import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as yup from 'yup';
import {
  FormControl,
  FormLabel,
  Select,
  FormHelperText,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '@hooks';

import PaymentMethodIcon from '../../components/PaymentMethodIcon';
import { setPaymentMethod } from '../../payment/redux';

const useStyles = makeStyles({
  control: {
    marginTop: '1rem',
  },
  select: {
    marginTop: '0.6rem',
    color: '#AAA',
  },
  option: {
    color: '#333',
  },
  icon: {
    marginRight: '0.7rem',
  },
  selected: {
    color: '#333',
  },
  label: {
    '&.Mui-focused, &.Mui-error': {
      color: '#333',
    },
  },
});

export default function PaymentMethodPicker({
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (value) {
      dispatch(setPaymentMethod(value));
    }
  }, [dispatch, value]);

  return (
    <FormControl
      className={classes.control}
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <FormLabel className={classes.label} htmlFor={name}>
        {t('fields.paymentMethodPicker.label')}
      </FormLabel>
      <Select
        className={clsx(classes.select, value !== '' && classes.selected)}
        fullWidth={true}
        id={name}
        inputRef={inputRef}
        name={name}
        native={true}
        onBlur={onBlur}
        onChange={onChange}
        startAdornment={
          value && (
            <InputAdornment className={classes.icon}>
              <PaymentMethodIcon paymentMethod={value} />
            </InputAdornment>
          )
        }
        value={value}
      >
        <option aria-label="None" disabled={true} value="">
          {t('fields.paymentMethodPicker.placeholder')}
        </option>
        {options.map(option => (
          <option className={classes.option} key={option} value={option}>
            {t(`fields.paymentMethodPicker.options.${option}`)}
          </option>
        ))}
      </Select>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
}

PaymentMethodPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  error: PropTypes.string,
};

PaymentMethodPicker.defaultProps = {
  error: '',
  inputRef: undefined,
};

PaymentMethodPicker.validation = () =>
  yup.string().required('fields.paymentMethodPicker.required');
