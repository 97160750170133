import { useSelector } from 'react-redux';
import { arrayOf, bool, shape } from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from '@hooks';
import { getMonthlyPayments } from '../../../payment/redux';
import DocumentTile from './components/DocumentTile';

export const AdditionalDocumentsContainer = ({ options }) => {
  const { documents, isUsedWithInstallmentSelector } = options;

  const { t } = useTranslation();

  const monthlyPayments = useSelector(getMonthlyPayments);

  if (isUsedWithInstallmentSelector && !monthlyPayments) {
    return null;
  }

  return (
    <Box mt={4}>
      {isUsedWithInstallmentSelector ? null : (
        <Typography component="h3" fontWeight="bold" variant="body1">
          {t('fields.additionalDocumentsContainer.title')}
        </Typography>
      )}
      <Grid container={true} pt={1}>
        {documents.map(({ key, uri }) => (
          <DocumentTile
            href={uri}
            key={key}
            textAlign="start"
            title={t(`fields.additionalDocumentsContainer.documents.${key}`)}
          />
        ))}
      </Grid>
    </Box>
  );
};

AdditionalDocumentsContainer.propTypes = {
  options: shape({
    isUsedWithInstallmentSelector: bool,
    documents: arrayOf(shape()).isRequired,
  }).isRequired,
};

AdditionalDocumentsContainer.defaultTypes = {
  options: {
    isUsedWithInstallmentSelector: false,
  },
};
