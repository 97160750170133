import PropTypes from 'prop-types';

export default function Value({ value }) {
  return <span>{value}</span>;
}

Value.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Value.defaultProps = {
  value: '',
};
