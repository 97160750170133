/* eslint-disable sonarjs/cognitive-complexity */
// TODO eslint migration - fix dependency array missing props
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../../components/CompleteWrapper';
import {
  overrideEventValue,
  toKeyValuePairOrNull,
} from '../utils/valueFormatters';
import ChainedAutocompleteIcon from './ChainedAutocompleteIcon';

const ChainedAutocomplete = forwardRef(
  (
    {
      disabled = false,
      error = false,
      errorKey,
      fetchItems,
      fetchOnInitialLoad = false,
      fetchOnUpstreamFilterChange = false,
      freeSolo = false,
      id,
      labelKey,
      matchFromStart = false,
      name,
      onBlur,
      onChange,
      upstreamFilter = null,
      value = null,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const initialValueRef = useRef(value);
    const [options, setOptions] = useState([]);
    const [option, setOption] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const isUpstreamFilterEmpty = !upstreamFilter && !fetchOnInitialLoad;
    const isDisabled = disabled || isUpstreamFilterEmpty;
    const filterOptions = createFilterOptions({
      matchFrom: matchFromStart ? 'start' : 'any',
    });

    const handleFetchItems = filter => {
      setIsLoading(true);
      fetchItems(filter, upstreamFilter?.key)
        .then(result => {
          setOptions(result);
        })
        .finally(() => setIsLoading(false));
    };

    useEffect(() => {
      if (options.length > 0 && !option?.key && initialValueRef.current) {
        const matchingOption = options.find(
          item =>
            item.value.toLowerCase() === initialValueRef.current?.toLowerCase(),
        );

        if (matchingOption) {
          setOption(matchingOption);
          onChange(matchingOption);
        }
      }
    }, [options]);

    const fetchDataDebounced = useCallback(
      debounce(filter => handleFetchItems(filter), 300),
      [upstreamFilter],
    );

    const resetField = () => {
      if (!freeSolo || options.includes(option)) {
        onChange(null);
        setOption({});
      }
      setOptions([]);
    };

    // Will fetch when the component loads. Useful for the first chained filter
    useEffect(() => {
      if (fetchOnInitialLoad) {
        fetchDataDebounced();
      }
    }, [fetchOnInitialLoad]);

    // Will reset the state of the field when the upstream filter changes
    // Will fetch new data if the filter changes and has a value assigned
    useEffect(() => {
      resetField();

      // We only call the italian post API when there's a default value to search by
      // or when the user types a search key in the field.
      const isFilterOnInitialValueField =
        !fetchOnUpstreamFilterChange &&
        initialValueRef.current &&
        upstreamFilter?.key;

      // We call the italian post API when the upstream field has changed and get
      // the full result set for the uppstream field value. (ex. post codes for city)
      const isFilterOnLoadField =
        fetchOnUpstreamFilterChange && upstreamFilter?.key;

      if (isFilterOnInitialValueField) {
        fetchDataDebounced(value);
      } else if (isFilterOnLoadField && !isUpstreamFilterEmpty) {
        fetchDataDebounced(upstreamFilter.key);
      }
    }, [fetchOnUpstreamFilterChange, upstreamFilter]);

    const handleOnChange = (event, selectedOption) => {
      setOption(selectedOption);
      onChange(overrideEventValue(event, selectedOption));
    };

    // Will fetch when the input changes if the field is not dependent on an upstream filter
    const handleOnInputChange = event => {
      const currentInput = event?.target?.value;
      if (!fetchOnUpstreamFilterChange && currentInput) {
        fetchDataDebounced(currentInput);
      }

      // Free input fields will not trigger the on change event because there will be no selection
      // OnChange has to be triggered manualy on key input. The field should not reset on fetch
      if (freeSolo) {
        const keyValuePair = toKeyValuePairOrNull(currentInput);
        onChange(overrideEventValue(event, keyValuePair));
      }
    };

    return (
      <FormControl error={true} fullWidth={true}>
        <CompleteWrapper complete={!!value} error={error}>
          <Autocomplete
            autoComplete={false}
            autoHighlight={true}
            disabled={isDisabled}
            filterOptions={filterOptions}
            freeSolo={freeSolo}
            fullWidth={true}
            getOptionLabel={selectedOption =>
              selectedOption?.displayValue || ''
            }
            id={id}
            inputref={ref}
            name={name}
            noOptionsText={t('fields.addressContainer.noMatches')}
            onBlur={onBlur}
            onChange={handleOnChange}
            onInputChange={handleOnInputChange}
            options={options}
            popupIcon={
              <ChainedAutocompleteIcon
                isDisabled={isDisabled}
                isLoading={isLoading}
              />
            }
            renderInput={params => (
              <TextField
                sx={{
                  '> .MuiInputBase-root.MuiInputBase-adornedEnd': { pr: '6px' },
                }}
                {...params} // eslint-disable-line  react/jsx-props-no-spreading
                error={error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: freeSolo ? (
                    <IconButton
                      disabled={isDisabled}
                      size="small"
                      sx={{ paddingX: '4px', paddingY: 0 }}
                    >
                      <ChainedAutocompleteIcon
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                      />
                    </IconButton>
                  ) : (
                    params.InputProps.endAdornment
                  ),
                }}
                label={t(labelKey)}
              />
            )}
            size="small"
            value={option}
          />
        </CompleteWrapper>
        {error ? <FormHelperText>{t(errorKey)}</FormHelperText> : null}
      </FormControl>
    );
  },
);

ChainedAutocomplete.displayName = 'ChainedAutocomplete';

export default ChainedAutocomplete;
