export enum FeatureFlag {
  IsExternalSigningEnabled = 'isExternalSigningEnabled',
  IsHelpWidgetEnabled = 'isHelpWidgetEnabled',
  IsHeyLightBrandingEnabled = 'isHeylightBrandingEnabled',
  IsHeylightOverwriteByMerchantBrandingEnabled = 'isHeylightOverwriteByMerchantBrandingEnabled',
  IsInstorePreviousOrderSelectorEnabled = 'isInstorePreviousOrderSelectorEnabled',
  IsPagolightTextUpdateEnabled = 'isPagolightTextUpdateEnabled',
  IsSalesforceLiveChatEnabled = 'isSalesforceLiveChatEnabled',
  IsShowCardChangeUpdatesEnabled = 'IsShowCardChangeUpdatesEnabled',
}
