import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { setAbandonVisible } from '@redux';
import { CopyBlock } from '../../components';
import { SentrySeverity } from '../../utils';
import { ProgressBar } from './ProgressBar';

const DEFAULT_REDIRECT_SECONDS = 8;

export default function MerchantRedirectBlock({ name, options, value: url }) {
  const {
    hideProgress,
    hideText = false,
    redirectSeconds = DEFAULT_REDIRECT_SECONDS,
  } = options;
  const [countdown, setCountdown] = useState(0);

  // Ensure the cross is missing for this page
  const dispatch = useDispatch();
  const updateAbandonVisible = useCallback(
    value => dispatch(setAbandonVisible(value)),
    [dispatch],
  );

  const countdownIncrement = useMemo(() => {
    if (redirectSeconds === 0) {
      return 100;
    }
    return (100 * 100) / (redirectSeconds * 1000);
  }, [redirectSeconds]);

  useEffect(() => {
    const logToSentryAndRedirect = async () => {
      try {
        Sentry.captureMessage(
          `User redirected to the merchant e-commerce platform. URL: ${url}`,
          SentrySeverity.info,
        );
        await Sentry.flush(1000);
      } finally {
        window.location.assign(url);
      }
    };

    if (countdown === 100) {
      setTimeout(() => {
        // Remove listener from onbeforeunload
        window.onbeforeunload = () => {};
        logToSentryAndRedirect();
      }, 500);
    }
  }, [countdown, url]);

  useEffect(() => {
    updateAbandonVisible(false);
    const timer = setInterval(() => {
      setCountdown(prevCountdown =>
        prevCountdown >= 100 ? 100 : prevCountdown + countdownIncrement,
      );
    }, 100);

    return () => {
      clearInterval(timer);
      updateAbandonVisible(true);
    };
  }, [countdownIncrement, updateAbandonVisible]);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      {hideProgress ? null : <ProgressBar countdown={countdown} />}
      {hideText ? null : (
        <CopyBlock i18nKey={`fields.merchantRedirectBlock.${name}.content`} />
      )}
    </FormControl>
  );
}

MerchantRedirectBlock.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.shape({
    hideProgress: PropTypes.bool,
    redirectSeconds: PropTypes.number,
  }),
};

MerchantRedirectBlock.defaultProps = {
  options: {},
};
