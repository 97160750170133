import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from '@hooks';

const useStyles = makeStyles({
  span: {
    marginTop: '1px',
  },
});

export const PagolightProLogo = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="center" mb={2}>
        <Box className={classes.span} component="span" pr={1}>
          {t('footer.poweredBy')}
        </Box>
        <img
          alt="HeidiPay"
          height="21px"
          src="/images/heidipay.svg"
          width="100px"
        />
      </Box>
      <Typography align="center" variant="caption">
        {t('footer.pagolightPro')}
      </Typography>
    </>
  );
};
