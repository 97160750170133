import { RowType } from '../../PaymentPlanTable/utils';

export const getPaymentPlanFields = ({
  currency,
  formatAmountWithCurrency,
  payMonthly,
  payNow,
  residualPayment,
  term,
  totalAmount,
  translate,
}) => {
  if (!currency || !residualPayment) {
    return null;
  }

  const { finalPayment } = residualPayment;
  const amountWithoutResidualPayment = totalAmount - finalPayment;

  return [
    {
      label: translate('fields.paymentPlanMobilezone.payNow'),
      value: formatAmountWithCurrency(payNow, currency),
    },
    {
      label: translate('fields.paymentPlanMobilezone.payMonthly', {
        creditDurationWithoutResidualInstallment: term - 1,
      }),
      value: formatAmountWithCurrency(payMonthly, currency),
    },
    {
      label: translate('fields.paymentPlanMobilezone.interest'),
      value: '0 %',
    },
    {
      label: 'divider-1',
      rowType: RowType.divider,
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(
        'fields.paymentPlanMobilezone.totalAmountWithoutResidualPayment',
      ),
      tooltip: translate(
        'fields.mobilezone.tooltip.selectScheduleMobilezone.totalAmountWithoutResidualPayment',
        {
          creditDurationWithResidualInstallment: term,
        },
      ),
      value: formatAmountWithCurrency(amountWithoutResidualPayment, currency),
    },
    {
      label: 'divider-2',
      rowType: RowType.divider,
    },
    {
      label: translate('fields.paymentPlanMobilezone.residualAmount', {
        creditDurationWithResidualInstallment: term,
      }),
      tooltip: translate(
        'fields.mobilezone.tooltip.selectScheduleMobilezone.residualAmount',
        {
          creditDurationWithResidualInstallment: term,
        },
      ),
      value: formatAmountWithCurrency(finalPayment, currency),
    },
    {
      label: 'divider-3',
      rowType: RowType.divider,
    },
    {
      label: translate('fields.paymentPlanMobilezone.totalAmount'),
      tooltip: translate(
        'fields.mobilezone.tooltip.selectScheduleMobilezone.totalAmount',
        {
          creditDurationWithResidualInstallment: term,
        },
      ),
      value: formatAmountWithCurrency(totalAmount, currency),
    },
    {
      label: 'divider-4',
      rowType: RowType.divider,
    },
    {
      currency,
      label: translate('fields.paymentPlan.schedule'),
      hideScheduleTitle: true,
      rowType: RowType.scheduleToggle,
    },
  ];
};
