import PropTypes from 'prop-types';
import * as yup from 'yup';

export const contentScrollablePropTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.shape(),
  error: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  onScrollToBottom: PropTypes.func,
};

export const contentScrollableDefaultProps = {
  error: [],
  options: {},
  onScrollToBottom: null,
};

export const contentScrollableValidation = (
  name,
  { mandatoryAcceptanceKeys },
) =>
  yup.array().of(
    yup
      .object()
      .shape({
        key: yup.string(),
        value: yup
          .mixed()
          .oneOf(
            [true, false],
            'fields.consentScrollableContainer.consentBlock.required',
          ),
      })
      .test(
        'consents-with-mandatory-acceptance',
        'fields.consentScrollableContainer.consentBlock.requiresAcceptance',
        field =>
          !mandatoryAcceptanceKeys.find(key => key === field.key) ||
          field.value === true,
      ),
  );
