import { lazy } from 'react';

// instore components
import {
  InstoreMultiProductFormContainer,
  InstorePricingStructureCodePicker,
  InstoreProductSelectStandalone,
  VariantsPicker,
} from '@instore/components';

import {
  AdditionalDocumentsContainer,
  AdditionalUnderwritingContainer,
  ButtonGroupPicker,
  ChangeCardComplete,
  CodeVerification,
  ConfirmationCheckbox,
  ConsentScrollableContainer,
  ConsentScrollableZoomContainer,
  CountdownTimer,
  Divider,
  ExternalDocumentViewer,
  HtmlContainer,
  InstorePaymentSummaryMobilezone,
  InstoreProductSearchTextInput,
  IntroPagolightPro,
  IntrumIframeContainer,
  IntrumSigningHoldingContainer,
  NationalityPicker,
  NumberPicker,
  OrderProfilePicker,
  PagolightHoldingContainer,
  PaymentMethodBlock,
  PaymentPlanMobilezone,
  PaymentPlanPagolightPro,
  PaymentPlanSwissCredit,
  RadioButtonsStacked,
  RedirectComponent,
  ScrollableContainer,
  SecciLink,
  SecciLinkLegalNotice,
  SelectPicker,
  SelectPickerItalianProvince,
  SelectPickerRaw,
  SelectPickerSearchable,
  SignContract,
  SpidCallbackProcessor,
  SPIDWelcomeContainer,
  SignatureList,
  SuccessIcon,
  TermsAndConditionsButtonGroupPicker,
  TermsAndConditionsCheckbox,
  TextBlockValue,
  TextInput,
  TextInputDocumentNumber,
  TextInputNumeric,
  TextInputMoney,
  DisplayList,
  FailureIcon,
  AlertText,
  HelpTooltip,
} from './components';
import AddressContainer from './components/AddressContainer';
import {
  addressContainerItalianPostValidation,
  addressContainerItalianPostOcrValidation,
} from './components/AddressContainerItalianPost';
import { AddressContainerSwissPost } from './components/AddressContainerSwissPost';
import ContinueButton from './components/ContinueButton';
import CustomerAgreementPagolightPro from './components/CustomerAgreementPagolightProDeprecated';
import DateOfBirthInput from './components/DateOfBirthInput';
import HiddenField from './components/HiddenField';
import HiddenMonthlyPaymentsField from './components/HiddenMonthlyPaymentsField';
import ImageRow from './components/ImageRow';
import InstoreMoneyInput from './components/InstoreMoneyInput';
import InstorePaymentSummary from './components/InstorePaymentSummary';
import LoginContainer from './components/LoginContainer';
import MandatoryActionContainer from './components/MandatoryActionContainer';
import MerchantRedirectBlock from './components/MerchantRedirectBlock';
import MobileNumberInput from './components/MobileNumberInput';
import MobileVerificationCodeInput from './components/MobileVerificationCodeInput';
import MonthlyPaymentsPicker from './components/MonthlyPaymentsPicker';
import MonthlyPaymentsToggle from './components/MonthlyPaymentsToggle';
import OnfidoFailureContainer from './components/OnfidoFailureContainer';
import OrderSummary from './components/OrderSummary';
import PaymentGatewayContainer from './components/PaymentGatewayContainer';
import PaymentMethodPicker from './components/PaymentMethodPicker';
import PaymentMethodsDisplay from './components/PaymentMethodsDisplay';
import PaymentPlan from './components/PaymentPlan';
import ProductSelect from './components/ProductSelect';
import RadioButtons from './components/RadioButtons';
import Spacer from './components/Spacer';
import Spinner from './components/Spinner';
import TextBlock from './components/TextBlock';
import VisibilityController from './components/VisibilityController/VisibilityController';
import WaitingBot from './components/WaitingBot';

// Form input components need to expose the validation rules via a validation function
const AddressContainerItalianPostOcr = {
  lazy: lazy(() =>
    import('./components/AddressContainerItalianPost').then(module => ({
      default: module.AddressContainerItalianPostOcr,
    })),
  ),
  validation: addressContainerItalianPostOcrValidation,
};
const AddressContainerItalianPost = {
  lazy: lazy(() =>
    import('./components/AddressContainerItalianPost').then(module => ({
      default: module.AddressContainerItalianPost,
    })),
  ),
  validation: addressContainerItalianPostValidation,
};

export default {
  AlertText,
  AdditionalDocumentsContainer,
  AdditionalUnderwritingContainer,
  AddressContainer,
  AddressContainerItalianPost,
  AddressContainerSwissPost,
  AddressContainerItalianPostOcr,
  ButtonGroupPicker,
  ChangeCardComplete,
  CodeVerification,
  ConsentScrollableContainer,
  ConsentScrollableZoomContainer,
  ConfirmationCheckbox,
  ContinueButton,
  CountdownTimer,
  CustomerAgreementPagolightPro,
  DateInput: DateOfBirthInput,
  DateOfBirthInput,
  Divider,
  ExternalDocumentViewer,
  HelpTooltip,
  HiddenField,
  HiddenMonthlyPaymentsField,
  HtmlContainer,
  ImageRow,
  InstoreMerchantRedirectQR: lazy(() =>
    import('@instore/components/InstoreMerchantRedirectQR').then(module => ({
      default: module.InstoreMerchantRedirectQR,
    })),
  ),
  InstoreMultiProductFormContainer,
  InstorePaymentSummary,
  InstorePaymentSummaryMobilezone,
  InstorePricingStructureCodePicker,
  InstoreProductSearchTextInput,
  InstoreProductSelectStandalone,
  IntroPagolightPro,
  IntrumIdentityHoldingContainer: lazy(() =>
    import('./components/IntrumIdentityHoldingContainer'),
  ),
  IntrumIframeContainer,
  IntrumSigningHoldingContainer,
  LoginContainer,
  MerchantRedirectBlock,
  MobileNumberInput,
  MobileVerificationCodeInput,
  MoneyInput: InstoreMoneyInput,
  MonthlyPaymentsPicker,
  MonthlyPaymentsToggle,
  MandatoryActionContainer,
  NationalityPicker,
  NumberPicker,
  OnfidoFailureContainer,
  OnfidoHoldingPage: lazy(() =>
    import('./components/OnfidoHoldingPage').then(module => ({
      default: module.OnfidoHoldingPage,
    })),
  ),
  OnfidoHoldingPageV2: lazy(() =>
    import('./components/OnfidoHoldingPage').then(module => ({
      default: module.OnfidoHoldingPageV2,
    })),
  ),
  OnfidoItalianHealthCardWelcomeScreenContainer: lazy(() =>
    import('./components/OnfidoItalianHealthCardWelcomeScreenContainer'),
  ),
  OrderSummary,
  OrderProfilePicker,
  OnfidoContainerLatest: lazy(() =>
    import('./components/OnfidoContainerLatest'),
  ),
  OnfidoContainerV14: lazy(() =>
    import('./components/OnfidoContainerLatest/OnfidoContainerV14'),
  ),
  OnfidoWelcomeScreenContainer: lazy(() =>
    import('./components/OnfidoWelcomeScreenContainer').then(module => ({
      default: module.OnfidoWelcomeScreenContainer,
    })),
  ),
  OnfidoWelcomeScreenContainerV2: lazy(() =>
    import('./components/OnfidoWelcomeScreenContainer').then(module => ({
      default: module.OnfidoWelcomeScreenContainerV2,
    })),
  ),
  PagolightHoldingContainer,
  PaymentMethodBlock,
  PaymentGatewayContainer,
  PaymentMethodsDisplay,
  PaymentMethodPicker,
  PaymentPlan,
  PaymentPlanMobilezone,
  PaymentPlanPagolightPro,
  PaymentPlanSwissCredit,
  PDFDocumentViewer: lazy(() => import('./components/PDFDocumentViewer')),
  ProductSelect,
  RadioButtons,
  RadioButtonsStacked,
  RedirectComponent,
  ScrollableContainer,
  SecciLink,
  SecciLinkLegalNotice,
  SelectPicker,
  SelectPickerItalianProvince,
  SelectPickerRaw,
  SelectPickerSearchable,
  SignContract,
  SignatureList,
  Spacer,
  SpidCallbackProcessor,
  SPIDWelcomeContainer,
  Spinner,
  SuccessIcon,
  FailureIcon,
  TermsAndConditionsButtonGroupPicker,
  TermsAndConditionsCheckbox,
  TextBlock,
  TextBlockValue,
  TextInput,
  TextInputDocumentNumber,
  TextInputNumeric,
  TextInputMoney,
  WaitingBot,
  VariantsPicker,
  VisibilityController,
  DisplayList,
};
