import { useSelector } from 'react-redux';
import { Button, Typography, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { getIsLoading } from '@redux';
import { ContinueButtonPortal } from './ContinueButtonPortal';
import { IContinueButtonProps } from './types';

const StyledButton = styled(Button, {
  shouldForwardProp: (prop): boolean => prop !== 'width',
})<{ width?: string }>(({ theme, width }) => ({
  maxWidth: '18rem',
  width: width || 'auto',
  background: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}33`,
    color: theme.palette.primary.main,
  },
  '&:disabled': {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.4,
  },
}));

export const ContinueButton = ({
  options = {},
  submit,
  width,
}: IContinueButtonProps) => {
  const { t } = useTranslation();
  const isLoading = useSelector(getIsLoading);

  const translationKey = `fields.continueButton${
    options.overrideTextKey ? `.${options.overrideTextKey}` : ''
  }.label`;

  return (
    <ContinueButtonPortal>
      <StyledButton
        color="primary"
        disabled={isLoading}
        onClick={submit}
        type="submit"
        variant="contained"
        width={width}
      >
        <Typography variant="button">{t(translationKey)}</Typography>
      </StyledButton>
    </ContinueButtonPortal>
  );
};

ContinueButton.formatting = () => undefined;

export default ContinueButton;
