import PropTypes from 'prop-types';
import * as yup from 'yup';
import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { StackedLabel } from '../StackedLabel';
import useSelectPickerUtil from './utils/SelectPickerUtil';

export const SelectPicker = ({
  complete,
  displayRawValues,
  error,
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  options,
  sx,
  value,
}) => {
  const { disabled, tooltip, withStackedLabel } = options;

  const { t } = useTranslation();
  const displayLabel = t(label ?? `fields.selectPicker.${name}.label`);

  const { sortAndTranslateItemsBackwardCompatible } = useSelectPickerUtil();
  const selectItems = displayRawValues
    ? options.items
    : sortAndTranslateItemsBackwardCompatible(options, name, t);

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem', ...sx }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel label={displayLabel} name={name} tooltip={tooltip} />
      ) : (
        <InputLabel htmlFor={name}>{displayLabel}</InputLabel>
      )}
      <CompleteWrapper complete={complete} error={error}>
        <Select
          id={name}
          inputRef={inputRef}
          label={withStackedLabel ? undefined : displayLabel}
          name={name}
          native={true}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        >
          <option aria-label="None" value="" />
          {selectItems.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </CompleteWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

SelectPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  complete: PropTypes.bool,
  displayRawValues: PropTypes.bool,
  error: PropTypes.string,
  inputRef: PropTypes.shape(),
  label: PropTypes.string,
};

SelectPicker.defaultProps = {
  complete: false,
  displayRawValues: false,
  error: '',
  inputRef: undefined,
  label: null,
};

SelectPicker.validation = () =>
  yup.string().required('fields.selectPicker.required');
