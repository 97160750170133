import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ISelectPickerRawOptions,
  SelectPickerRaw,
} from '../../../transaction/components/SelectPickerRaw/SelectPickerRaw';
import { IBaseInputComponent } from '../../../transaction/components/types';
import {
  setPricingStructureCode,
  updateIsPricingStructureCodeRequired,
} from '../../redux';

export const InstorePricingStructureCodePicker = ({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  value,
}: IBaseInputComponent<ISelectPickerRawOptions, string, string>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateIsPricingStructureCodeRequired(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPricingStructureCode(value));
  }, [dispatch, value]);

  return (
    <SelectPickerRaw
      complete={complete}
      error={error}
      inputRef={inputRef}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      setValue={setValue}
      value={value}
    />
  );
};
