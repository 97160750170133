/* eslint-disable max-len */
import { useTheme, Box } from '@material-ui/core';

export interface IFailureIconOptions {
  height?: number;
  width?: number;
}
export interface IFailureIcon {
  options?: IFailureIconOptions;
}

const FailureIcon = ({ options }: IFailureIcon) => {
  const { height, width } = options ?? {};

  const {
    palette: { error },
  } = useTheme();

  const fillColor = error?.main;

  return (
    <Box textAlign="center">
      <svg
        className="icon line-color"
        data-name="Line Color"
        height={height || 140}
        role="img"
        viewBox="0 0 24 24"
        width={width || 110}
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Failure Icon</title>
        <path
          d="M15 15L9 9"
          fill="none"
          stroke="red"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></path>
        <path
          d="M9 15L15 9"
          data-name="secondary"
          fill={fillColor}
          stroke="red"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></path>
        <path
          d="M12 1a10.5 10.5 0 1010.5 11A10.513 10.513 0 0012 1zm0 20a9.5 9.5 0 119.5-9.5A9.511 9.511 0 0112 21z"
          fill="#C4C4C4"
        ></path>
      </svg>
    </Box>
  );
};

export default FailureIcon;
