import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ArrowDropDown } from '@material-ui/icons';
import { Menu, ButtonGroup, Button, Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { getSessionDetails, getFeatureFlags } from '../../../redux/selectors';
import { getCurrentAction } from '../../../transaction/redux';
import { CheckoutType } from '../../../utils';
import { PreviousOrderSelector } from './components/PreviousOrderSelector';
import { openInstoreSalesGuide } from './utils/openInstoreSalesGuide';

const TaskTypes = Object.freeze({
  previousOrderSelector: 'previousOrderSelector',
  instoreSalesGuide: 'instoreSalesGuide',
});

const TaskComponents = Object.freeze({
  [TaskTypes.previousOrderSelector]: PreviousOrderSelector,
});

export const InstoreTasksMenu = ({ setFormValue }) => {
  const {
    i18n: { language: locale },
    t,
  } = useTranslation();
  const { isInstorePreviousOrderSelectorEnabled } =
    useSelector(getFeatureFlags);
  const { checkoutType } = useSelector(getSessionDetails);
  const currentAction = useSelector(getCurrentAction);

  const [anchorElement, setAnchorElement] = useState(null);
  const [taskType, setTaskType] = useState(null);
  const TaskComponent = TaskComponents[taskType] || null;

  const isInstoreCheckoutForm =
    checkoutType === CheckoutType.instore && currentAction === 'instore';

  const displayTaskMenu = (event, type) => {
    setAnchorElement(event.currentTarget);
    setTaskType(type);
  };

  const handleClose = () => {
    setAnchorElement(null);
    setTaskType(null);
  };

  const handleOpenInstoreHelp = () => openInstoreSalesGuide(locale);

  if (!isInstoreCheckoutForm) {
    return null;
  }

  return (
    <>
      <Grid container={true} justifyContent="flex-end">
        <ButtonGroup aria-label="menu" size="small" variant="outlined">
          {isInstorePreviousOrderSelectorEnabled ? (
            <Button
              onClick={e => displayTaskMenu(e, TaskTypes.previousOrderSelector)}
              sx={{ textTransform: 'none' }}
            >
              {t('instore.tasksMenu.previousOrders.label')}
              <ArrowDropDown />
            </Button>
          ) : null}
          <Button
            onClick={handleOpenInstoreHelp}
            sx={{ textTransform: 'none' }}
          >
            {t('instore.tasksMenu.help.label')}
          </Button>
        </ButtonGroup>
      </Grid>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleClose}
        open={!!anchorElement}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {TaskComponent ? (
          <TaskComponent closeMenu={handleClose} setFormValue={setFormValue} />
        ) : null}
      </Menu>
    </>
  );
};

InstoreTasksMenu.propTypes = {
  setFormValue: PropTypes.func.isRequired,
};
