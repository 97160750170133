import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Collapse,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../../components';
import { getMonthlyPayments, getPaymentPlan } from '../../../payment/redux';
import { useFormatters } from '../../../utils';
import { SecciLink } from '../SecciLink';
import { Cell } from '../Styled';
import { getPaymentPlanFields } from './utils/getPaymentPlanFields';

export const PaymentPlanPagolightPro = ({ options }) => {
  const { fullDetails = true } = options;

  const [displayFields, setDisplayFields] = useState([]);

  const displayPaymentPlan = displayFields.length > 0;

  const { formatAmountWithCurrency, formatDate, formatPercentage } =
    useFormatters();

  const { t } = useTranslation();

  const { schedule, summary } = useSelector(getPaymentPlan);

  const monthlyPayments = useSelector(getMonthlyPayments);

  useEffect(() => {
    if (summary.currency) {
      const firstPayment = schedule[0];
      const terms = getPaymentPlanFields({
        ...summary,
        firstRepaymentDate: firstPayment.date,
        firstRepaymentAmount: firstPayment.amount,
        fullDetails,
        term: monthlyPayments,
        translate: t,
        formatAmountWithCurrency,
      });
      setDisplayFields(terms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary, schedule, monthlyPayments, t]);

  const formatFieldValue = useCallback(
    field => {
      const fieldTypeToFormatter = {
        currency: formatAmountWithCurrency,
        date: date => formatDate({ date, options: {} }),
        percentage: formatPercentage,
        string: value => value,
      };

      return field.value
        ? fieldTypeToFormatter[field.type](field.value, field.currency)
        : null;
    },
    [formatAmountWithCurrency, formatDate, formatPercentage],
  );

  return (
    <Collapse in={displayPaymentPlan} sx={{ width: '100%' }}>
      <Grid container={true}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {displayFields.map(term => (
                <TableRow key={term.label}>
                  <Cell
                    align="left"
                    colSpan={term.isLabelOnly ? 2 : 1}
                    component="th"
                    scope="row"
                  >
                    {term.renderAsCopyBlock ? (
                      <CopyBlock i18nKey={term.label} value={term.value} />
                    ) : (
                      term.label
                    )}
                  </Cell>
                  {term.isLabelOnly ? null : (
                    <Cell
                      align="right"
                      boldText={true}
                      verticalAlign={term.verticalAlign}
                    >
                      {formatFieldValue(term)}
                    </Cell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SecciLink numberOfInstallments={monthlyPayments} />
      </Grid>
    </Collapse>
  );
};

PaymentPlanPagolightPro.propTypes = {
  options: PropTypes.shape({
    fullDetails: PropTypes.bool,
  }),
};

PaymentPlanPagolightPro.defaultProps = {
  options: {
    fullDetails: true,
  },
};
