import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  OriginatorUuidProd,
  OriginatorUuidQa,
  OriginatorUuidSbx,
} from '../../../../enums';
import { IUseFormatter } from '../../../../utils/formatters/types';
import { getPagolightProPaymentPlanSwissCreditFields } from './getPagolightProPaymentPlanSwissCreditFields';
import { getPaymentPlanSwissCreditFields } from './getPaymentPlanSwissCreditFields';
import { IScheduleData } from './types';

interface IFilters {
  isRegulatedProduct: boolean;
  originatorUuid: string;
}

interface IGetPaymentPlanFields {
  filters: IFilters;
  formatters: IUseFormatter;
  scheduleData: IScheduleData;
  translate: Translate;
}

const pagolightProOriginators = [
  OriginatorUuidQa.PAGOLIGHTPRO.valueOf(),
  OriginatorUuidSbx.PAGOLIGHTPRO.valueOf(),
  OriginatorUuidProd.PAGOLIGHTPRO.valueOf(),
];

export const getPaymentPlanFields = ({
  filters,
  formatters,
  scheduleData,
  translate,
}: IGetPaymentPlanFields) => {
  // TODO change as soon as possible to a better implementation
  if (pagolightProOriginators.includes(filters.originatorUuid)) {
    return getPagolightProPaymentPlanSwissCreditFields(
      scheduleData,
      formatters,
      translate,
    );
  }

  return getPaymentPlanSwissCreditFields(scheduleData, formatters, translate);
};
