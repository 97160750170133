export function persistFallbackUrl(fallbackUrl) {
  if (window.sessionStorage) {
    try {
      window.sessionStorage.setItem('fallbackUrl', fallbackUrl);
    } catch {
      // Access was denied
    }
  }
}

export function getPersistedFallbackurl() {
  if (window.sessionStorage) {
    try {
      return window.sessionStorage.getItem('fallbackUrl');
    } catch {
      // Access was denied
    }
  }
  return undefined;
}
