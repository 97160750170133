import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import mem from 'mem';
import * as yup from 'yup';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  Box,
  Link,
  makeStyles,
} from '@material-ui/core';

import CompleteWrapper from '../../components/CompleteWrapper';
import Value from '../../components/Value';
import { getDispatch } from '../../store';
import {
  checkVerificationCode,
  resendVerificationCode,
  getIsVerifying,
} from '../../verification/redux';
import CodeVerification from './CodeVerification';

const VERIFICATION_CODE_LENGTH = 6;

const useStyles = makeStyles({
  label: {
    marginBottom: '1rem',
  },
  link: {
    fontSize: '0.875rem',
    verticalAlign: 'baseline',
  },
});

export default function MobileVerificationCodeInput({
  clearError,
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}) {
  const { t } = useTranslation();
  const isVerifying = useSelector(getIsVerifying);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [code, setCode] = useState(value);

  const { mobileNumber, renderCodeVerification = false } = options;

  const resend = () => {
    setCode('');
    clearError();
    dispatch(resendVerificationCode());
  };

  const handleChange = event => {
    const val = event.target.value || '';
    setCode(val);
    onChange(val);
    if (val && val.length === VERIFICATION_CODE_LENGTH) {
      clearError();
      onBlur();
    }
  };

  const handleCodeVerificationChange = verificationCode =>
    handleChange({ target: { value: verificationCode } });

  return (
    <FormControl component="fieldset" error={!!error} fullWidth={true}>
      {renderCodeVerification ? (
        <>
          <Box margin="auto" py={2}>
            <CodeVerification
              digits={VERIFICATION_CODE_LENGTH}
              hasError={!!error}
              isVerified={complete}
              isVerifying={isVerifying}
              onDigitChange={handleCodeVerificationChange}
            />
            <FormHelperText>{t(error)}</FormHelperText>
          </Box>
        </>
      ) : (
        <>
          <FormLabel className={classes.label} htmlFor={name}>
            <Trans
              components={{
                mobile: <Value value={mobileNumber} />,
              }}
              i18nKey="fields.mobileVerificationCodeInput.label"
            />
          </FormLabel>
          <CompleteWrapper
            complete={complete}
            error={error}
            validating={isVerifying}
          >
            <TextField
              autoComplete="one-time-code"
              disabled={isVerifying}
              error={!!error}
              helperText={t(error)}
              id={name}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              inputRef={inputRef}
              name={name}
              onBlur={onBlur}
              onChange={handleChange}
              placeholder="000000"
              size="small"
              type="text"
              value={code}
              variant="outlined"
            />
          </CompleteWrapper>
        </>
      )}
      <Box component="p">
        <Trans
          components={{
            resend: (
              <Link
                className={classes.link}
                component="button"
                onClick={resend}
                type="button"
              />
            ), // eslint-disable-line jsx-a11y/anchor-is-valid
          }}
          i18nKey="fields.mobileVerificationCodeInput.resend"
        />
      </Box>
    </FormControl>
  );
}

MobileVerificationCodeInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.shape({
    mobileNumber: PropTypes.string,
    renderCodeVerification: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  complete: PropTypes.bool,
  error: PropTypes.string,
  clearError: PropTypes.func.isRequired,
};

MobileVerificationCodeInput.defaultProps = {
  value: '',
  error: '',
  inputRef: undefined,
  complete: false,
};

MobileVerificationCodeInput.validation = () =>
  yup
    .string()
    .required('fields.mobileVerificationCodeInput.required')
    .min(
      VERIFICATION_CODE_LENGTH,
      'fields.mobileVerificationCodeInput.required',
    )
    .max(
      VERIFICATION_CODE_LENGTH,
      'fields.mobileVerificationCodeInput.required',
    )
    .test(
      'verificationCode',
      'fields.mobileVerificationCodeInput.invalid',
      mem(async value => {
        if (value?.length !== VERIFICATION_CODE_LENGTH) {
          return false;
        }
        const dispatch = getDispatch();
        return dispatch(checkVerificationCode(value));
      }),
    );
