import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as yup from 'yup';
import {
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  Select,
} from '@material-ui/core';
import { useTranslation } from '@hooks';

import { setAmount } from '@instore/redux';
import CompleteWrapper from '../../components/CompleteWrapper';
import { useFormatters } from '../../utils';

const useStyles = makeStyles({
  formControl: {
    marginTop: '0.625rem !important',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      marginLeft: '0.25rem',
      marginRight: '0.25rem',
    },
    '& > *:first-child': {
      marginLeft: '0',
    },
    '& > *:last-child': {
      marginRight: '0',
    },
  },
  amount: {
    flexGrow: 1,
  },
  currency: {},
  select: {
    width: '2.3em',
  },
});

const maxAmount = 999999;

const moneyInputValidation = (name, options) =>
  yup
    .object()
    .shape({
      amount: yup
        .string()
        .required(`fields.moneyInput.${name}.amount.required`)
        .matches(
          /^\d+(\.\d{1,2})?$/,
          `fields.moneyInput.${name}.amount.invalid`,
        )
        .test(
          'greaterThanZero',
          `fields.moneyInput.${name}.amount.invalid`,
          value => Number(value) > 0,
        )
        .test(
          'lessThanMaxAmount',
          `fields.moneyInput.${name}.amount.maxExceeded`,
          value => Number(value) <= (options.maxAmount || maxAmount),
        ),
      currency: yup
        .string()
        .required(`fields.moneyInput.${name}.currency.required`),
    })
    .required();

export default function InstoreMoneyInput({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}) {
  const { amount, currency } = value;
  const { currencies, disabled } = options;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formatAmountClean } = useFormatters();

  const handleOnChange = event => {
    const cleanAmount = formatAmountClean(event.target.value);
    onChange({ amount: cleanAmount, currency });
  };

  useEffect(() => {
    const validationSchema = moneyInputValidation(name, options);
    if (validationSchema.isValidSync(value)) {
      dispatch(setAmount(value));
    }
  }, [dispatch, name, options, value]);

  return (
    <FormControl
      className={classes.formControl}
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <CompleteWrapper complete={complete} error={error}>
        <div className={classes.root}>
          <TextField
            className={classes.amount}
            disabled={disabled}
            error={!!error && error.amount?.message}
            id={`${name}-amount`}
            InputLabelProps={{ shrink: !!amount }}
            inputRef={inputRef}
            label={t(`fields.moneyInput.${name}.amount.label`)}
            name={`${name}-amount`}
            onBlur={onBlur}
            onChange={handleOnChange}
            size="small"
            type="text"
            value={amount}
            variant="outlined"
          />
          <Select
            className={classes.currency}
            disabled={disabled}
            id={`${name}-currency`}
            inputProps={{
              className: clsx(
                'MuiInputBase-inputMarginDense',
                'MuiOutlinedInput-inputMarginDense',
                classes.select,
              ),
            }}
            name={`${name}-currency`}
            native={true}
            onBlur={onBlur}
            onChange={event =>
              onChange({ amount, currency: event.target.value })
            }
            size="small"
            value={currency}
            variant="outlined"
          >
            {currencies.map(currencyOption => (
              <option key={currencyOption} value={currencyOption}>
                {currencyOption}
              </option>
            ))}
          </Select>
        </div>
      </CompleteWrapper>
      <FormHelperText>
        {t(error ? error.amount?.message || error.currency?.message : '', {
          amount: maxAmount,
        })}
      </FormHelperText>
    </FormControl>
  );
}

InstoreMoneyInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  error: PropTypes.string,
  complete: PropTypes.bool,
  options: PropTypes.shape({
    currencies: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    maxAmount: PropTypes.number,
  }),
};

InstoreMoneyInput.defaultProps = {
  error: '',
  complete: false,
  inputRef: undefined,
  options: {
    currencies: [],
    disabled: false,
    maxAmount,
  },
};

InstoreMoneyInput.validation = moneyInputValidation;

InstoreMoneyInput.isComplete = ({ amount, currency }) =>
  Boolean(amount && currency);
