import PropTypes from 'prop-types';
import { CopyBlock } from '../../components';

export default function TextBlock({ name, options, value }) {
  const { largeText, overrideKey, textAlign } = options;

  const overrideSection = overrideKey ? `.${overrideKey}` : '';
  const sx = {
    ...(textAlign ? { textAlign } : {}),
  };

  return (
    <CopyBlock
      i18nKey={`fields.textBlock${overrideSection}.${name}.content`}
      sizeLarge={largeText}
      sx={sx}
      value={value}
    />
  );
}

TextBlock.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape(),
  options: PropTypes.shape(),
};

TextBlock.defaultProps = {
  value: {},
  options: {},
};

TextBlock.formatting = () => undefined;
