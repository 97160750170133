import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { Tick, Cross } from './Icons';

const useStyles = makeStyles({
  layout: {
    display: 'flex',
    width: '100%',
  },
  input: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  status: {
    flexShrink: 0,
    position: 'relative',
    marginLeft: '1rem',
    marginTop: '0.5rem',
  },
  icon: {
    position: 'absolute',
    top: 3,
    left: 3,
  },
});

const getStatusColor = ({ complete, error }) => {
  if (error) {
    return 'error';
  }
  if (complete) {
    return 'success';
  }
  return 'legacyGrey';
};

export default function CompleteWrapper({
  children,
  complete,
  error,
  validating,
}) {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <div className={classes.input}>{children}</div>
      <div className={classes.status}>
        <CircularProgress
          color={validating ? 'secondary' : getStatusColor({ error, complete })}
          role={validating ? 'progressbar' : 'hidden'}
          size="1.6rem"
          thickness={3}
          value={100}
          variant={validating ? 'indeterminate' : 'determinate'}
        />
        {getIcon(classes, complete, error, validating)}
      </div>
    </div>
  );
}

CompleteWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  complete: PropTypes.bool,
  validating: PropTypes.bool,
};

CompleteWrapper.defaultProps = {
  error: '',
  complete: false,
  validating: false,
};

function getIcon(classes, complete, error, validating) {
  if (validating) {
    return null;
  }
  if (error) {
    return <Cross className={classes.icon} color="error" fontSize="small" />;
  }
  if (complete) {
    return <Tick className={classes.icon} color="success" fontSize="small" />;
  }
  return null;
}
