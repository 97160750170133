import { Help } from '@heidi-pay/heidi-component-library/components';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { ChevronRightOutlined } from '@material-ui/icons';
import { IconButton, styled, useTheme } from '@mui/material';
import { useTranslation } from '@hooks';

const HelpButton = styled(IconButton)(({ theme }) => ({
  padding: '10px',
  color: theme.palette.primary.main,
  backgroundColor: BaseColour.White,
  marginTop: 'auto',
  marginRight: '20px',
  marginLeft: '-70px',
  position: 'absolute',
  bottom: '0',
  left: '0',
  boxShadow: `0 4px 30px 0 ${BaseColour.BlackFillLight}`,
  '&:hover': {
    backgroundColor: BaseColour.White,
    boxShadow: `0 4px 30px 0 ${BaseColour.BlackFill}`,

    '& > svg': {
      opacity: 0.9,
    },
  },
}));

interface IHelpFloatingButtonMigrated {
  isToggled: boolean;
  onClick: () => void;
}

export const HelpFloatingButtonMigrated = ({
  isToggled,
  onClick,
}: IHelpFloatingButtonMigrated) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <HelpButton
      aria-labelledby="help-button-label"
      onClick={onClick}
      size="large"
    >
      <span hidden={true} id="help-button-label">
        {isToggled
          ? t('helpWidget.floatingButton.close')
          : t('helpWidget.floatingButton.open')}
      </span>
      {isToggled ? (
        <ChevronRightOutlined fontSize="large" />
      ) : (
        <Help color={palette.primary.main} />
      )}
    </HelpButton>
  );
};
