import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { InputFieldAdornment } from '@heidi-pay/heidi-component-library/components/InputFieldAdornment';
import { SelectInput } from '@heidi-pay/heidi-component-library/components/SelectInput';
import {
  FormControl,
  FormHelperText,
  TextField,
  styled,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { setAmount } from '@instore/redux';
import {
  IBaseInputComponent,
  IComponentBaseOptions,
} from '@transaction/components/types';
import { useFormatters } from '@utils';
import { IAmount } from '../../../services/types';

const maxAmount = 999999;

const FieldWrapperStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

interface IInstoreMoneyInputError {
  amount?: { message: string };
  currency?: { message: string };
}

interface IInstoreMoneyInputOptions extends IComponentBaseOptions {
  currencies: string[];
  maxAmount?: number;
}

interface IInstoreMoneyInputProps
  extends IBaseInputComponent<
    IInstoreMoneyInputOptions,
    IAmount,
    IInstoreMoneyInputError
  > {
  value: IAmount;
}

const moneyInputValidation = (
  name: string,
  options?: IInstoreMoneyInputOptions,
) =>
  yup
    .object()
    .shape({
      amount: yup
        .string()
        .required(`fields.moneyInput.${name}.amount.required`)
        .matches(
          /^\d+(\.\d{1,2})?$/,
          `fields.moneyInput.${name}.amount.invalid`,
        )
        .test(
          'greaterThanZero',
          `fields.moneyInput.${name}.amount.invalid`,
          value => Number(value) > 0,
        )
        .test(
          'lessThanMaxAmount',
          `fields.moneyInput.${name}.amount.maxExceeded`,
          value => Number(value) <= (options?.maxAmount || maxAmount),
        ),
      currency: yup
        .string()
        .required(`fields.moneyInput.${name}.currency.required`),
    })
    .required();

export const InstoreMoneyInput = ({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}: IInstoreMoneyInputProps) => {
  const { amount, currency } = value;
  const { currencies = [], disabled } = options ?? {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formatAmountClean } = useFormatters();
  const validationErrorKey = error?.amount?.message || error?.currency?.message;

  const handleOnAmountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const cleanAmount = formatAmountClean(event.target.value);
      onChange({ amount: cleanAmount, currency });
    },
    [currency, formatAmountClean, onChange],
  );

  const handleOnCurrencyChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange({ amount, currency: event.target.value });
    },
    [amount, onChange],
  );

  useEffect(() => {
    const validationSchema = moneyInputValidation(name, options);
    if (validationSchema.isValidSync(value)) {
      dispatch(setAmount(value));
    }
  }, [dispatch, name, options, value]);

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{
        mt: '0.625rem',
      }}
      variant="outlined"
    >
      <FieldWrapperStyled>
        <TextField
          disabled={disabled}
          error={!!error?.amount?.message}
          id={`${name}-amount`}
          InputProps={{
            endAdornment: (
              <InputFieldAdornment isComplete={complete} isError={!!error} />
            ),
          }}
          inputRef={inputRef}
          label={t(`fields.moneyInput.${name}.amount.label`)}
          name={`${name}-amount`}
          onBlur={onBlur}
          onChange={handleOnAmountChange}
          size="small"
          sx={{
            flexGrow: 1,
            mr: '0.5rem',
          }}
          type="text"
          value={amount}
          variant="outlined"
        />
        <SelectInput
          complete={!!currency}
          disabled={disabled}
          id={`${name}-currency`}
          name={`${name}-currency`}
          onBlur={onBlur}
          onChange={handleOnCurrencyChange}
          value={currency}
        >
          {currencies.map(currencyOption => (
            <option key={currencyOption} value={currencyOption}>
              {currencyOption}
            </option>
          ))}
        </SelectInput>
      </FieldWrapperStyled>
      <FormHelperText>
        {t(validationErrorKey || '', {
          amount: maxAmount,
        })}
      </FormHelperText>
    </FormControl>
  );
};

InstoreMoneyInput.defaultProps = {
  error: '',
  complete: false,
  inputRef: undefined,
  options: {
    currencies: [],
    disabled: false,
    maxAmount,
  },
};

InstoreMoneyInput.validation = moneyInputValidation;

InstoreMoneyInput.isComplete = ({ amount, currency }: IAmount) =>
  Boolean(amount && currency);
