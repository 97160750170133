import { useEffect, useState, useCallback } from 'react';
import QRCodeLib from 'qrcode';

interface IQRCode {
  altText: string;
  size?: number;
  text: string;
}

// TODO This component also exists in Portals. Move it to a shared component library
// and remove it from both projects while importing from the common source.
export function QRCode({ altText, size = 200, text }: IQRCode) {
  const [dataUrl, setDataUrl] = useState('');

  const generateQrCode = useCallback(() => {
    if (text.length) {
      QRCodeLib.toDataURL(text).then(url => setDataUrl(url));
    }
  }, [text]);

  useEffect(generateQrCode, [generateQrCode]);

  if (dataUrl === '') {
    return <span>...</span>;
  }

  return <img alt={altText} height={size} src={dataUrl} width={size} />;
}
