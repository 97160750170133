import { Grid, styled, useMediaQuery } from '@mui/material';
import { useTranslation } from '@hooks';

const Circle = styled('div', {
  shouldForwardProp: prop => !['color'].includes(prop),
})`
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 3px;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 2px solid ${props => props.color || '#000'};
  color: ${props => props.color || '#000'};
  text-align: center;
  font-weight: 500;
}`;

const Step = styled(Grid)`
  font-weight: 500;
  font-size: 1rem;
`;

/* eslint-disable react/prop-types */
const StepItem = ({ index, text }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Grid
      container={true}
      justifyContent="center"
      pb={4}
      textAlign={isMobile ? 'center' : 'start'}
    >
      <Grid item={true} sm="1" xs="12">
        <Circle>{index + 1}</Circle>
      </Grid>
      <Step item={true} pt={0.6} sm="7" xs="12">
        {text}
      </Step>
    </Grid>
  );
};

const OnfidoFailureContainer = () => {
  const { t } = useTranslation();
  const steps = t('fields.onfidoFailureSteps');

  return (
    <>
      {steps.map((step, index) => (
        <StepItem index={index} key={step} text={step} />
      ))}
    </>
  );
};

export default OnfidoFailureContainer;
