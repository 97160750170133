import {
  heidipayOriginators,
  pagolightOriginators,
  pagolightProOriginators,
} from '@utils/constants';

interface IGetI18nKeyProps {
  isHeylightBrandingEnabled: boolean;
  isIntroScreen?: boolean;
  isPagolightTextUpdateEnabled: boolean;
  originatorUuid: string;
}

export const getI18nKey = ({
  isHeylightBrandingEnabled,
  isIntroScreen = false,
  isPagolightTextUpdateEnabled,
  originatorUuid,
}: IGetI18nKeyProps): string => {
  const baseKey = 'fields.merchantRedirectSection';
  const colorFormatting = isIntroScreen ? 'white.' : '';
  if (isHeylightBrandingEnabled) {
    if (pagolightOriginators.includes(originatorUuid)) {
      return `${baseKey}.heyLightTextRebrand.${colorFormatting}pagolight`;
    }
    if (pagolightProOriginators.includes(originatorUuid)) {
      return `${baseKey}.heyLightTextRebrand.${colorFormatting}pagolightPro`;
    }
    if (heidipayOriginators.includes(originatorUuid)) {
      return `${baseKey}.heyLightTextRebrand.${colorFormatting}default`;
    }
  }

  if (isPagolightTextUpdateEnabled) {
    return `${baseKey}.pagolightTextRebrand`;
  }

  return `${baseKey}.default`;
};
