export const getPaymentPlanFields = ({
  apr,
  currency,
  firstRepaymentDate,
  fullDetails = true,
  nominalInterestRate,
  taxAmount,
  totalAmountPayable,
  translate,
}) => [
  {
    label: translate('fields.paymentPlanPagolightPro.firstInstalmentDate'),
    type: 'date',
    value: firstRepaymentDate,
  },
  ...(fullDetails
    ? [
        {
          currency,
          label: translate(
            taxAmount > 0
              ? 'fields.paymentPlanPagolightPro.firstRepaymentAmountWithTax'
              : 'fields.paymentPlanPagolightPro.firstRepaymentAmountWithoutTax',
          ),
          type: 'currency',
          value: taxAmount,
        },
        {
          label: translate(
            'fields.paymentPlanPagolightPro.nominalInterestRate',
          ),
          type: 'percentage',
          value: nominalInterestRate,
        },
      ]
    : []),
  {
    label: translate('fields.paymentPlanPagolightPro.apr'),
    type: 'percentage',
    value: apr,
  },
  ...(fullDetails
    ? [
        {
          currency,
          label: translate('fields.paymentPlanPagolightPro.total'),
          type: 'currency',
          value: totalAmountPayable,
        },
      ]
    : []),
];
