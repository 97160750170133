import { useTheme } from '@material-ui/core';
import { CallToAction, CameraAlt, Portrait } from '@material-ui/icons';
import { Box } from '@mui/material';
import { CopyBlock } from '../../../components';
import { StepsList } from './components';
import { IOnfidoWelcomeScreenContainerProps } from './types';

export const BaseTranslationKey = 'fields.onfidoWelcomeScreenContainer.default';

export const OnfidoWelcomeScreenContainer = ({
  options,
}: IOnfidoWelcomeScreenContainerProps) => {
  const { isThreeStepUpload } = options ?? {};
  const outros = ['outro1', 'outro2', 'outro3', 'outro4'];
  const steps = [
    {
      translationKey: `${BaseTranslationKey}.listItems.listItem1`,
      icon: <CallToAction />,
    },
    {
      translationKey: `${BaseTranslationKey}.listItems.listItem2`,
      icon: <Portrait />,
    },
    ...(isThreeStepUpload
      ? [
          {
            translationKey: `${BaseTranslationKey}.listItems.listItem3`,
            icon: <CameraAlt />,
          },
        ]
      : []),
  ];

  const {
    palette: { primary },
  } = useTheme();

  return (
    <Box>
      <CopyBlock i18nKey={`${BaseTranslationKey}.title`} />
      <StepsList
        iconBackgroundColour={primary.main}
        steps={steps}
        titleTranslationKey={`${BaseTranslationKey}.listTitle`}
      />
      {outros.map(key => (
        <CopyBlock
          component="p"
          i18nKey={`${BaseTranslationKey}.outros.${key}`}
          key={key}
          sx={{ margin: '1rem 0' }}
        />
      ))}
    </Box>
  );
};
