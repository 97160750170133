import PropTypes from 'prop-types';
import { FormControl, FormHelperText, Grid, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { ConsentRadioGroup } from './ConsentRadioGroup';

const TextContainer = styled(Grid)`
  font-size: 1rem;
  font-weight: bold;
`;

const ConsentBlock = ({ error, innerRef, name, onChange, options, value }) => {
  const { t } = useTranslation();
  const {
    message: requiresAcceptanceError,
    value: { message: requiresSelectionError } = {},
  } = error;

  const acceptOnly = options?.acceptOnly;

  return (
    <FormControl component="fieldset" error={!!error} fullWidth={true}>
      <Grid container={true} ref={innerRef}>
        <TextContainer id="ilCliente" item={true} sm={4} xs={12}>
          {t('fields.consentBlock.label')}
        </TextContainer>
        <Grid item={true} sm={8} xs={12}>
          <ConsentRadioGroup
            acceptOnly={acceptOnly}
            error={error}
            name={name}
            onChange={onChange}
            value={value}
          />
        </Grid>
      </Grid>
      <FormHelperText sx={{ marginLeft: 0 }}>
        {t(requiresSelectionError || requiresAcceptanceError)}
      </FormHelperText>
    </FormControl>
  );
};

ConsentBlock.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.shape(),
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  onChange: PropTypes.func.isRequired,
};

ConsentBlock.defaultProps = {
  error: {},
  innerRef: null,
};

export default ConsentBlock;
