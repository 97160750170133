import PropTypes from 'prop-types';
import { isValid, formatISO } from 'date-fns';
import de from 'date-fns/locale/de';
import enGBLocale from 'date-fns/locale/en-GB';
import frCHLocale from 'date-fns/locale/fr-CH';
import it from 'date-fns/locale/it';
import * as yup from 'yup';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useTranslation } from '@hooks';

import CompleteWrapper from '../../components/CompleteWrapper';

const localeMap = {
  'en-gb': enGBLocale,
  'fr-ch': frCHLocale,
  'it-ch': it,
  'de-ch': de,
};

const useStyles = makeStyles({
  root: {
    '& .MuiInputAdornment-positionEnd > button': {
      padding: 0,
    },
  },
});

export default function DateOfBirthInput({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}) {
  const { i18n, t } = useTranslation();
  const classes = useStyles();

  const locale = i18n.language;
  const adapterLocale = localeMap[locale.toLowerCase()];
  const { allowOnlyFutureDate = false } = options || {};

  const handleChange = date => {
    if (isValid(date)) {
      return onChange(
        formatISO(date, {
          representation: 'date',
        }),
      );
    }
    return onChange(date);
  };

  return (
    <MuiPickersUtilsProvider locale={adapterLocale} utils={DateFnsUtils}>
      <CompleteWrapper complete={complete} error={error}>
        <KeyboardDatePicker
          cancelLabel={t('fields.dateOfBirthInput.cancel')}
          className={classes.root}
          disableFuture={!allowOnlyFutureDate}
          error={!!error}
          format="dd/MM/yyyy"
          helperText={!!error && t(error)}
          id={name}
          inputRef={inputRef}
          inputVariant="outlined"
          label={t(`fields.dateOfBirthInput.${name}.label`)}
          margin="none"
          okLabel={t('fields.dateOfBirthInput.confirm')}
          onBlur={onBlur}
          onChange={handleChange}
          size="small"
          value={value || null}
          variant="dialog"
        />
      </CompleteWrapper>
    </MuiPickersUtilsProvider>
  );
}

DateOfBirthInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  error: PropTypes.string,
  complete: PropTypes.bool,
  options: PropTypes.shape({
    optional: PropTypes.bool,
  }),
};

DateOfBirthInput.defaultProps = {
  error: '',
  complete: false,
  inputRef: undefined,
  options: {
    optional: false,
  },
};

function yesterday() {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  return new Date(d.toDateString());
}

function getTomorrowDate() {
  const d = new Date();
  d.setDate(d.getDate() + 1);
  return new Date(d.toDateString());
}

DateOfBirthInput.validation = (
  name,
  { allowOnlyFutureDate = false, optional = false },
) => {
  let schema = yup
    .date()
    .transform((currentValue, originalValue) =>
      originalValue ? currentValue : null,
    );

  if (!optional) {
    schema = schema.required(`fields.dateOfBirthInput.${name}.required`);
  }

  if (allowOnlyFutureDate) {
    return schema
      .typeError(`fields.dateOfBirthInput.${name}.invalid`)
      .min(getTomorrowDate(), 'fields.dateOfBirthInput.noPast')
      .nullable();
  }
  return schema
    .typeError(`fields.dateOfBirthInput.${name}.invalid`)
    .max(yesterday(), 'fields.dateOfBirthInput.noFuture')
    .nullable();
};

DateOfBirthInput.formatting = value => {
  if (isValid(value)) {
    return formatISO(value, {
      representation: 'date',
    });
  }
  return value;
};
