import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '@hooks';

import { setMonthlyPayments } from '../../payment/redux';

const useStyles = makeStyles(theme => ({
  group: {
    justifyContent: 'space-evenly',
    marginTop: '0.5rem',
  },
  radio: {
    marginRight: '0',
    '& .Mui-checked': {
      '& svg:nth-child(2)': {
        color: theme.palette.primary.main,
      },
    },
  },
  label: {
    '&.Mui-focused, &.Mui-error': {
      color: '#333',
    },
  },
}));

export default function MonthlyPaymentsPicker({
  name,
  onBlur,
  onChange,
  options,
  value,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMonthlyPayments(value));
  }, [dispatch, value]);

  return (
    <FormControl component="fieldset" fullWidth={true} size="small">
      <FormLabel className={classes.label} component="legend">
        {t('fields.monthlyPaymentsPicker.label')}
      </FormLabel>
      <RadioGroup
        aria-label="monthly payments"
        className={classes.group}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        row={true}
        value={Number(value)}
      >
        {options.map(option => (
          <FormControlLabel
            className={classes.radio}
            control={<Radio />}
            key={option}
            label={option}
            value={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

MonthlyPaymentsPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};
