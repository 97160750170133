import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { FeatureFlag, useFeatureFlags } from '@hooks';
import { getMerchantDetails } from '@redux/selectors';

const ImageContainer = styled('div')({
  flex: '1',
  paddingRight: '1rem',
  paddingLeft: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});

const Image = styled('img')({
  maxWidth: '100%',
  maxHeight: '60px',
  objectPosition: 'center center',
  '@media(max-width: 600px)': {
    maxHeight: '40px',
  },
});

interface ILogoProps {
  altText: string;
  src: string;
}

const Logo = ({ altText, src }: ILogoProps) => (
  <ImageContainer>
    <Image alt={altText} src={src} />
  </ImageContainer>
);

export const HeaderLogo = () => {
  const { flagEnabled } = useFeatureFlags();
  const isHeyLightBrandingEnabled = flagEnabled(
    FeatureFlag.IsHeyLightBrandingEnabled,
  );
  const isHeylightOverwriteByMerchantBrandingEnabled = flagEnabled(
    FeatureFlag.IsHeylightOverwriteByMerchantBrandingEnabled,
  );
  const { logo: merchantLogo, name: merchantName } =
    useSelector(getMerchantDetails);

  if (
    isHeyLightBrandingEnabled &&
    !isHeylightOverwriteByMerchantBrandingEnabled
  ) {
    return (
      <Logo
        altText="HeyLight logo"
        src="https://storage.cloud.google.com/heidipay-sbx-fe-resources/logos/heylight_light_standard.svg"
      />
    );
  }

  if (!merchantLogo) {
    return null;
  }

  return <Logo altText={`${merchantName} logo`} src={merchantLogo} />;
};
