import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SelectPicker } from '../../../transaction/components/SelectPicker';
import { setSelectedProductVariant, updateOrderProfiles } from '../../redux';
import {
  getSelectedProductVariant,
  getProductSku,
  getProductDetails,
} from '../../redux/selectors';

export const VariantsPicker = ({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  value,
}) => {
  const { priceFieldName, variant } = options;
  const [variantDetails, setVariantDetails] = useState();
  const [variantOptions, setVariantOptions] = useState([]);
  const [variantEntries, setVariantEntries] = useState([]);

  const productDetails = useSelector(getProductDetails);
  const selectedProductVariant = useSelector(getSelectedProductVariant);
  const productSku = useSelector(getProductSku);
  const dispatch = useDispatch();

  useEffect(() => {
    setVariantDetails(productDetails?.variants);
  }, [productDetails?.variants]);

  useEffect(() => {
    dispatch(setSelectedProductVariant(null));
    dispatch(updateOrderProfiles([]));
    setValue(name, '');
  }, [dispatch, name, productSku, setValue]);

  useEffect(() => {
    const expectedPrice = productDetails?.expectedPrice;
    const variantPrice = selectedProductVariant?.price;

    if (expectedPrice) {
      const amount = variantPrice ?? expectedPrice.amount;
      setValue(priceFieldName, { ...expectedPrice, amount });
    }
  }, [priceFieldName, productDetails, selectedProductVariant, setValue]);

  useEffect(() => {
    if (variantDetails) {
      const entries = Object.entries(variantDetails[variant]?.variants ?? []);
      setVariantEntries(entries);

      const variantOpts = entries.map(([key, val]) => ({
        value: `${variant}|${key}`,
        text: val.label,
      }));

      setVariantOptions(variantOpts);
    } else {
      setVariantEntries([]);
      setVariantOptions([]);
    }
  }, [variant, variantDetails]);

  const handleVariantChange = useCallback(
    selectedVariant => {
      const selectedVariantKey = selectedVariant.target.value.split('|')[1];
      const selectedVar = variantEntries
        .map(([key, val]) => ({ ...val, key }))
        .find(({ key }) => key === selectedVariantKey);

      dispatch(
        setSelectedProductVariant(
          selectedVar
            ? {
                ...selectedVar,
                type: variant,
              }
            : null,
        ),
      );
      onChange(selectedVariant);
    },
    [dispatch, onChange, variant, variantEntries],
  );

  if (!variantOptions.length || !variantDetails?.[variant]) {
    return null;
  }

  const selectedValue = selectedProductVariant?.key
    ? `${variant}|${selectedProductVariant.key}`
    : value;

  const translationKey = `fields.variantPicker.${variantDetails[variant].label}.label`;

  return (
    <SelectPicker
      complete={complete}
      displayRawValues={true}
      error={error}
      inputRef={inputRef}
      label={translationKey}
      name={name}
      onBlur={onBlur}
      onChange={handleVariantChange}
      options={{ items: variantOptions }}
      value={selectedValue}
    />
  );
};

VariantsPicker.propTypes = {
  complete: PropTypes.bool,
  error: PropTypes.string,
  inputRef: PropTypes.shape({ current: PropTypes.any }),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.shape({
    priceFieldName: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
};

VariantsPicker.defaultProps = {
  complete: false,
  error: '',
  inputRef: undefined,
  value: '',
};
