import { forwardRef, useState } from 'react';
import {
  TextField,
  FormControl,
  FormHelperText,
  IconButton,
  styled,
} from '@mui/material';
import { useTranslation } from '@hooks';
import {
  extractNumbersFromString,
  toKeyValuePairOrNull,
} from '../utils/valueFormatters';
import { ChainedAutocompleteIcon } from './ChainedAutocompleteIcon';

const TextFieldNoPadding = styled(TextField)({
  '.MuiOutlinedInput-root': {
    paddingRight: 6,
  },
});

const ChainedTextField = forwardRef(
  (
    {
      error = false,
      id,
      maxLength = 6,
      name,
      onBlur,
      onChange,
      type = 'text',
      upstreamFilter = null,
    },
    ref,
  ) => {
    const [value, setValue] = useState('');
    const { t } = useTranslation();
    const disabled = !upstreamFilter;

    const handleOnChange = event => {
      const {
        target: { value: inputValue },
      } = event;
      const parsedInputValue =
        type === 'number'
          ? extractNumbersFromString(inputValue).slice(0, maxLength)
          : inputValue;
      setValue(parsedInputValue);
      onChange(toKeyValuePairOrNull(parsedInputValue));
    };

    return (
      <FormControl error={true} fullWidth={true}>
        <TextFieldNoPadding
          autoComplete="off"
          disabled={disabled}
          error={error}
          id={id}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-disabled="true"
                disabled={true}
                sx={{ paddingRight: '4px' }}
              >
                <ChainedAutocompleteIcon
                  hasError={error}
                  isComplete={!!value && !error}
                  isDisabled={disabled}
                />
              </IconButton>
            ),
          }}
          inputRef={ref}
          label={t(`fields.addressContainer.subFields.${name}.label`)}
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          size="small"
          value={value}
        />
        {error ? (
          <FormHelperText>
            {t(`fields.addressContainer.subFields.${name}.required`)}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  },
);

ChainedTextField.displayName = 'ChainedTextField';

export default ChainedTextField;
