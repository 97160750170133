import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../components/CompleteWrapper';
import { formatAmountIfExists } from '../../utils/amounts';

export default function ProductSelect({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const initalDescription = options.find(
    option => option.uuid === value,
  ).description;
  const [productDescription, setProductDescription] =
    useState(initalDescription);

  const handleChange = useCallback(
    event => {
      onChange(event);
      setProductDescription(
        options.find(option => option.uuid === event.target.value).description,
      );
    },
    [onChange, options, setProductDescription],
  );
  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <InputLabel htmlFor={name}>
        {t(`fields.productSelect.${name}.label`)}
      </InputLabel>
      <CompleteWrapper complete={complete} error={error}>
        <Select
          id={name}
          inputRef={inputRef}
          label={t(`fields.productSelect.${name}.label`)}
          name={name}
          native={true}
          onBlur={onBlur}
          onChange={handleChange}
          value={value}
        >
          {options.map(({ name: productName, price, uuid }) => (
            <option key={uuid} value={uuid}>
              {productName} {formatAmountIfExists(price, locale)}
            </option>
          ))}
        </Select>
      </CompleteWrapper>
      {productDescription ? (
        <Typography paragraph={true} sx={{ margin: '10px', marginBottom: 0 }}>
          {productDescription}
        </Typography>
      ) : null}
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
}

ProductSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  error: PropTypes.string,
  complete: PropTypes.bool,
};

ProductSelect.defaultProps = {
  error: '',
  complete: false,
  inputRef: undefined,
};

ProductSelect.validation = () =>
  yup.string().required('fields.productSelect.required');
