import {
  useState,
  useRef,
  useCallback,
  FocusEvent,
  KeyboardEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import mem from 'mem';
import * as yup from 'yup';
import { FormControl, FormHelperText, Box } from '@mui/material';

import { CopyBlock } from '../../../../components/CopyBlock';
import { getDispatch } from '../../../../store';

import {
  checkVerificationCode,
  resendVerificationCode,
  getIsVerifying,
} from '../../../../verification/redux';
import { CodeInput } from './components/CodeInput';

const VERIFICATION_CODE_LENGTH = 6;

interface IMobileVerificationCodeInputProps {
  clearError: () => void;
  error: string | null;
  name: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  value: string;
}

export const MobileVerificationCodeInput = ({
  clearError,
  error,
  name,
  onBlur,
  onChange,
  value,
}: IMobileVerificationCodeInputProps) => {
  const { t } = useTranslation();
  const isVerifying = useSelector(getIsVerifying);
  const dispatch = useDispatch();
  const [codeArray, setCodeArray] = useState<string[]>(
    value ? value.split('') : Array(VERIFICATION_CODE_LENGTH).fill(''),
  );
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const resend = useCallback(() => {
    setCodeArray(Array(VERIFICATION_CODE_LENGTH).fill(''));
    clearError();
    dispatch(resendVerificationCode());
  }, [clearError, dispatch]);

  const handleChange = useCallback(
    (index: number, inputValue: string) => {
      setCodeArray(prevCode => {
        const newCode = [...prevCode];
        newCode[index] = inputValue.slice(-1);

        if (inputValue && index < VERIFICATION_CODE_LENGTH - 1) {
          inputRefs.current[index + 1]?.focus();
        }

        const fullCode = newCode.join('');
        onChange(fullCode);
        if (fullCode.length === VERIFICATION_CODE_LENGTH) {
          clearError();
          onBlur();
        }

        return newCode;
      });
    },
    [onChange, clearError, onBlur],
  );

  const handleKeyDown = useCallback(
    (index: number, e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && !codeArray[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    },
    [codeArray],
  );

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  }, []);

  const setInputRef = useCallback(
    (el: HTMLInputElement | null, index: number) => {
      inputRefs.current[index] = el;
    },
    [],
  );

  return (
    <FormControl component="fieldset" error={!!error} fullWidth={true}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        margin="10px 0"
      >
        <Box display="flex" gap={2}>
          {codeArray.map((digit, index) => (
            <CodeInput
              digit={digit}
              error={!!error}
              index={index}
              isVerifying={isVerifying}
              key={index}
              name={name}
              onChange={handleChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              setInputRef={setInputRef}
            />
          ))}
        </Box>
      </Box>
      {error && <FormHelperText>{t(error)}</FormHelperText>}
      <CopyBlock
        i18nKey="fields.mobileVerificationCodeInput.heyLightTextRebrand.resend"
        props={{ onClick: resend }}
      />
    </FormControl>
  );
};

MobileVerificationCodeInput.validation = () =>
  yup
    .string()
    .required('fields.mobileVerificationCodeInput.heyLightTextRebrand.required')
    .min(
      VERIFICATION_CODE_LENGTH,
      'fields.mobileVerificationCodeInput.heyLightTextRebrand.required',
    )
    .max(
      VERIFICATION_CODE_LENGTH,
      'fields.mobileVerificationCodeInput.heyLightTextRebrand.required',
    )
    .test(
      'verificationCode',
      'fields.mobileVerificationCodeInput.heyLightTextRebrand.invalid',
      mem(async (value: string | undefined) => {
        if (value?.length !== VERIFICATION_CODE_LENGTH) {
          return false;
        }
        const dispatch = getDispatch();
        return dispatch(checkVerificationCode(value));
      }),
    );
