import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProgressBar } from '@heidi-pay/heidi-component-library/components';
import { FormControl } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { setAbandonVisible } from '@redux';
import { CopyBlock } from '../../../../components';
import { SentrySeverity } from '../../../../utils';

const DEFAULT_REDIRECT_SECONDS = 8;

interface IMerchantRedirectBlockProps {
  name: string;
  options?: {
    hideProgress?: boolean;
    hideText?: boolean;
    redirectSeconds?: number;
  };
  value: string;
}

export const MerchantRedirectBlock = ({
  name,
  options,
  value: url,
}: IMerchantRedirectBlockProps) => {
  const {
    hideProgress,
    hideText = false,
    redirectSeconds = DEFAULT_REDIRECT_SECONDS,
  } = options ?? {};

  const [countdown, setCountdown] = useState(0);
  const dispatch = useDispatch();

  const updateAbandonVisible = useCallback(
    (value: boolean) => dispatch(setAbandonVisible(value)),
    [dispatch],
  );

  const countdownIncrement = useMemo((): number => {
    if (redirectSeconds === 0) {
      return 100;
    }
    return 10 / redirectSeconds;
  }, [redirectSeconds]);

  useEffect(() => {
    const logToSentryAndRedirect = async (): Promise<void> => {
      try {
        Sentry.captureMessage(
          `User redirected to the merchant e-commerce platform. URL: ${url}`,
          SentrySeverity.info,
        );
        await Sentry.flush(1000);
      } finally {
        window.location.assign(url);
      }
    };

    if (countdown === 100) {
      setTimeout(() => {
        window.onbeforeunload = null;
        logToSentryAndRedirect();
      }, 500);
    }
  }, [countdown, url]);

  const updateCountdown = useCallback(() => {
    setCountdown(prevCountdown =>
      prevCountdown >= 100 ? 100 : prevCountdown + countdownIncrement,
    );
  }, [countdownIncrement]);

  useEffect(() => {
    updateAbandonVisible(false);
    const timer = setInterval(updateCountdown, 100);
    return () => {
      clearInterval(timer);
      updateAbandonVisible(true);
    };
  }, [countdownIncrement, updateAbandonVisible, updateCountdown]);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      {hideProgress ? null : (
        <ProgressBar countdown={countdown} isRounded={true} />
      )}
      {hideText ? null : (
        <CopyBlock
          i18nKey={`fields.merchantRedirectBlockHeylight.${name}.content`}
        />
      )}
    </FormControl>
  );
};
