import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { FormControl, FormHelperText, Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import {
  resendVerificationCode,
  getIsVerifying,
} from '../../../verification/redux';
import { VerificationCodeTypes } from '../../../verification/services/VerificationService';
import CodeVerification from '../CodeVerification';
import { ActionButtonsInline, ActionButtonsStacked } from './components';
import { ISignContract, IValidationContext } from './types';

const defaultNumberOfDigits = 5;

const validationContext: IValidationContext = {
  numberOfDigits: defaultNumberOfDigits,
};

const SignContract = ({
  complete = false,
  error = '',
  name,
  onChange,
  options = { digits: defaultNumberOfDigits, continueButtonOptions: {} },
  submit,
}: ISignContract) => {
  const {
    continueButtonOptions = {},
    digits,
    overrideKey = 'default',
    withStackedButtons = false,
  } = options;

  validationContext.numberOfDigits = digits;
  const dispatch = useDispatch();
  const isVerifying = useSelector(getIsVerifying);
  const { t } = useTranslation();

  const handleCodeVerification = useCallback(
    code => onChange(code),
    [onChange],
  );

  const handleCodeReSend = useCallback(
    () => dispatch(resendVerificationCode(VerificationCodeTypes.signContract)),
    [dispatch],
  );

  const handleSubmit = useCallback(() => submit(), [submit]);

  return (
    <>
      <Grid container={true} pt={12}>
        <Grid item={true} textAlign="center" xs={12}>
          <FormControl error={!!error}>
            <CodeVerification
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - ignore for now until we convert CodeVerification to TS
              digits={digits}
              hasError={!!error}
              id={name}
              isVerified={complete}
              isVerifying={isVerifying}
              onComplete={handleCodeVerification}
              onDigitChange={onChange}
            />
            {error ? (
              <FormHelperText>
                {t('fields.mobileVerificationCodeInput.invalid')}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
      </Grid>
      {withStackedButtons ? (
        <ActionButtonsStacked
          primaryButtonAction={handleSubmit}
          primaryButtonOptions={continueButtonOptions}
          secondaryButtonAction={handleCodeReSend}
          secondaryButtonLabel={t(`fields.signContract.${overrideKey}.button`)}
        />
      ) : (
        <ActionButtonsInline
          primaryButtonAction={handleSubmit}
          primaryButtonOptions={continueButtonOptions}
          secondaryButtonAction={handleCodeReSend}
          secondaryButtonLabel={t(`fields.signContract.${overrideKey}.button`)}
        />
      )}
    </>
  );
};

export const validateNumberOfDigits = async (values?: string) =>
  values?.length === validationContext.numberOfDigits;

SignContract.validation = () =>
  yup.string().test('signContract', 'ERROR', validateNumberOfDigits);

export default SignContract;
