import { useCallback } from 'react';
import * as yup from 'yup';
import { SelectInput } from '@heidi-pay/heidi-component-library/components/SelectInput';
import {
  FormControl,
  FormHelperText,
  SxProps,
  Theme,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from '@hooks';
import useSelectPickerUtil from '../../SelectPicker/utils/SelectPickerUtil';
import { StackedLabel } from '../../StackedLabel';
import { IBaseInputComponent, IComponentBaseOptions } from '../../types';

export interface ISelectPickerItem {
  text: string;
  value: string;
}

export interface ISelectPickerOptions extends IComponentBaseOptions {
  items: ISelectPickerItem[];
  sorted?: boolean;
}

export interface ISelectPickerProps
  extends IBaseInputComponent<ISelectPickerOptions, string> {
  displayRawValues?: boolean;
  hasEmptyOption?: boolean;
  label?: string;
  name: string;
  sx?: SxProps<Theme>;
}

export const SelectPicker = ({
  displayRawValues,
  error,
  hasEmptyOption = true,
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  options,
  sx,
  value,
}: ISelectPickerProps) => {
  const { disabled, items, overrideKey, tooltip, withStackedLabel } =
    options || {};

  const { t } = useTranslation();
  const displayLabel = t(
    label ??
      `fields.selectPicker.${
        overrideKey ? `${overrideKey}.` : ''
      }${name}.label`,
  );

  const { sortAndTranslateItemsBackwardCompatible } = useSelectPickerUtil();
  const selectItems = displayRawValues
    ? items
    : sortAndTranslateItemsBackwardCompatible(options, name, t, overrideKey);
  const handleOnChange = useCallback(
    (event: SelectChangeEvent<string>) => onChange(event.target.value),
    [onChange],
  );

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem', ...sx }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel label={displayLabel} name={name} tooltip={tooltip} />
      ) : null}
      <SelectInput
        complete={!!value && !error}
        disabled={disabled}
        hasEmptyOption={hasEmptyOption}
        hasError={!!error}
        id={name}
        inputRef={inputRef}
        label={withStackedLabel ? undefined : displayLabel}
        name={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        selectItems={selectItems}
        sx={{ mt: withStackedLabel ? '10px' : 0 }}
        value={value ?? ''}
      />
      {error ? <FormHelperText>{t(error)}</FormHelperText> : null}
    </FormControl>
  );
};

SelectPicker.validation = () =>
  yup.string().required('fields.selectPicker.required');
