import React, { useCallback } from 'react';
import * as yup from 'yup';
import { Checkbox } from '@heidi-pay/heidi-component-library/components';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { styled } from '@mui/material';
import { useTranslation } from '@hooks';

import { CopyBlock } from '../../../../components';

interface ITermsAndConditionsCheckboxProps {
  error?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  name: string;
  onBlur: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onChange: (checked: boolean) => void;
  options: Record<string, unknown>;
  value: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)({
  fontSize: '0.8rem',
  justifyContent: 'space-between',
  marginLeft: '0',
  '& > .MuiFormControlLabel-label': {
    fontSize: '0.8rem',
    margin: '0.5rem 0',
  },
  '& button, a': {
    fontWeight: '400',
    lineHeight: 1.5,
    minHeight: '0',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
  '& button:hover, a:hover': {
    background: 'transparent',
    textDecoration: 'none',
  },
});

export const TermsAndConditionsCheckbox = ({
  error = '',
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}: ITermsAndConditionsCheckboxProps) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.checked),
    [onChange],
  );

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
    >
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={value}
            error={error}
            inputRef={inputRef}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
          />
        }
        label={
          <CopyBlock
            i18nKey="fields.termsAndConditionsCheckbox.label"
            props={options}
          />
        }
        labelPlacement="start"
      />
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

TermsAndConditionsCheckbox.validation = () =>
  yup.bool().oneOf([true], 'fields.termsAndConditionsCheckbox.required');
