import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import PaymentMethodIcon from '../../components/PaymentMethodIcon';

const useStyles = makeStyles({
  availableSpecs: {
    display: 'inline-flex',
    justifyContent: 'space-around',
    marginTop: '20px',
    gap: '10px',
  },
});

export default function PaymentMethodsDisplay({ options }) {
  const classes = useStyles();
  return (
    <Grid className={classes.availableSpecs}>
      {options.map(methodName => (
        <PaymentMethodIcon
          key={methodName}
          paymentMethod={methodName}
          size={1.5}
        />
      ))}
    </Grid>
  );
}

PaymentMethodsDisplay.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
};
PaymentMethodsDisplay.defaultProps = {
  options: [],
};
