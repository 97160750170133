import { useEffect, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import InputCell from './InputCell';
import useCodeVerification from './useCodeVerification';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

const CodeVerification = memo(
  ({
    digits,
    hasError = false,
    isVerified,
    isVerifying = false,
    onComplete,
    onDigitChange,
  }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const baseAriaLabel = t('fields.codeVerification.label');
    const hasLoaded = useRef(false);

    const { inputRefs, onChange, onKeyDown, onKeyUp } = useCodeVerification(
      digits,
      onComplete,
      onDigitChange,
    );

    useEffect(() => {
      if (!hasLoaded.current) {
        inputRefs[0].current?.focus();
        hasLoaded.current = true;
      }
    }, [inputRefs, hasLoaded]);

    const fields = inputRefs.map((ref, index) => {
      const nextInputRef = index === digits - 1 ? null : inputRefs[index + 1];
      const previousInputRef = index > 0 ? inputRefs[index - 1] : ref;
      const ariaLabel = baseAriaLabel
        .replace('{index}', index + 1)
        .replace('{total}', digits);

      return (
        <InputCell
          ariaLabel={ariaLabel}
          digits={digits}
          key={ariaLabel}
          next={nextInputRef}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          prev={previousInputRef}
          reference={ref}
        />
      );
    });

    return (
      <CompleteWrapper
        className="notranslate"
        complete={isVerified}
        error={hasError}
        validating={isVerifying}
      >
        <Grid className={classes.root} item={true}>
          {fields}
        </Grid>
      </CompleteWrapper>
    );
  },
);

CodeVerification.displayName = 'CodeVerification';

CodeVerification.propTypes = {
  digits: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
  onDigitChange: PropTypes.func,
  isVerified: PropTypes.bool,
  isVerifying: PropTypes.bool,
  hasError: PropTypes.bool,
};

CodeVerification.defaultProps = {
  isVerified: false,
  isVerifying: false,
  hasError: false,
  onComplete: null,
  onDigitChange: null,
};

export default CodeVerification;
