import { Avatar, Box, List, ListItem, ListItemAvatar } from '@mui/material';
import { CopyBlock } from '../../../../components';

interface IStep {
  icon: React.ReactNode;
  translationKey: string;
}

interface IStepListProps {
  iconBackgroundColour?: string;
  steps: IStep[];
  titleTranslationKey: string;
}

export const StepsList = ({
  iconBackgroundColour,
  steps,
  titleTranslationKey,
}: IStepListProps) => (
  <Box py=".5rem">
    <CopyBlock i18nKey={titleTranslationKey} />
    <List sx={{ marginLeft: '8px' }}>
      {steps.map(({ icon, translationKey }) => (
        <ListItem key={translationKey} sx={{ paddingLeft: 0 }}>
          <ListItemAvatar sx={{ alignSelf: 'flex-start', pt: '4px' }}>
            <Avatar sx={{ bgcolor: iconBackgroundColour || 'transparent' }}>
              {icon}
            </Avatar>
          </ListItemAvatar>
          <CopyBlock component="p" i18nKey={translationKey} />
        </ListItem>
      ))}
    </List>
  </Box>
);
