import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import { styled } from '@mui/material';

const Input = styled('input', {
  shouldForwardProp: prop => !['digits'].includes(prop),
})`
  text-align: center;
  width: ${props => 80 / props.digits}%;
  height: 40px;
  max-width: 40px;
  font-size: 25px;
  border: none;
  border-bottom: 1px solid #000;
  padding: 0;
  border-radius: 0;

  &:focus {
    outline: none;
    border-bottom: 2px solid
      ${props => props.theme?.palette?.primary?.main || '#03c2fc'};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const InputCell = ({
  ariaLabel,
  digits,
  next,
  onChange,
  onKeyDown,
  onKeyUp,
  prev,
  reference,
}) => {
  const theme = useTheme();

  const handleChange = e => onChange(e, next);

  const handleKeyUp = e => onKeyUp(e, prev);

  return (
    <Input
      aria-label={ariaLabel}
      autoComplete="one-time-code"
      digits={digits}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      onKeyUp={handleKeyUp}
      pattern="[0-9]*)"
      ref={reference}
      theme={theme}
      type="number"
    />
  );
};

InputCell.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  digits: PropTypes.number.isRequired,
  next: PropTypes.shape({
    current: null,
  }),
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  prev: PropTypes.shape().isRequired,
  reference: PropTypes.shape().isRequired,
};

InputCell.defaultProps = {
  next: { current: null },
};

export default InputCell;
