import { isValid, formatISO } from 'date-fns';
import * as yup from 'yup';
import { getTomorrowDate, yesterday } from './utils';

export const dateValidationSchema = (
  name: string,
  {
    allowOnlyFutureDate = false,
    optional = false,
  }: { allowOnlyFutureDate?: boolean; optional?: boolean },
) => {
  let schema = yup
    .date()
    .transform((currentValue, originalValue) =>
      originalValue ? currentValue : null,
    );

  if (!optional) {
    schema = schema.required(`fields.dateOfBirthInput.${name}.required`);
  }
  if (allowOnlyFutureDate) {
    return schema
      .typeError(`fields.dateOfBirthInput.${name}.invalid`)
      .min(getTomorrowDate(), 'fields.dateOfBirthInput.noPast')
      .nullable();
  }
  return schema
    .typeError(`fields.dateOfBirthInput.${name}.invalid`)
    .max(yesterday(), 'fields.dateOfBirthInput.noFuture')
    .nullable();
};

export const dateFormatting = (value: Date) => {
  if (isValid(value)) {
    return formatISO(value, {
      representation: 'date',
    });
  }
  return value;
};
