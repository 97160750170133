import { Controller } from 'react-hook-form';
import ChainedTextField from './ChainedTextField';

export const ControlledChainedTextField = ({
  control,
  errors = false,
  id,
  name,
  submitAttempted = false,
  type = 'text',
  upstreamFilter = null,
}) => (
  <Controller
    control={control}
    name={name}
    render={({ onBlur, onChange, ref }, { isTouched }) => (
      <ChainedTextField
        autoComplete="off"
        error={isTouched || submitAttempted ? !!errors[name]?.message : false}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
        type={type}
        upstreamFilter={upstreamFilter}
      />
    )}
  />
);
