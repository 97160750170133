import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { FormControl, Typography } from '@mui/material';
import { useTranslation } from '@hooks';
import { formatAmountIfExists } from '@utils/amounts';
import { SelectPicker } from '../SelectPicker';

export const ProductSelect = ({
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const initalDescription = options.find(
    option => option.uuid === value,
  ).description;
  const [productDescription, setProductDescription] =
    useState(initalDescription);

  const handleChange = useCallback(
    event => {
      onChange(event);
      setProductDescription(
        options.find(option => option.uuid === event).description,
      );
    },
    [onChange, options, setProductDescription],
  );

  const productOptions = options.map(({ name: productName, price, uuid }) => ({
    value: uuid,
    text: `${productName} ${formatAmountIfExists(price, locale)}`,
  }));
  const selectPickerOptions = {
    items: productOptions,
  };

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <SelectPicker
        complete={!!value}
        displayRawValues={true}
        error={error}
        hasEmptyOption={false} // The empty option comes from BE with a blank uuid
        inputRef={inputRef}
        label={t(`fields.productSelect.${name}.label`)}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        options={selectPickerOptions}
        value={value}
      />
      {productDescription ? (
        <Typography sx={{ marginTop: '10px', marginLeft: '16px' }}>
          {productDescription}
        </Typography>
      ) : null}
    </FormControl>
  );
};

ProductSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  error: PropTypes.string,
  complete: PropTypes.bool,
};

ProductSelect.defaultProps = {
  error: '',
  complete: false,
  inputRef: undefined,
};

ProductSelect.validation = () =>
  yup.string().required('fields.productSelect.required');
