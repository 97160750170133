import { ValueOf } from '@appTypes';

export const Actions = Object.freeze({
  awaitIntrumIdentity: 'await-intrum-identity',
  awaitIntrumSigning: 'await-intrum-signing',
  awaitOnfidoResult: 'await-onfido-result',
  completeTransaction: 'complete-transaction',
  completeTransactionMediobanca: 'complete-transaction-mediobanca',
  completeTransactionCardChange: 'complete-transaction-card-change',
  completeTransactionPagolightPro: 'complete-transaction-pagolight-pro',
  failTransaction: 'fail-transaction',
  initialiseExternalPagolightPro: 'initialise-external-pagolight-pro',
  initialiseHeylightItalianBNPL: 'initialise-heylight-italian-bnpl',
  initialiseHeylightItalianCredit: 'initialise-heylight-italian-credit',
  initialiseHeylightSwiss: 'initialise-heylight-swiss',
  presentVerificationPending: 'present-verification-pending',
  processingPleaseWait: 'processing-please-wait',
  transactionExpired: 'transaction-expired',
  selectScheduleSwissCredit: 'select-schedule-swiss-credit',
  signCustomerAgreementPagolightPro: 'sign-customer-agreement-pagolight-pro',
});

type ActionValues = ValueOf<typeof Actions>;

interface IGetActionSettings {
  isHeylightBrandingEnabled: boolean;
}

export const getActionSettings = ({
  isHeylightBrandingEnabled = false,
}: Partial<IGetActionSettings> = {}) => {
  const actionName =
    (window.location.pathname.split('/')[1] as ActionValues) ?? '';

  const actionsAbandonDisabled: ActionValues[] = [
    Actions.transactionExpired,
    Actions.awaitIntrumIdentity,
    Actions.awaitIntrumSigning,
    Actions.completeTransaction,
    Actions.completeTransactionCardChange,
    Actions.completeTransactionMediobanca,
    Actions.completeTransactionPagolightPro,
    Actions.presentVerificationPending,

    ...(isHeylightBrandingEnabled
      ? [Actions.signCustomerAgreementPagolightPro]
      : []),
  ];

  const actionsLoadingBackdropDisabled: ActionValues[] = [
    Actions.awaitIntrumIdentity,
    Actions.awaitIntrumSigning,
    Actions.awaitOnfidoResult,
    Actions.initialiseExternalPagolightPro,
    Actions.initialiseHeylightItalianBNPL,
    Actions.initialiseHeylightItalianCredit,
    Actions.initialiseHeylightSwiss,
  ];

  const clientSideActions: ActionValues[] = [Actions.processingPleaseWait];

  return {
    isAbandonDisabled: actionsAbandonDisabled.includes(actionName),
    isClientSideAction: clientSideActions.includes(actionName),
    isLoadingBackdropDisabled:
      actionsLoadingBackdropDisabled.includes(actionName),
  };
};
