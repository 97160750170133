import {
  Declined,
  ThumbsUp,
} from '@heidi-pay/heidi-component-library/components';
import {
  heyLightTypographyComponents,
  Subtitle,
} from '@heidi-pay/heidi-component-library/components/Typography';
import {
  Body,
  Notes,
  TypographyBaseProps,
} from '@heidi-pay/heidi-component-library/components/Typography/heyLightTypography';
import {
  BaseColour,
  HeyLightColour,
} from '@heidi-pay/heidi-component-library/enums';
import { Typography } from '@mui/material';
import { FormatAmount } from './TypographyRebranded';

const SubtitleInline = (props: TypographyBaseProps<'h2'>) => {
  return <Subtitle display="inline" {...props} />;
};

const SectionTitle = <Typography component="h2" mt="16px" variant="h5" />;

export const AccentSpan = (
  <Typography
    color={HeyLightColour.Accent}
    component="span"
    fontSize="inherit"
  />
);

export const typographyComponentsHeyLight = {
  ...heyLightTypographyComponents,
  accentSpan: AccentSpan,
  greyNotes: <Notes color={BaseColour.Grey1} />,
  greyBody: <Body color={BaseColour.Grey1} display={'inline'} />,
  greySmallText: (
    <Typography color={BaseColour.Grey1} sx={{ fontSize: '0.75rem' }} />
  ),
  subtitleInline: <SubtitleInline />,
  sectionTitle: SectionTitle,
  formatAmount: <FormatAmount />,
  rejectedIcon: <Declined />,
  successIcon: <ThumbsUp />,
};
