import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useTranslation } from '@hooks';

export const ConsentRadioGroup = ({
  acceptOnly,
  error,
  name,
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const handleChange = event => {
    const hasConsented = event.target.value === 'true';
    onChange(hasConsented);
  };

  return (
    <FormControl component="fieldset" error={!!error}>
      <RadioGroup
        aria-labelledby="ilCliente"
        id={name}
        name={name}
        onChange={handleChange}
        row={true}
        value={value}
      >
        <FormControlLabel
          control={<Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />}
          label={t(
            `fields.consentBlock.options.${acceptOnly ? 'accept' : 'yes'}`,
          )}
          value="true"
        />
        {!acceptOnly ? (
          <FormControlLabel
            control={<Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />}
            label={t('fields.consentBlock.options.no')}
            value="false"
          />
        ) : null}
      </RadioGroup>
    </FormControl>
  );
};

ConsentRadioGroup.propTypes = {
  error: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape(),
  value: PropTypes.string.isRequired,
};

ConsentRadioGroup.defaultProps = {
  error: {},
};
