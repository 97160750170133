import { useCallback, useState } from 'react';
import { Card, styled } from '@mui/material';
import { FeatureFlag, useFeatureFlags } from '@hooks';
import { HelpFloatingButtonMigrated } from '../heyLight/HelpFloatingButtonMigrated';
import HelpFloatingButton from './HelpFloatingButton';
import HelpForm from './HelpForm';

interface IContainer {
  contentVisible: boolean;
}

const Container = styled('div', {
  shouldForwardProp: prop => prop !== 'contentVisible',
})<IContainer>`
  font-size: 1rem;
  display: flex;
  position: fixed;
  right: ${(props: IContainer) => (props.contentVisible ? '10px' : '-285px')};
  bottom: 1rem;
  transition: all 0.2s ease-out;
  width: 275px;
  z-index: 1;
`;

const FormContainer = styled(Card)`
  position: relative;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.6rem 1rem 0.625rem 1rem;
  box-shadow: 0 0 20px 5px #707070;

  & > fieldset {
    border: 1px solid black;
  }
`;

const HelpWidget = () => {
  const { flagEnabled } = useFeatureFlags();
  const isHeyLightEnabled = flagEnabled(FeatureFlag.IsHeyLightBrandingEnabled);
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = useCallback(
    () => setIsToggled(previous => !previous),
    [],
  );

  return (
    <Container contentVisible={isToggled}>
      {isHeyLightEnabled ? (
        <HelpFloatingButtonMigrated
          isToggled={isToggled}
          onClick={handleToggle}
        />
      ) : (
        <HelpFloatingButton isToggled={isToggled} onClick={handleToggle} />
      )}
      <FormContainer>
        <HelpForm />
      </FormContainer>
    </Container>
  );
};

export default HelpWidget;
