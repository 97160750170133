import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { getActionNameFromPath } from '@utils';
import { IEmbeddedSvc, ISalesforceConfig } from '../types';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    embedded_svc?: IEmbeddedSvc;
  }
}

interface IInitSalesforceWidget {
  avatarUrl?: string;
  config: ISalesforceConfig;
  externalContractUuid: string;
  merchantId: string;
  merchantName: string;
  translate: Translate;
  translatedAction: string;
}

let hasInitialised = false;

export const initSalesforceWidget = ({
  avatarUrl,
  config: {
    baseCoreURL,
    baseLiveAgentContentUrl: baseLiveAgentContentURL,
    baseLiveAgentURL,
    baseUrl,
    buttonId,
    communityEndpointUrl: communityEndpointURL,
    deploymentId,
    eswConfigDevName,
    eswLiveAgentDevName,
    opsPortalUrl,
    orgId,
  },
  externalContractUuid,
  merchantId,
  merchantName,
  translate,
  translatedAction: translatedStep,
}: IInitSalesforceWidget) => {
  if (!window.embedded_svc) {
    return;
  }

  window.embedded_svc.settings.displayHelpButton = true;
  window.embedded_svc.settings.language = 'it';
  window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  window.embedded_svc.settings.entryFeature = 'LiveAgent';
  window.embedded_svc.settings.defaultMinimizedText = translate(
    'helpWidget.salesforce.chatWithAnAgent',
  );
  window.embedded_svc.settings.disabledMinimizedText = translate(
    'helpWidget.salesforce.agentOffline',
  );
  window.embedded_svc.settings.loadingText = translate(
    'helpWidget.salesforce.loading',
  );
  window.embedded_svc.settings.extraPrechatFormDetails = [
    {
      label: 'Session ID',
      value: externalContractUuid,
      transcriptFields: ['Session_ID__c'],
      displayToAgent: false,
    },
    {
      label: 'Merchant Name',
      value: merchantName,
      transcriptFields: ['Merchant_name__c'],
      displayToAgent: true,
    },
    {
      label: 'Merchant ID',
      value: merchantId,
      transcriptFields: ['Merchant_ID__c'],
      displayToAgent: false,
    },
    {
      label: 'Action name',
      value: translatedStep,
      transcriptFields: ['Action_name__c'],
      displayToAgent: true,
    },
    {
      label: 'Raw action name',
      value: getActionNameFromPath(),
      transcriptFields: ['Raw_action_name__c'],
      displayToAgent: false,
    },
    {
      label: 'Ops application url',
      value: `${opsPortalUrl}/${externalContractUuid}`,
      transcriptFields: ['Ops_application_url__c'],
      displayToAgent: false,
    },
  ];

  if (avatarUrl) {
    window.embedded_svc.settings.chatbotAvatarImgURL = avatarUrl;
  }

  if (window.embedded_svc && !hasInitialised) {
    hasInitialised = true;
    window.embedded_svc.init(
      baseCoreURL,
      communityEndpointURL,
      baseUrl,
      orgId,
      eswConfigDevName,
      {
        baseLiveAgentContentURL,
        deploymentId,
        buttonId,
        baseLiveAgentURL,
        eswLiveAgentDevName,
        isOfflineSupportEnabled: false,
      },
    );
  }
};
