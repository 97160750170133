import { useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Box, FormControl, FormHelperText, Grid } from '@mui/material';
import { useTranslation } from '@hooks';

import {
  getIsVerifying,
  resendVerificationCode,
} from '../../../../verification/redux';
import { VerificationCodeTypes } from '../../../../verification/services/VerificationService';
import { CodeInput } from '../MobileVerificationCodeInput/components/CodeInput';
import { ActionButtonsStacked } from './components';
import { ISignContract, IValidationContext } from './types';

const defaultNumberOfDigits = 5;
const validationContext: IValidationContext = {
  numberOfDigits: defaultNumberOfDigits,
};

export const SignContract = ({
  error = '',
  name,
  onChange,
  options = { digits: defaultNumberOfDigits, continueButtonOptions: {} },
  submit,
}: ISignContract) => {
  const {
    continueButtonOptions = {},
    digits,
    overrideKey = 'default',
  } = options;

  validationContext.numberOfDigits = digits;
  const dispatch = useDispatch();
  const isVerifying = useSelector(getIsVerifying);
  const { t } = useTranslation();

  const [code, setCode] = useState(Array(digits).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleCodeChange = useCallback(
    (index: number, value: string) => {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      onChange(newCode.join(''));

      if (value && index < digits - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    },
    [code, digits, onChange],
  );

  const handleCodeReSend = useCallback(
    () => dispatch(resendVerificationCode(VerificationCodeTypes.signContract)),
    [dispatch],
  );

  const handleSubmit = useCallback(() => submit(), [submit]);

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();
    },
    [],
  );

  const setInputRef = useCallback(
    (el: HTMLInputElement | null, index: number) => {
      inputRefs.current[index] = el;
    },
    [],
  );

  const handleKeyDown = useCallback(
    (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && !code[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    },
    [code],
  );

  return (
    <>
      <Grid container={true} display={'flex'} justifyContent={'center'}>
        <Box display="flex" gap={1.5}>
          {code.map((digit, index) => (
            <CodeInput
              digit={digit}
              error={!!error}
              index={index}
              isVerifying={isVerifying}
              key={index}
              name={name}
              onChange={handleCodeChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              setInputRef={setInputRef}
            />
          ))}
        </Box>
        <FormControl error={!!error}>
          {error ? (
            <FormHelperText sx={{ textAlign: 'left' }}>
              {t('fields.mobileVerificationCodeInput.invalid')}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <ActionButtonsStacked
        primaryButtonAction={handleSubmit}
        primaryButtonOptions={continueButtonOptions}
        secondaryButtonAction={handleCodeReSend}
        secondaryButtonLabel={t(`fields.signContract.${overrideKey}.button`)}
      />
    </>
  );
};

export const validateNumberOfDigits = async (values?: string) =>
  values?.length === validationContext.numberOfDigits;

SignContract.validation = () =>
  yup.string().test('signContract', 'ERROR', validateNumberOfDigits);
