import { IPaymentPlan } from '../../../payment/redux/types';
import { ILoadPaymentSummary } from '../../services/types';

export const getSummaryData = ({
  apr,
  holdPayment,
  holdPercentage,
  initialBalance,
  initialPayment,
  initialPaymentPercentage,
  interestAmount,
  nominalInterestRate,
  residualPercentage,
  schedule,
  total,
}: ILoadPaymentSummary): IPaymentPlan => {
  const scheduleArr = schedule.map(payment => ({
    amount: payment.amount,
    date: payment.dueDate,
  }));

  const finalPayment = parseFloat(scheduleArr[scheduleArr.length - 1].amount);
  const initialBalanceAmount = parseFloat(initialBalance.amount);
  const initialPaymentAmount = parseFloat(initialPayment);
  const creditAmount = parseFloat(total.amount) - initialPaymentAmount;

  const summaryDict = {
    summary: {
      currency: total.currency,
      creditAmount,
      payNow: {
        percentage: initialPaymentPercentage,
        amount: initialPayment,
      },
      payMonthly: scheduleArr[0].amount,
      total: total.amount,
      heldOnCard: {
        percentage: holdPercentage,
        amount: holdPayment,
      },
      residualPayment: {
        residualPaymentEnabled: Boolean(Number(residualPercentage)),
        finalPayment: finalPayment.toString(),
      },
      interest: {
        apr: apr ?? '0',
        interestAmount: interestAmount ?? '0',
        nominalInterestRate: nominalInterestRate ?? '0',
      },
      totalPricePlusInterest: initialBalanceAmount - initialPaymentAmount,
      initialBalance: initialBalanceAmount,
      initialPayment,
      refreshPrice: initialBalanceAmount - finalPayment - initialPaymentAmount,
    },
  };

  return { schedule: scheduleArr, ...summaryDict };
};
