import { useSelector } from 'react-redux';
import { List, ListItem, styled } from '@mui/material';
import { getCancelUrl } from '@redux';
import { CopyBlock } from '../../../components';
import { icons } from '../../../icons';
import { IDisplayList } from './types';

const StyledList = styled(List)({
  width: 'inherit',
});

const ImageContainer = styled('div')({
  height: '50px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const DisplayList = ({ options, value }: IDisplayList) => {
  const cancelUrl = useSelector(getCancelUrl);
  const { email, items, page } = options;
  const { amount, dueDate } = value ?? {};

  return (
    <StyledList>
      {items.map(({ icon, translationKey }, index) => {
        return (
          <ListItem key={translationKey} sx={{ marginTop: index > 0 ? 1 : 0 }}>
            {icon ? <ImageContainer>{icons[icon]}</ImageContainer> : null}
            <CopyBlock
              component="p"
              i18nKey={`displayList.${page}.${translationKey}`}
              key={translationKey}
              sx={{ marginX: '1rem' }}
              value={{ amount, dueDate, email, cancelUrl }}
            />
          </ListItem>
        );
      })}
    </StyledList>
  );
};
