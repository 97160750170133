import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  makeStyles,
} from '@material-ui/core';

import { Tick } from '../../components/Icons';
import {
  getCurrentStepNumber,
  getTotalNumberOfSteps,
} from '../../transaction/redux';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    '@media(max-width: 600px)': {
      display: 'none',
    },
  },
  stepper: {
    background: 'none',
    paddingRight: '.5rem',
    paddingLeft: '.5rem',
    paddingTop: '1.3rem',
    paddingBottom: 0,
    flexGrow: 1,
  },
  close: {
    width: '1.5rem',
    padding: 0,
  },
  stepperLabel: {
    '& > span': { display: 'contents' },
  },
});

function ProgressStepIcon(props) {
  const { completed } = props;
  if (completed) {
    return (
      <svg
        aria-hidden="true"
        className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
        focusable="false"
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="12" />
        <Tick
          className="MuiSvgIcon-fontSizeSmall"
          height="1.2rem"
          htmlColor="white"
          width="1.2rem"
          x="0.15rem"
          y="0.15rem"
        />
      </svg>
    );
  }

  return <StepIcon {...props} />; // eslint-disable-line react/jsx-props-no-spreading
}

ProgressStepIcon.propTypes = {
  completed: PropTypes.bool.isRequired,
};

export default function Progress() {
  const step = useSelector(getCurrentStepNumber);
  const numberOfSteps = useSelector(getTotalNumberOfSteps);
  const classes = useStyles();

  if (step >= numberOfSteps) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Stepper activeStep={step - 1} className={classes.stepper}>
        {Array(numberOfSteps - 1)
          .fill()
          .map((stepItem, index) => {
            const stepKey = `step-${index}`;
            return (
              <Step key={stepKey}>
                <StepLabel
                  className={classes.stepperLabel}
                  StepIconComponent={ProgressStepIcon}
                />
              </Step>
            );
          })}
      </Stepper>
    </div>
  );
}
