import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Container, ContainerProps, styled } from '@mui/material';
import { Children } from '@appTypes';
import { getFeatureFlags, getIsLoading, getIsUnhandledError } from '@redux';
import ErrorBackdrop from './components/ErrorBackdrop';
import { Footer } from './components/Footer';
import HelpWidget from './components/HelpWidget';
import { useShowHelpWidget } from './components/hooks';
import LoadingBackdrop from './components/LoadingBackdrop';
import MetricsLoader from './components/MetricsLoader';
import { SalesforceLiveChat } from './components/SalesforceLiveChat';
import { SentryErrorBoundary } from './components/SentryErrorBoundary';
import { MainContent } from './MainContent';

interface IStyledContainerProps extends ContainerProps {
  component: React.ElementType;
  isHeylightBrandingEnabled?: boolean;
}

const StyledContainer = styled(Container, {
  shouldForwardProp: prop => prop !== 'isHeylightBrandingEnabled',
})<IStyledContainerProps>(({ isHeylightBrandingEnabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  margin: '0 auto',
  padding: isHeylightBrandingEnabled ? '0' : '0 16px',
  // iPhone 5 fix:
  // https://gist.github.com/pburtchaell/e702f441ba9b3f76f587#file-styles-css-L40
  '@media screen and (device-aspect-ratio: 40/71)': {
    minHeight: '500px',
  },
  ...(isHeylightBrandingEnabled
    ? {
        '@media (min-width: 600px)': {
          paddingLeft: 0,
          paddingRight: 0,
        },
        paddingLeft: 0,
        paddingRight: 0,
      }
    : {}),
}));

interface IMain {
  children: Children;
}

const Main = ({ children }: IMain) => {
  const isLoading = useSelector(getIsLoading);
  const isUnhandledError = useSelector(getIsUnhandledError);
  const { isHeylightBrandingEnabled } = useSelector(getFeatureFlags);
  const { showHeyLightHelpWidget, showSalesforceHelpWidget } =
    useShowHelpWidget();

  return (
    <StyledContainer
      component="main"
      isHeylightBrandingEnabled={isHeylightBrandingEnabled}
      maxWidth="sm"
    >
      <SentryErrorBoundary>
        <Suspense fallback={<LoadingBackdrop />}>
          <MainContent>{children}</MainContent>

          <Footer />

          {showHeyLightHelpWidget ? <HelpWidget /> : null}
          {showSalesforceHelpWidget ? <SalesforceLiveChat /> : null}

          {isUnhandledError ? <ErrorBackdrop /> : null}
        </Suspense>

        {isLoading && !isUnhandledError ? <LoadingBackdrop /> : null}
        <MetricsLoader />
      </SentryErrorBoundary>
    </StyledContainer>
  );
};

export default Main;
