/* eslint-disable max-lines */
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { InputFieldAdornment } from '@heidi-pay/heidi-component-library/components/InputFieldAdornment';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { StackedLabel } from '../../StackedLabel';
import {
  MonetaryFormatInput,
  MonetaryFixedFormatInput,
  NumericFormatInput,
} from './components';

export const Type = Object.freeze({
  monetary: 'monetary',
  monetaryFixed: 'monetaryFixed',
  numeric: 'numeric',
});

const inputComponentMap = Object.freeze({
  [Type.monetary]: MonetaryFormatInput,
  [Type.monetaryFixed]: MonetaryFixedFormatInput,
  [Type.number]: NumericFormatInput,
});

export const TextInputNumeric = ({
  complete,
  controlName,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  value,
}) => {
  const {
    currency = undefined,
    disabled = false,
    hidden = false,
    labelOverride = null,
    tooltip,
    type = Type.numeric,
    withInputAdornment = false,
    withStackedLabel = false,
  } = options;

  const { t } = useTranslation();

  // These references are used to handle the label shrinking
  const inputElementRef = useRef(null);
  const parentElementRef = useRef(inputRef);

  const label = t(`fields.textInput.${labelOverride || name}.label`);
  const inputAdornmentText = withInputAdornment
    ? t(`fields.textInput.${labelOverride || name}.inputAdornment`)
    : '';

  const isMoneyField =
    value?.hasOwnProperty('amount') || value?.hasOwnProperty('currency');
  const parsedValue = isMoneyField ? value.amount : value;
  const parsedCurrency = isMoneyField ? value.currency : currency;
  const [shrinkLabel, setShrinkLabel] = useState(
    parsedValue !== '' || withInputAdornment,
  );

  useEffect(() => {
    setShrinkLabel(parsedValue !== '' || withInputAdornment);
  }, [parsedValue, withInputAdornment]);

  if (hidden) {
    return null;
  }

  const handleOnChange = newValue => {
    const val = isMoneyField
      ? { amount: newValue, currency: parsedCurrency }
      : newValue;
    onChange(val);
    if (controlName && setValue) {
      setValue(controlName ?? name, val);
    }
  };

  const handleFocus = () => {
    setShrinkLabel(true);
  };

  const handleBlur = event => {
    if (!parsedValue && !withInputAdornment) {
      setShrinkLabel(false);
    }
    onBlur(event);
  };

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '10px' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel
          className="MuiFormLabel-external"
          isHeyLight={true}
          label={label}
          name={name}
          tooltip={tooltip}
        />
      ) : null}
      <TextField
        disabled={disabled}
        error={!!error}
        id={name}
        InputLabelProps={{ shrink: shrinkLabel }}
        InputProps={{
          startAdornment: withInputAdornment ? (
            <InputAdornment position="start" sx={{ marginTop: '15px' }}>
              <Typography color="black" variant="body2">
                {inputAdornmentText}
              </Typography>
            </InputAdornment>
          ) : null,
          endAdornment: (
            <InputAdornment position="end">
              {!withStackedLabel ? (
                <InputAdornment position="start">
                  {parsedCurrency}
                </InputAdornment>
              ) : null}
              <InputFieldAdornment
                isComplete={complete}
                isDisabled={disabled}
                isError={!!error}
              />
            </InputAdornment>
          ),
          inputComponent: inputComponentMap[type] ?? NumericFormatInput,
        }}
        inputProps={{ inputMode: 'decimal' }}
        inputRef={element => {
          inputElementRef.current = element;
          if (parentElementRef) {
            parentElementRef.current = element;
          }
        }}
        label={withStackedLabel ? parsedCurrency : label}
        name={name}
        onBlur={handleBlur}
        onChange={handleOnChange}
        onFocus={handleFocus}
        size="small"
        sx={{ marginTop: '10px' }}
        type="text"
        value={parsedValue}
        variant="outlined"
      />
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

TextInputNumeric.validation = (
  name,
  {
    isMonetaryObject = false,
    labelOverride = null,
    optional = false,
    regex = null,
  },
) => {
  let schema;

  if (isMonetaryObject) {
    schema = yup
      .object({
        amount: yup.string().required(),
        currency: yup.string().required(),
      })
      .transform(form => (!form ? { amount: '', currency: '' } : form));
  } else {
    schema = yup.string();
    if (regex) {
      schema = schema.matches(
        new RegExp(regex),
        `fields.textInput.${labelOverride || name}.invalid`,
      );
    }
  }

  if (optional) {
    return schema.nullable();
  }

  return schema.test(
    name,
    `fields.textInput.${labelOverride || name}.required`,
    value => {
      if (isMonetaryObject) {
        return Boolean(value?.amount) && Boolean(value?.currency);
      }
      return Boolean(value);
    },
  );
};

TextInputNumeric.propTypes = {
  controlName: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.shape({
    currency: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    labelOverride: PropTypes.string,
    optional: PropTypes.bool,
    tooltip: PropTypes.string,
    type: PropTypes.oneOf([Type.monetary, Type.monetaryFixed, Type.numeric]),
    withStackedLabel: PropTypes.bool,
    withInputAdornment: PropTypes.bool,
  }),
  setValue: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      amount: PropTypes.string,
      currency: PropTypes.string,
    }),
  ]).isRequired,
};

TextInputNumeric.defaultProps = {
  error: '',
  options: {
    currency: undefined,
    disabled: false,
    labelOverride: undefined,
    optional: false,
    type: Type.numeric,
    tooltip: undefined,
    withStackedLabel: false,
    withInputAdornment: false,
  },
};
