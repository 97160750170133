import React, { useCallback } from 'react';
import { Locale } from 'date-fns';
import de from 'date-fns/locale/de';
import enGBLocale from 'date-fns/locale/en-GB';
import frCHLocale from 'date-fns/locale/fr-CH';
import it from 'date-fns/locale/it';
import { DatePicker } from '@heidi-pay/heidi-component-library/components';
import { useTranslation } from '@hooks';
import { dateFormatting, dateValidationSchema } from './validation';

const localeMap: Record<string, Locale> = {
  'en-gb': enGBLocale,
  'fr-ch': frCHLocale,
  'it-ch': it,
  'de-ch': de,
};

interface IDateOfBirthInput {
  error?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  name: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (date: string | Date | null) => void;
  options?: {
    allowOnlyFutureDate?: boolean;
    labelOverride?: string;
    optional?: boolean;
  };
  value?: string | Date;
}

export const DateOfBirthInput = ({
  error = '',
  inputRef = undefined,
  name,
  onBlur,
  onChange,
  options = { allowOnlyFutureDate: false },
  value,
}: IDateOfBirthInput) => {
  const { i18n, t } = useTranslation();
  const { allowOnlyFutureDate = false, labelOverride } = options || {};
  const formattedOptionalLabelOverride = labelOverride
    ? `${labelOverride}.`
    : '';
  const locale = i18n.language;
  const adapterLocale = localeMap[locale.toLowerCase()];

  const handleChange = useCallback(
    (date: string | number | Date | null) => {
      onChange(date as Date);
    },
    [onChange],
  );

  return (
    <DatePicker
      adapterLocale={adapterLocale}
      cancelButtonLabel={t(
        `fields.dateOfBirthInput.${formattedOptionalLabelOverride}cancel`,
      )}
      disableFuture={!allowOnlyFutureDate}
      error={error ? t(error) : undefined}
      format="dd/MM/yyyy"
      inputRef={inputRef}
      label={t(
        `fields.dateOfBirthInput.${formattedOptionalLabelOverride}${name}.label`,
      )}
      name={name}
      okButtonLabel={t(
        `fields.dateOfBirthInput.${formattedOptionalLabelOverride}confirm`,
      )}
      onBlur={onBlur}
      onChange={handleChange}
      sx={{ marginTop: '7px', width: '100%' }}
      value={value ? new Date(value) : null}
    />
  );
};

DateOfBirthInput.validation = dateValidationSchema;
DateOfBirthInput.formatting = dateFormatting;
