import { Divider as MUIDivider, useTheme } from '@mui/material';

export const Divider = ({ options }) => {
  const theme = useTheme();
  const dividerColor = options?.themed ? theme.palette.primary.main : undefined;
  return (
    <MUIDivider
      sx={{
        my: options?.spaced ? 2 : 0,
        width: '100%',
        borderColor: dividerColor,
      }}
    />
  );
};
