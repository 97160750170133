import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ComponentTypes } from '../../../../utils';
import { ButtonGroupPicker } from '../../ButtonGroupPicker';
import { NumberPicker } from '../../NumberPicker';
import { SelectPicker } from '../../SelectPicker';
import TextBlock from '../../TextBlock';
import { TextInputNumeric } from '../../TextInputNumeric';
import { notApplicableFieldValue } from '../utils/constants';

export const ControlledField = ({
  control,
  errors,
  id,
  name,
  options,
  type,
}) => {
  const {
    field: { onBlur, onChange, ref, value },
  } = useController({
    name,
    control,
  });

  // Hide the field when it's not applicable. This is controlled by the container
  if (value === notApplicableFieldValue) {
    return null;
  }

  if (type === ComponentTypes.TextBlock) {
    return (
      <TextBlock
        error={errors[name]?.message}
        name={name}
        onChange={onChange}
        options={options}
        value={value}
      />
    );
  }

  if (type === ComponentTypes.ButtonGroupPicker) {
    return (
      <ButtonGroupPicker
        complete={!!value}
        error={errors[name]?.message}
        name={name}
        onChange={onChange}
        options={options}
        value={value}
      />
    );
  }

  if (type === ComponentTypes.TextInputNumeric) {
    return (
      <TextInputNumeric
        complete={!!value}
        error={errors[name]?.message}
        id={id}
        inputRef={ref}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        value={value}
      />
    );
  }

  if (type === ComponentTypes.NumberPicker) {
    return (
      <NumberPicker
        complete={!!value}
        error={errors[name]?.message}
        id={id}
        inputRef={ref}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        value={value}
      />
    );
  }

  return (
    <SelectPicker
      complete={!!value}
      error={errors[name]?.message}
      id={id}
      inputRef={ref}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};

ControlledField.propTypes = {
  control: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape(),
  ]).isRequired,
  type: PropTypes.string.isRequired,
  errors: PropTypes.shape(),
};

ControlledField.defaultProps = {
  errors: {},
};
