import { useCallback, memo, useMemo } from 'react';
import * as yup from 'yup';
import { Delete } from '@material-ui/icons';
import { Button, Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import {
  TextInput,
  TextInputNumeric,
} from '../../../../transaction/components/heylight';
import { InstoreProductSearchTextInput } from '../InstoreProductSearchTextInput';
import { ControlledField } from './components/ControlledField';
import { FieldNames } from './enums';
import { updateProductValue } from './utils/helpers';

export const InstoreProductForm = memo(
  ({
    control,
    enableRemove,
    errors,
    index,
    isIdentityFieldEnabled,
    isNameFieldEnabled = true,
    remove,
    setValue,
  }) => {
    const { t } = useTranslation();

    const updateProduct = useMemo(
      () => updateProductValue(index, setValue),
      [index, setValue],
    );

    // update the form with product details (sku, price, name etc)
    const handleSearchResult = useCallback(
      productDetails => {
        updateProduct(FieldNames.Price, productDetails?.expectedPrice);
        if (isNameFieldEnabled) {
          updateProduct(FieldNames.ProductName, productDetails?.name);
        }
        if (isIdentityFieldEnabled) {
          updateProduct(FieldNames.ProductIdentity, productDetails?.identity);
        }
        updateProduct(
          FieldNames.AdditionalDetails,
          productDetails?.additionalDetails,
          false,
        );
      },
      [isIdentityFieldEnabled, isNameFieldEnabled, updateProduct],
    );

    const handleRemove = () => remove(index);

    return (
      <>
        <ControlledField
          Component={InstoreProductSearchTextInput}
          control={control}
          controlName={`products.${index}.${FieldNames.ProductSku}`}
          error={errors?.products?.[index]?.[FieldNames.ProductSku]?.message}
          id={FieldNames.ProductSku}
          name={FieldNames.ProductSku}
          onSearchResult={handleSearchResult}
          setValue={setValue}
        />
        {isNameFieldEnabled ? (
          <ControlledField
            Component={TextInput}
            control={control}
            controlName={`products.${index}.${FieldNames.ProductName}`}
            error={errors?.products?.[index]?.[FieldNames.ProductName]?.message}
            id={FieldNames.ProductName}
            name={FieldNames.ProductName}
            options={{ disabled: true }}
            setValue={setValue}
          />
        ) : null}
        {isIdentityFieldEnabled ? (
          <ControlledField
            Component={TextInput}
            control={control}
            controlName={`products.${index}.${FieldNames.ProductIdentity}`}
            error={
              errors?.products?.[index]?.[FieldNames.ProductIdentity]?.message
            }
            id={FieldNames.ProductIdentity}
            name={FieldNames.ProductIdentity}
            options={{ disabled: true }}
            setValue={setValue}
          />
        ) : null}
        <ControlledField
          Component={TextInputNumeric}
          control={control}
          controlName={`products.${index}.${FieldNames.Price}`}
          error={errors?.products?.[index]?.[FieldNames.Price]?.message}
          id={FieldNames.Price}
          name={FieldNames.Price}
          options={{ disabled: true, type: 'monetary' }}
          setValue={setValue}
        />
        <Grid container={true}>
          <Grid item={true} xs={6}>
            <ControlledField
              Component={TextInputNumeric}
              control={control}
              controlName={`products.${index}.${FieldNames.Discount}`}
              error={errors?.products?.[index]?.[FieldNames.Discount]?.message}
              id={FieldNames.Discount}
              name={FieldNames.Discount}
              options={{ optional: true }}
              setValue={setValue}
            />
          </Grid>
          {enableRemove ? (
            <Grid item={true} pr="40px" pt={1} textAlign="end" xs={6}>
              <Button
                onClick={handleRemove}
                size="large"
                startIcon={<Delete />}
                sx={{ py: 0, textTransform: 'none' }}
                variant="text"
              >
                {t('fields.instoreProductForm.remove')}
              </Button>
            </Grid>
          ) : null}
          <ControlledField
            Component={TextInput}
            control={control}
            controlName={`products.${index}.${FieldNames.AdditionalDetails}`}
            id={FieldNames.AdditionalDetails}
            name={FieldNames.AdditionalDetails}
            options={{ hidden: true }}
            setValue={setValue}
          />
        </Grid>
      </>
    );
  },
);

export const validation = () =>
  yup
    .object()
    .transform(form => {
      if (form.submitAttempted) {
        form.trigger();
      }
      const formValues = form.getValues();
      return { ...form, ...formValues };
    })
    .test('instoreProductForm', 'ERROR', form => {
      return !form?.hasErrors();
    })
    .nullable();

InstoreProductForm.displayName = 'InstoreProductForm';
InstoreProductForm.validation = validation;
