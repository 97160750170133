import { useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { FormControl, TextField, FormHelperText } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useTranslation } from '@hooks';
import { SentrySeverity } from '@utils';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { StackedLabel } from '../StackedLabel';
import { DocumentValidationCountry } from './enums';
import { DocumentType } from './types';
import { documentTypeValidationMap } from './validators';

interface ITextInputDocumentNumberOptions {
  disabled?: boolean;
  documentNumberFieldName?: string;
  documentValidationCountry: string;
  hidden?: boolean;
  labelOverride?: string;
  tooltip?: string;
  type?: string;
  withStackedLabel?: boolean;
}

interface ITextInputDocumentNumber {
  complete: boolean;
  controlName?: string;
  error: string;
  inputRef?: React.Ref<HTMLInputElement>;
  name: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: ITextInputDocumentNumberOptions;
  setValue: (name: string, value: string) => void;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
  value: string;
  watch: (name: string) => unknown;
}

export const TextInputDocumentNumber = ({
  complete,
  controlName,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  trigger,
  value,
  watch,
}: ITextInputDocumentNumber) => {
  const { t } = useTranslation();

  const {
    disabled = false,
    documentNumberFieldName = 'documentType',
    hidden = false,
    labelOverride = undefined,
    tooltip,
    type = '',
    withStackedLabel = false,
  } = options;

  const documentType = watch(documentNumberFieldName);
  const label = t(
    `fields.textInputDocumentNumber.${labelOverride || name}.label`,
  );

  const maskDocumentNumber = (documentNumber: string) => {
    return documentNumber.replace(/\d/g, '1').replace(/[a-zA-Z]/g, 'A');
  };

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(controlName ?? name, e.target.value);
      onChange(e);
    },
    [controlName, name, onChange, setValue],
  );

  // TODO: Remove once we are happy with the validation rules and don't need to send
  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onBlur(e);
      const validationResult = trigger(name);
      const maskedValue = maskDocumentNumber(value);

      validationResult.then(isValid => {
        if (!isValid) {
          Sentry.captureMessage(
            `Document number validation failed for ${documentType}: ${maskedValue}`,
            SentrySeverity.warning,
          );
        }
      });
    },
    [documentType, name, onBlur, trigger, value],
  );

  useEffect(() => {
    trigger(name);
  }, [documentType, name, trigger]);

  if (hidden) {
    return null;
  }

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel
          className="MuiFormLabel-external"
          label={label}
          name={name}
          tooltip={tooltip}
        />
      ) : null}
      <CompleteWrapper complete={complete} error={error}>
        <TextField
          disabled={disabled}
          error={!!error}
          hidden={true}
          id={name}
          InputLabelProps={{ shrink: value !== '' }}
          inputRef={inputRef}
          label={withStackedLabel ? undefined : label}
          name={name}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          size="small"
          type={type}
          value={value}
          variant="outlined"
        />
      </CompleteWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

TextInputDocumentNumber.validation = (
  name: string,
  {
    documentNumberFieldName = 'documentType',
    documentValidationCountry,
  }: ITextInputDocumentNumberOptions,
) => {
  const schema =
    documentValidationCountry === DocumentValidationCountry.ITALY
      ? yup
          .string()
          .when(
            documentNumberFieldName,
            (documentType: DocumentType, currentSchema: yup.StringSchema) => {
              const validationFunction =
                documentTypeValidationMap[documentType];
              return validationFunction
                ? validationFunction(currentSchema)
                : currentSchema;
            },
          )
      : yup.string();

  return schema.required(`fields.textInputDocumentNumber.${name}.required`);
};
