import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { FeatureFlag, useFeatureFlags } from '@hooks';
import {
  getBrand,
  getFeatureFlags,
  getLocales,
  getOriginationChannel,
} from '@redux';
import { Brands, getActionSettings, OriginationChannels } from '@utils';
import { MerchantRedirectLink } from '../../../components/MerchantRedirectLink';
import LocalePicker from '../LocalePicker';
import getLogoByKey from '../logos';

export const Footer = () => {
  const { logo } = useSelector(getBrand);
  const locales = useSelector(getLocales);
  const { isShowCardChangeUpdatesEnabled } = useSelector(getFeatureFlags);
  const originationChannel = useSelector(getOriginationChannel);
  const { flagEnabled } = useFeatureFlags();

  const isHeylightBrandingEnabled = flagEnabled(
    FeatureFlag.IsHeyLightBrandingEnabled,
  );
  const { isAbandonDisabled } = getActionSettings({
    isHeylightBrandingEnabled,
  });

  const showLogoFooter = logo === Brands.heidipay;
  const LogoComponent = getLogoByKey(logo);

  const shouldShowLogoComponent =
    !isHeylightBrandingEnabled &&
    (showLogoFooter || (!isShowCardChangeUpdatesEnabled && logo));

  const shouldShowMerchantRedirectLink =
    originationChannel === OriginationChannels.ecommerce && !isAbandonDisabled;
  const shouldShowLocalePicker = locales && locales.length >= 2;

  if (
    !shouldShowLocalePicker &&
    !shouldShowMerchantRedirectLink &&
    !shouldShowLogoComponent
  ) {
    return null;
  }

  return (
    <Box
      alignItems="center"
      component="footer"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m={2}
    >
      <Box
        component="span"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        m={2}
      >
        {shouldShowMerchantRedirectLink ? (
          <MerchantRedirectLink
            isHeylightBrandingEnabled={isHeylightBrandingEnabled}
          />
        ) : null}
        {shouldShowLogoComponent ? <LogoComponent /> : null}
      </Box>
      {shouldShowLocalePicker ? <LocalePicker /> : null}
    </Box>
  );
};
