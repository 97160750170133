import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../components/CopyBlock';
import {
  setMonthlyPayments,
  updatePaymentPlan,
  getMonthlyPayments,
} from '../../payment/redux';
import { useFormatters } from '../../utils';

const ProductVariant = {
  SWISS_CREDIT_PRODUCT: 'swiss-credit-product',
};

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    marginTop: '20px',
    '& .Mui-selected:not(:hover)': {
      background: `${String(theme.palette.primary.main)}33`,
      color: 'black',
    },
  },
  toggleButton: {
    color: 'black',
    fontSize: '16px',
    fontWeight: '700',
  },
  label: {
    fontWeight: 700,
    fontSize: '18px',
    paddingTop: '10px',
  },
}));

export default function MonthlyPaymentsToggle({
  error,
  onChange,
  options,
  value,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    overrideKey,
    summary: { currency, paymentPlans },
  } = options;

  const { formatAmountWithCurrency } = useFormatters();
  const numberOfMonthlyPayments = useSelector(getMonthlyPayments);

  const getPaymentPlanForMonthlyPayments = useCallback(
    monthlyPaymentsValue => paymentPlans[monthlyPaymentsValue],
    [paymentPlans],
  );

  const setPaymentPlanForMonthlyPayments = useCallback(
    monthlyPaymentsValue => {
      const paymentPlan =
        getPaymentPlanForMonthlyPayments(monthlyPaymentsValue);
      dispatch(updatePaymentPlan(paymentPlan));
    },
    [dispatch, getPaymentPlanForMonthlyPayments],
  );

  useEffect(() => {
    if (value) {
      dispatch(setMonthlyPayments(value));
    }
  }, [dispatch, value]);

  useEffect(() => {
    if (numberOfMonthlyPayments > 0) {
      setPaymentPlanForMonthlyPayments(numberOfMonthlyPayments);
    }
  }, [numberOfMonthlyPayments, setPaymentPlanForMonthlyPayments]);

  const handleChange = (e, updatedValue) => {
    if (updatedValue !== null) {
      dispatch(setMonthlyPayments(updatedValue));
      onChange(updatedValue);
    }
  };

  // For non-equal amount instalments, there will be no amount to display
  const getPaymentPlanLabel = useCallback(
    ({ amount, displayValue, value: term }) => {
      if (overrideKey) {
        return amount
          ? `${formatAmountWithCurrency(amount, currency)} x ${
              displayValue || term
            }`
          : term;
      }
      return amount
        ? `${displayValue || term}× ${formatAmountWithCurrency(
            amount,
            currency,
          )}`
        : term;
    },
    [currency, formatAmountWithCurrency, overrideKey],
  );

  const paymentPlanTermOptions = useMemo(() => {
    // Currency is extracted from paymentPlan in the redux state
    // and can be null when the component loads
    if (!currency) {
      return [];
    }

    return options.options.map(option => ({
      label: getPaymentPlanLabel(option),
      value: option.value,
    }));
  }, [getPaymentPlanLabel, options.options, currency]);

  if (!currency) {
    return null;
  }

  const hideHeaderList = [ProductVariant.SWISS_CREDIT_PRODUCT];
  const hideHeader = hideHeaderList.includes(options?.variant);

  return (
    <FormControl component="fieldset" fullWidth={true} size="small">
      {!hideHeader ? (
        <FormLabel className={classes.label} component="legend">
          <CopyBlock
            i18nKey={
              overrideKey
                ? `fields.monthlyPaymentsToggle.label.${options.overrideKey}.${
                    options.mode ?? 'default'
                  }`
                : `fields.monthlyPaymentsToggle.label.${options.mode}`
            }
          />
        </FormLabel>
      ) : null}
      <ToggleButtonGroup
        className={classes.buttonGroup}
        exclusive={true}
        onChange={handleChange}
        orientation="vertical"
        value={value}
      >
        {paymentPlanTermOptions.map(option => (
          <ToggleButton
            aria-label={option.label}
            className={classes.toggleButton}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <FormHelperText error={!!error}>{t(error)}</FormHelperText>
    </FormControl>
  );
}

MonthlyPaymentsToggle.validation = () =>
  yup.string().required('fields.monthlyPaymentsToggle.required');

MonthlyPaymentsToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    summary: PropTypes.shape({
      currency: PropTypes.string,
      paymentPlans: PropTypes.shape(),
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.string,
        value: PropTypes.number.isRequired,
        label: PropTypes.string,
      }),
    ),
    mode: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MonthlyPaymentsToggle.defaultProps = {
  error: null,
  value: '',
};
