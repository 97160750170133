import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  IPaymentRowProps,
  RowType,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IUseFormatter } from '@utils/formatters/types';
import { IScheduleData } from './types';

export const getSwissMobilezonePaymentPlanFields = (
  {
    currency,
    numberOfInstallments: term,
    payMonthly,
    payNow,
    residualPayment,
    total: totalAmount,
  }: IScheduleData,
  formatters: IUseFormatter,
  translate: Translate,
): IPaymentRowProps[] | null => {
  if (!currency || !residualPayment) {
    return null;
  }

  const { finalPayment } = residualPayment;
  const amountWithoutResidualPayment =
    Number(totalAmount) - Number(finalPayment);

  return [
    {
      label: translate('fields.paymentPlanMobilezone.payNow'),
      labelVariant: 'h6',
      value: formatters.formatAmountWithCurrency(payNow.amount, currency),
      valueVariant: 'h6',
    },
    {
      label: translate('fields.paymentPlanMobilezone.payMonthly', {
        creditDurationWithoutResidualInstallment: term - 1,
      }),
      labelVariant: 'h6',
      value: formatters.formatAmountWithCurrency(payMonthly, currency),
      valueVariant: 'h6',
    },
    {
      label: translate('fields.paymentPlanMobilezone.interest'),
      labelVariant: 'h6',
      value: '0 %',
      valueVariant: 'h6',
    },
    {
      label: 'divider-1',
      rowType: RowType.Divider,
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(
        'fields.paymentPlanMobilezone.totalAmountWithoutResidualPayment',
      ),
      labelVariant: 'h6',
      tooltipProps: {
        content: translate(
          'fields.mobilezone.tooltip.selectScheduleMobilezone.totalAmountWithoutResidualPayment',
          {
            creditDurationWithResidualInstallment: term,
          },
        ),
        fontSize: '17px',
      },
      value: formatters.formatAmountWithCurrency(
        amountWithoutResidualPayment,
        currency,
      ),
      valueVariant: 'h6',
    },
    {
      label: 'divider-2',
      rowType: RowType.Divider,
    },
    {
      label: translate('fields.paymentPlanMobilezone.residualAmount', {
        creditDurationWithResidualInstallment: term,
      }),
      labelVariant: 'h6',
      tooltipProps: {
        content: translate(
          'fields.mobilezone.tooltip.selectScheduleMobilezone.residualAmount',
          {
            creditDurationWithResidualInstallment: term,
          },
        ),
        fontSize: '17px',
      },
      value: formatters.formatAmountWithCurrency(finalPayment, currency),
      valueVariant: 'h6',
    },
    {
      label: 'divider-3',
      rowType: RowType.Divider,
    },
    {
      label: translate('fields.paymentPlanMobilezone.totalAmount'),
      labelVariant: 'h6',
      tooltipProps: {
        content: translate(
          'fields.mobilezone.tooltip.selectScheduleMobilezone.totalAmount',
          {
            creditDurationWithResidualInstallment: term,
          },
        ),
        fontSize: '17px',
      },
      value: formatters.formatAmountWithCurrency(totalAmount, currency),
      valueVariant: 'h6',
    },
    {
      label: 'divider-4',
      rowType: RowType.Divider,
    },
    {
      label: translate('fields.paymentPlan.schedule'),
      labelVariant: 'h6',
      rowType: RowType.ScheduleToggle,
    },
  ];
};
