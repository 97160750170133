import { useSelector } from 'react-redux';
import { Box, FormControl } from '@mui/material';
import { useStatusPolling } from '@hooks';
import { getIsLoading } from '@redux';
import { CopyBlock } from '../../../components';
import { checkOnfidoStatus } from '../../../services/OnfidoStatusService';
import { ColorCodes } from '../../../theme';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { IOnfidoHoldingPageProps } from './types';

export const BaseTranslationKey =
  'fields.onfidoHoldingPage.pagolightTextRebrand';

export const OnfidoHoldingPageV2 = ({
  submit,
  submitAttempted,
  value,
}: IOnfidoHoldingPageProps) => {
  const isSubmitting = useSelector(getIsLoading);

  useStatusPolling({
    isSubmitting,
    onComplete: submit,
    pollingFunction: checkOnfidoStatus,
    retryIntervalMs: 6000,
    submitAttempted,
  });

  return (
    <FormControl
      className="notranslate"
      component="fieldset"
      fullWidth={true}
      size="small"
    >
      <Box alignItems="center" display="flex" flexDirection="column">
        <CopyBlock i18nKey={`${BaseTranslationKey}.title`} value={value} />
        <CopyBlock i18nKey={`${BaseTranslationKey}.warning`} />
      </Box>
      <ProgressBar
        backgroundColor={ColorCodes.PagolightPrimaryDark}
        height="10px"
        isRounded={true}
        variant="indeterminate"
      />
    </FormControl>
  );
};
