import { handleActions } from 'redux-actions';

import * as actions from './actions';
import { initialState } from './initialState';

export default handleActions(
  {
    [actions.setIsLoading]: (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }),

    [actions.setSoftErrorCode]: (state, { payload: { softErrorCode } }) => ({
      ...state,
      softErrorCode,
    }),

    [actions.setIsUnhandledError]: {
      next: (state, { payload: { isUnhandledError } }) => ({
        ...state,
        isUnhandledError,
      }),
      throw: state => ({
        ...state,
        isUnhandledError: true,
      }),
    },

    [actions.updateLocales]: (state, { payload: { locales } }) => ({
      ...state,
      locales,
    }),

    [actions.updateLocale]: (state, { payload: { locale } }) => ({
      ...state,
      locale,
    }),

    [actions.updateRedirectUrls]: (state, { payload: { redirectUrls } }) => ({
      ...state,
      redirectUrls,
    }),

    [actions.setFallbackUrl]: (state, { payload: { fallbackUrl } }) => ({
      ...state,
      fallbackUrl,
    }),

    [actions.setMetricsData]: (state, { payload: { metricsData } }) => ({
      ...state,
      metricsData,
    }),

    [actions.setOriginationChannel]: (
      state,
      { payload: { originationChannel } },
    ) => ({
      ...state,
      originationChannel,
    }),

    [actions.setOriginatorUuid]: (state, { payload: { originatorUuid } }) => ({
      ...state,
      originatorUuid,
    }),

    [actions.setMerchantDetails]: (
      state,
      { payload: { logo, name, primaryColour, translationOverrides, uuid } },
    ) => ({
      ...state,
      merchantDetails: {
        name,
        logo,
        primaryColour,
        translationOverrides,
        uuid,
      },
    }),

    [actions.setBrand]: (
      state,
      {
        payload: {
          brand: { logo },
        },
      },
    ) => ({
      ...state,
      brand: {
        logo: logo || 'heidipay',
      },
    }),

    [actions.setAbandonVisible]: (state, { payload: { abandonVisible } }) => ({
      ...state,
      abandonVisible,
    }),

    [actions.setExternalContractUuid]: (
      state,
      { payload: { externalContractUuid } },
    ) => ({
      ...state,
      externalContractUuid,
    }),

    [actions.setSessionDetails]: (
      state,
      { payload: { checkoutType, sessionUuid } },
    ) => ({
      ...state,
      sessionDetails: {
        checkoutType,
        sessionUuid,
      },
    }),

    [actions.setFeatureFlags]: (state, { payload: { featureFlags } }) => ({
      ...state,
      featureFlags,
    }),

    [actions.addNetworkDisconnectLogItem]: (
      state,
      { payload: { networkDisconnectLogItem } },
    ) => ({
      ...state,
      networkDisconnectLog: [
        ...state.networkDisconnectLog,
        networkDisconnectLogItem,
      ],
    }),

    [actions.setThemeType]: (state, { payload: { themeType } }) => ({
      ...state,
      themeType,
    }),
  },
  initialState,
);
