/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  styled,
} from '@mui/material';
import { Nullable } from '@appTypes';
import { useTranslation } from '@hooks';
import { registerSubFormValidation } from '@utils';
import { AddressType } from '../../../enums';
import LoadingBackdrop from '../../../main/components/LoadingBackdrop';
import { AddressField } from '../AddressContainerItalianPost/utils/constants';
import { mapFormValuesToAddressFields } from '../AddressContainerItalianPost/utils/mapFormValuesToAddressFields';
import { SelectPickerSearchable } from '../SelectPicker';
import { IBaseInputComponent, SubFormWithType } from '../types';
import {
  addressValidationFields,
  clearReason,
  defaultFormFields,
  fieldConfig,
} from './constants';
import { useAutoComplete } from './hooks';
import {
  AddressFieldValue,
  AddressFieldValueArray,
  Choices,
  IAddressContainerSwissPostOptions,
  IAddressField,
  IAddressFieldConfig,
  IValidationField,
} from './types';
import { getParsedFieldValue } from './utils/getParsedFieldValue';

interface IFormLabelProps {
  isBold?: boolean;
}

const StyledFormLabel = styled(FormLabel)<IFormLabelProps>(
  ({ isBold, theme }) => ({
    ...theme?.typography?.subtitle1,
    ...(isBold && { fontWeight: 'bold' }),
  }),
);

const StyledFormControlLabel = styled(FormControlLabel)({
  margin: 0,
  width: '100%',

  '.MuiFormControlLabel-label': {
    width: '100%',
  },
});

const getConfigItemByName = (fieldName: AddressFieldValue) =>
  fieldConfig.find(item => item.fieldName === fieldName) as IAddressFieldConfig;

const buildSchema = (
  fields: IValidationField[],
  erroringField?: Nullable<AddressFieldValue>,
) =>
  Object.fromEntries(
    fields.map(field => [
      field.name,
      yup
        .string()
        .required(`fields.addressContainer.subFields.${field.name}.required`)
        .test(
          'choice-validation',
          `fields.addressContainerSwissPost.subFields.${field.name}.invalid`,
          () => erroringField !== field.name,
        ),
    ]),
  );

export const AddressContainerSwissPost = ({
  name,
  onChange,
  options = {
    hiddenFields: [],
    isExpandable: false,
  },
  submitAttempted,
  value,
}: IBaseInputComponent<
  IAddressContainerSwissPostOptions,
  SubFormWithType<IAddressField>
>) => {
  const { t } = useTranslation();
  const { hiddenFields, isExpandable } = options;
  const [isExpanded, setIsExpanded] = useState(Boolean(value?.isExpanded));
  const [erroringField, setErroringField] =
    useState<Nullable<AddressFieldValue>>(null);
  const [activeField, setActiveField] =
    useState<Nullable<AddressFieldValue>>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { choices, fieldLoading, refreshChoices, resetChoices } =
    useAutoComplete();

  useEffect(() => {
    if (isLoading) {
      refreshChoices({
        requestData: value as IAddressField,
        debounced: false,
        setIsLoading,
      });
    }
  }, [isLoading, refreshChoices, value]);

  const schema = yup
    .object()
    .shape(buildSchema(addressValidationFields, erroringField));

  const { control, errors, getValues, setValue, trigger } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: { ...defaultFormFields, ...value },
  });

  const formValues = getValues();

  const isDisabled = (fieldName: AddressFieldValue) => {
    if (fieldName === AddressField.zipCode) {
      return false;
    }

    const config = getConfigItemByName(fieldName);
    const dependentFieldNotErroring =
      config.enabledDependsOn && config.enabledDependsOn !== erroringField;
    const dependentFieldHasValue =
      config.enabledDependsOn && formValues[config.enabledDependsOn];

    return !(dependentFieldNotErroring && dependentFieldHasValue);
  };

  const getErrorMessage = (fieldName: AddressFieldValue, formValue: string) => {
    const errorMessage = `fields.addressContainerSwissPost.subFields.${fieldName}.invalid`;

    const choiceInvalidErrorMsg =
      erroringField === fieldName ? errorMessage : null;
    const fieldErrorMsg = submitAttempted ? errors[fieldName]?.message : null;
    const availableChoices = choices[fieldName];

    const isValueInAvailableChoices = Boolean(
      availableChoices.find(choice => choice === formValue),
    );

    const valueNotInChoicesErrorMsg =
      fieldName === activeField && !isValueInAvailableChoices
        ? errorMessage
        : null;

    return valueNotInChoicesErrorMsg ?? choiceInvalidErrorMsg ?? fieldErrorMsg;
  };

  const updateFormState = useCallback(
    (fieldName: AddressFieldValue, fieldValue: string | null) => {
      if (formValues[fieldName] !== fieldValue) {
        const parsedFieldValue = getParsedFieldValue(fieldName, fieldValue);
        setValue(fieldName, parsedFieldValue);
      }
    },
    [formValues, setValue],
  );

  const resetFormValues = useCallback(
    (fieldNames: AddressFieldValueArray) =>
      fieldNames.forEach(fieldName => updateFormState(fieldName, '')),
    [updateFormState],
  );

  const resetDependentFields = (fieldName: AddressFieldValue) => {
    const config = getConfigItemByName(fieldName);
    const fieldsToReset = fieldConfig.filter(item =>
      config.dependsOn.includes(item.fieldName),
    );

    resetFormValues(fieldsToReset.map(item => item.fieldName));
    resetChoices(config);
    return getValues();
  };

  const setSingleValuesInForm = useCallback(
    (newChoices: Choices) =>
      Object.entries(newChoices)
        .filter(
          ([fieldName, fieldChoices]) =>
            fieldName !== AddressField.streetNumber &&
            fieldChoices.length === 1 &&
            fieldChoices[0] !== formValues[fieldName as AddressFieldValue],
        )
        .forEach(([fieldName, fieldChoices]) =>
          updateFormState(fieldName as AddressFieldValue, fieldChoices[0]),
        ),
    [formValues, updateFormState],
  );

  const onChoicePick = async (
    fieldName: AddressFieldValue,
    fieldValue: string | null,
    reason: AutocompleteInputChangeReason | AutocompleteChangeReason,
  ) => {
    updateFormState(fieldName, fieldValue);

    if (clearReason.includes(reason) && !fieldValue) {
      resetDependentFields(fieldName);
    }
  };

  const onInputChange = (fieldName: AddressFieldValue, fieldValue: string) => {
    updateFormState(fieldName, fieldValue);
    const requestData = resetDependentFields(fieldName);
    setActiveField(fieldName);
    refreshChoices({
      requestData,
      fieldName,
      setErroringField,
      debounced: true,
    });
    onChoicePick(fieldName, fieldValue, 'input');
  };

  useEffect(
    () => setSingleValuesInForm(choices),
    [choices, setSingleValuesInForm],
  );

  useEffect(() => {
    const isInvalidInitialPayload =
      !isLoading &&
      !choices.zipCode.length &&
      activeField == null &&
      formValues.zipCode;

    if (isInvalidInitialPayload) {
      resetFormValues(Object.values(AddressField));
    }
  }, [
    activeField,
    choices,
    formValues.zipCode,
    isLoading,
    resetFormValues,
    setSingleValuesInForm,
  ]);

  useEffect(
    () =>
      onChange({
        getValues: () => mapFormValuesToAddressFields(getValues()),
        trigger,
        hasErrors: () => Object.values(errors).length !== 0,
        errors,
        isExpanded,
      }),
    [errors, getValues, isExpanded, onChange, trigger],
  );

  const handleOnBlur = (
    fieldName: AddressFieldValue,
    onBlur: () => void,
    formValue: string,
  ) => {
    return () => {
      const availableChoices = choices[fieldName] ?? [];
      const isValueInAvailableChoices = Boolean(
        availableChoices.find(choice => choice === formValue),
      );

      if (!isValueInAvailableChoices) {
        updateFormState(fieldName, '');
        resetDependentFields(fieldName);
      }

      setActiveField(null);
      onBlur();
    };
  };

  return (
    <>
      {isExpandable ? (
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={!isExpanded}
              name={`${name}-expand`}
              onChange={() => setIsExpanded(!isExpanded)}
            />
          }
          label={t(`fields.addressContainer.${name}.expandCheckbox`)}
          labelPlacement="start"
        />
      ) : null}

      {!isExpandable || (isExpandable && isExpanded) ? (
        <FormControl component="fieldset" fullWidth={true}>
          {name === AddressType.BillingAddress ? (
            <StyledFormLabel as="legend" isBold={true}>
              {t(`fields.addressContainer.${name}.label`)}
            </StyledFormLabel>
          ) : null}

          <StyledFormLabel>
            {t('fields.addressContainerSwissPost.enterZipCode')}
          </StyledFormLabel>

          {isLoading ? <LoadingBackdrop contained={true} /> : null}

          <Box component="form" p="0.725rem 0">
            <Grid item={true} xs={12}>
              {fieldConfig.map(({ fieldName }) => (
                <Controller
                  control={control}
                  key={fieldName}
                  name={fieldName}
                  render={({ onBlur, value: formValue }) =>
                    !hiddenFields?.includes(fieldName) ? (
                      <SelectPickerSearchable
                        autoComplete={true}
                        complete={Boolean(formValue)}
                        displayRawValues={true}
                        error={getErrorMessage(fieldName, formValue)}
                        freeSolo={true}
                        inputRef={null}
                        isLoading={fieldLoading === fieldName}
                        label={`fields.addressContainerSwissPost.subFields.${fieldName}.label`}
                        name={fieldName}
                        onBlur={handleOnBlur(fieldName, onBlur, formValue)}
                        onChange={event =>
                          onChoicePick(
                            fieldName,
                            event.target.value,
                            event.reason,
                          )
                        }
                        onInputChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => onInputChange(fieldName, event.target.value)}
                        options={{
                          items: choices[fieldName].map(choice => ({
                            value: choice,
                            text: choice,
                          })),
                          disabled: isDisabled(fieldName),
                        }}
                        sx={{ mt: 1 }}
                        value={formValue}
                      />
                    ) : (
                      <input type="hidden" />
                    )
                  }
                />
              ))}
            </Grid>
          </Box>
        </FormControl>
      ) : null}
    </>
  );
};

AddressContainerSwissPost.validation =
  registerSubFormValidation<IAddressField>('addressContainer');
