import { useSelector } from 'react-redux';
import { FormControl, Box, CircularProgress } from '@material-ui/core';
import { useStatusPolling } from '@hooks';
import { getIsLoading } from '@redux';
import { CopyBlock } from '../../../components';
import { checkOnfidoStatus } from '../../../services/OnfidoStatusService';
import WaitingBot from '../WaitingBot';
import { IOnfidoHoldingPageProps } from './types';

export const BaseTranslationKey = 'fields.onfidoHoldingPage.default';

export const OnfidoHoldingPage = ({
  options,
  submit,
  submitAttempted,
}: IOnfidoHoldingPageProps) => {
  const isSubmitting = useSelector(getIsLoading);

  const { displayPagolightLogo = false, enableWaitingBot = false } = options;

  useStatusPolling({
    isSubmitting,
    onComplete: submit,
    pollingFunction: checkOnfidoStatus,
    retryIntervalMs: 6000,
    submitAttempted,
  });

  const getWaitingBot = () => (
    <Box alignItems="center" display="flex" flexDirection="column" mt={6}>
      <WaitingBot displayPagolightLogo={displayPagolightLogo} />
    </Box>
  );

  const getLegacySpinner = () => (
    <>
      <Box m={3}>
        <CopyBlock i18nKey={`${BaseTranslationKey}.title`} />
      </Box>
      <Box alignItems="center" display="flex" flexDirection="column" m={10}>
        <CircularProgress />
      </Box>
      <Box m={2}>
        <CopyBlock i18nKey={`${BaseTranslationKey}.content`} />
      </Box>
    </>
  );

  return (
    <FormControl
      className="notranslate"
      component="fieldset"
      fullWidth={true}
      size="small"
    >
      {enableWaitingBot ? getWaitingBot() : getLegacySpinner()}
      <Box m={2}>
        <CopyBlock i18nKey={`${BaseTranslationKey}.warning`} />
      </Box>
    </FormControl>
  );
};
