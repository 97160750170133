import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import DoneIcon from '@material-ui/icons/Done';
import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from '@hooks';

interface IAcceptedBlockProps {
  isCurrentAccepted: boolean;
}

const AcceptedContainer = styled(Box)(({ theme }) => ({
  background: BaseColour.GreenLight,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  marginBottom: '16px',
  alignSelf: 'center',
  alignItems: 'center',
}));

const StyledDoneIcon = styled(DoneIcon)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  marginLeft: theme.spacing(0.5),
  color: BaseColour.GreenDark,
}));

export const AcceptedBlock = ({ isCurrentAccepted }: IAcceptedBlockProps) => {
  const { t } = useTranslation();
  if (!isCurrentAccepted) {
    return null;
  }

  return (
    <AcceptedContainer>
      <Typography color={BaseColour.GreenDark} variant="body1">
        {t('fields.signatureList.acceptedBlock.content')}
      </Typography>
      <StyledDoneIcon />
    </AcceptedContainer>
  );
};
