/* eslint-disable max-len */
import { useTheme, Box } from '@material-ui/core';
import { IIconProps } from 'src/icons/type';

const SuccessIcon = ({ options }: IIconProps) => {
  const { height, width } = options ?? {};

  const {
    palette: { success },
  } = useTheme();
  const fillColor = success?.main || '#52c3c2';

  return (
    <Box textAlign="center">
      <svg
        fill="none"
        height={height ?? 140}
        role="img"
        viewBox="0 0 110 107"
        width={width ?? 110}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M109 53.5C109 82.4691 84.8496 106 55 106C25.1504 106 1 82.4691 1 53.5C1 24.5309 25.1504 1 55 1C84.8496 1 109 24.5309 109 53.5Z"
          fill="white"
        />
        <path
          d="M109 53.5C109 82.4691 84.8496 106 55 106C25.1504 106 1 82.4691 1 53.5C1 24.5309 25.1504 1 55 1C84.8496 1 109 24.5309 109 53.5Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M109 53.5C109 82.4691 84.8496 106 55 106C25.1504 106 1 82.4691 1 53.5C1 24.5309 25.1504 1 55 1C84.8496 1 109 24.5309 109 53.5Z"
          stroke="#C4C4C4"
          strokeWidth="2"
        />
        <rect
          fill={fillColor}
          height="60.816"
          rx="6"
          transform="matrix(0.641778 0.76689 -0.634353 0.773044 80.3457 24.6924)"
          width="13.2451"
        />
        <rect
          fill={fillColor}
          height="38.0476"
          rx="6"
          transform="matrix(-0.63529 0.772274 -0.640844 -0.767671 58.1949 72.0625)"
          width="13.2662"
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear"
            x1="55"
            x2="55"
            y1="0"
            y2="107"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default SuccessIcon;
