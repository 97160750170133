import { PropTypes } from 'prop-types';
import {
  ContactSupportOutlined,
  ChevronRightOutlined,
} from '@material-ui/icons';
import { IconButton, styled } from '@mui/material';
import { useTranslation } from '@hooks';

const HelpButton = styled(IconButton)`
  padding: 0.3rem;
  color: #ffffff;
  background-color: ${props => props.theme.palette.primary.main};
  margin-top: auto;
  margin-right: 20px;
  margin-left: -65px;
  position: absolute;
  bottom: 0;
  left: 0;
  &:hover {
    background-color: ${props => props.theme.palette.primary.main};
    opacity: 0.7;
  }
`;

const HelpFloatingButton = ({ isToggled, onClick }) => {
  const { t } = useTranslation();

  return (
    <HelpButton
      aria-labelledby="help-button-label"
      onClick={onClick}
      size="large"
    >
      <span hidden={true} id="help-button-label">
        {isToggled
          ? t('helpWidget.floatingButton.close')
          : t('helpWidget.floatingButton.open')}
      </span>
      {isToggled ? (
        <ChevronRightOutlined fontSize="large" />
      ) : (
        <ContactSupportOutlined fontSize="large" />
      )}
    </HelpButton>
  );
};

HelpFloatingButton.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HelpFloatingButton;
