import { useCallback, memo, useMemo } from 'react';
import * as yup from 'yup';
import { Delete } from '@material-ui/icons';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { TextInput, TextInputNumeric } from '../../../transaction/components';
import { InstoreProductSelect } from '../InstoreProductSelect';
import { ControlledField } from './components/ControlledField';
import { FieldNames } from './enums';
import { updateProductValue } from './utils/helpers';

export const InstoreProductCategoryForm = memo(
  ({
    control,
    currency,
    enableRemove,
    errors,
    index,
    isIdentityFieldEnabled,
    isNameFieldEnabled = true,
    isQuantityFieldEnabled,
    remove,
    setValue,
  }) => {
    const { t } = useTranslation();

    const updateProduct = useMemo(
      () => updateProductValue(index, setValue),
      [index, setValue],
    );

    // update the form with product details (sku, price, name etc)
    const handleSearchResult = useCallback(
      productDetails => {
        updateProduct(FieldNames.ProductSku, productDetails?.sku);
        updateProduct(FieldNames.Price, {
          amount: '',
          currency: productDetails?.expectedPrice?.currency ?? currency,
        });
        updateProduct(
          FieldNames.AdditionalDetails,
          productDetails?.additionalDetails,
          false,
        );
        updateProduct(FieldNames.Discount, productDetails?.discount);
        updateProduct(FieldNames.ResidualValue, productDetails?.residualValue);
        if (isNameFieldEnabled) {
          updateProduct(FieldNames.ProductName, '');
        }
        if (isIdentityFieldEnabled) {
          updateProduct(FieldNames.ProductIdentity, productDetails?.identity);
        }
        if (isQuantityFieldEnabled) {
          updateProduct(FieldNames.Quantity, '1');
        }
      },
      [
        currency,
        isIdentityFieldEnabled,
        isNameFieldEnabled,
        isQuantityFieldEnabled,
        updateProduct,
      ],
    );

    const handleRemove = () => remove(index);

    return (
      <Box mt={index > 0 ? '2rem' : 0}>
        <ControlledField
          Component={InstoreProductSelect}
          control={control}
          controlName={`products.${index}.${FieldNames.ProductSku}`}
          error={errors?.products?.[index]?.[FieldNames.ProductSku]?.message}
          id={FieldNames.ProductSku}
          name="category"
          onSearchResult={handleSearchResult}
          setValue={setValue}
        />
        {isNameFieldEnabled ? (
          <ControlledField
            Component={TextInput}
            control={control}
            controlName={`products.${index}.${FieldNames.ProductName}`}
            error={errors?.products?.[index]?.[FieldNames.ProductName]?.message}
            id={FieldNames.ProductName}
            name={FieldNames.ProductName}
            setValue={setValue}
          />
        ) : null}
        {isIdentityFieldEnabled ? (
          <ControlledField
            Component={TextInput}
            control={control}
            controlName={`products.${index}.${FieldNames.ProductIdentity}`}
            error={
              errors?.products?.[index]?.[FieldNames.ProductIdentity]?.message
            }
            id={FieldNames.ProductIdentity}
            name={FieldNames.ProductIdentity}
            options={{ disabled: true }}
            setValue={setValue}
          />
        ) : null}
        <Grid container={true}>
          <Grid
            pr={isQuantityFieldEnabled ? '1rem' : '0'}
            xs={isQuantityFieldEnabled ? 8 : 12}
          >
            <ControlledField
              Component={TextInputNumeric}
              control={control}
              controlName={`products.${index}.${FieldNames.Price}`}
              error={errors?.products?.[index]?.[FieldNames.Price]?.message}
              id={FieldNames.Price}
              name={FieldNames.Price}
              options={{ type: 'monetary' }}
              setValue={setValue}
            />
          </Grid>
          {isQuantityFieldEnabled ? (
            <Grid xs={4}>
              <ControlledField
                Component={TextInputNumeric}
                control={control}
                controlName={`products.${index}.${FieldNames.Quantity}`}
                error={
                  errors?.products?.[index]?.[FieldNames.Quantity]?.message
                }
                id={FieldNames.Quantity}
                name={FieldNames.Quantity}
                setValue={setValue}
              />
            </Grid>
          ) : null}
        </Grid>

        <Grid container={true}>
          {enableRemove ? (
            <Grid item={true} pr="40px" pt={1} textAlign="end" xs={12}>
              <Button
                onClick={handleRemove}
                size="large"
                startIcon={<Delete />}
                sx={{ py: 0, textTransform: 'none' }}
                variant="text"
              >
                {t('fields.instoreProductForm.remove')}
              </Button>
            </Grid>
          ) : null}
          <ControlledField
            Component={TextInput}
            control={control}
            controlName={`products.${index}.${FieldNames.AdditionalDetails}`}
            id={FieldNames.AdditionalDetails}
            name={FieldNames.AdditionalDetails}
            options={{ hidden: true }}
            setValue={setValue}
          />
          <ControlledField
            Component={TextInput}
            control={control}
            controlName={`products.${index}.${FieldNames.Discount}`}
            id={FieldNames.Discount}
            name={FieldNames.Discount}
            options={{ hidden: true }}
            setValue={setValue}
          />
          <ControlledField
            Component={TextInputNumeric}
            control={control}
            controlName={`products.${index}.${FieldNames.ResidualValue}`}
            id={FieldNames.ResidualValue}
            name={FieldNames.ResidualValue}
            options={{ type: 'monetary', hidden: true }}
            setValue={setValue}
          />
        </Grid>
      </Box>
    );
  },
);

export const validation = () =>
  yup
    .object()
    .transform(form => {
      if (form.submitAttempted) {
        form.trigger();
      }
      const formValues = form.getValues();
      return { ...form, ...formValues };
    })
    .test('instoreProductForm', 'ERROR', form => {
      return !form.hasErrors();
    })
    .nullable();

InstoreProductCategoryForm.displayName = 'InstoreProductCategoryForm';
InstoreProductCategoryForm.validation = validation;
