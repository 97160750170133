import { ReactNode } from 'react';
import { InfoOutlined } from '@material-ui/icons';
import { Link, styled, Typography } from '@mui/material';
import { useFormatters } from '../../../utils';
import { Size } from './enums';

const InfoOutlinedStyled = styled(InfoOutlined)({
  fontSize: Size.Lg,
  marginLeft: '4px',
  marginBottom: '-5px',
});

export const H1 = (
  <Typography component="h1" fontSize={Size.Xxl} lineHeight="32px" />
);

export const H2 = (
  <Typography component="h2" fontSize={Size.Xl} lineHeight="28px" />
);

export const H3 = (
  <Typography component="h3" fontSize={Size.Lg} lineHeight="24px" />
);

export const HyperlinkV2 = (
  <Link fontWeight="bold" sx={{ cursor: 'pointer' }} underline="always" />
);

const Title = (
  <Typography
    component="h1"
    fontSize={Size.Xxl}
    fontWeight="bold"
    lineHeight="32px"
  />
);

export const Info = <InfoOutlinedStyled />;

export const ParagraphV2 = <Typography component="p" mt={4} variant="body1" />;

export const ParagraphNoStylingV2 = (
  <Typography component="p" variant="body1" />
);

interface IFormatterProps {
  children?: ReactNode;
  currency?: string;
  format?: Intl.DateTimeFormatOptions;
}

const DateFormatter = ({ children, format }: IFormatterProps) => {
  const { formatDate } = useFormatters();

  if (!Array.isArray(children)) {
    return null;
  }

  const date = children.length === 1 ? children[0] : null;
  if (!date) {
    return null;
  }

  const options = format || {};

  return <span>{formatDate({ date, options })}</span>;
};

export const FormatDate = (props: IFormatterProps) => (
  <DateFormatter {...props} />
);

export const FormatDateDDMM = (props: IFormatterProps) => (
  <DateFormatter format={{ month: 'long', day: 'numeric' }} {...props} />
);

export const FormatAmount = ({ children, currency }: IFormatterProps) => {
  const { formatAmountWithCurrency } = useFormatters();

  if (!Array.isArray(children)) {
    return null;
  }

  const amount = children.length === 1 ? children[0] : null;

  if (!amount) {
    return null;
  }

  return <span>{formatAmountWithCurrency(amount, currency)}</span>;
};

export const SpanBody = <Typography component="span" variant="body1" />;

export const typographyComponentsRebranded = {
  h1: H1,
  h2: H2,
  h3: H3,
  formatAmount: <FormatAmount />,
  formatDate: <FormatDate />,
  formatDateDDMM: <FormatDateDDMM />,
  hyperlinkV2: HyperlinkV2,
  info: Info,
  paragraphV2: ParagraphV2,
  paragraphNoStylingV2: ParagraphNoStylingV2,
  spanBody: SpanBody,
  title: Title,
};
