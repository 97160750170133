import { Box, IconButton, makeStyles } from '@material-ui/core';
import { Cross } from '../../components/Icons';
import { useGetHeaderOptions } from './hooks';
import { HeaderLogo } from './logos/HeaderLogo';

const useStyles = makeStyles({
  root: {
    marginTop: '1.3rem',
    marginLeft: 0,
    marginRight: 0,
    '@media(max-width: 600px)': {
      marginTop: '0.875rem',
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  gutter: {
    width: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  close: {
    padding: 0,
    color: '#a4a4a4',
  },
});

export default function Header() {
  const classes = useStyles();
  const {
    abandonVisible,
    backToMerchant,
    cancelUrl,
    isRedirectToMerchantDisabled,
  } = useGetHeaderOptions();

  return (
    <Box
      alignItems="center"
      className={classes.root}
      component="header"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      marginRight={4}
    >
      <div className={classes.row}>
        <div className={classes.gutter} />

        <HeaderLogo />

        <div className={classes.gutter}>
          {!!cancelUrl && abandonVisible && (
            <IconButton
              aria-label="close"
              className={classes.close}
              disabled={isRedirectToMerchantDisabled}
              id="close-button"
              onClick={backToMerchant}
            >
              <Cross />
            </IconButton>
          )}
        </div>
      </div>
    </Box>
  );
}
