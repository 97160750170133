import PropTypes from 'prop-types';
import { FormLabel } from '@mui/material';
import { HelpTooltip } from '../HelpTooltip';

export const StackedLabel = ({
  className,
  isHeyLight = false,
  label,
  name,
  tooltip,
}) => {
  return (
    <FormLabel className={className} htmlFor={name}>
      {label}
      {tooltip ? (
        <HelpTooltip isHeyLight={isHeyLight} tooltip={tooltip} />
      ) : null}
    </FormLabel>
  );
};

StackedLabel.propTypes = {
  className: PropTypes.string,
  isHeyLight: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

StackedLabel.defaultProps = {
  className: undefined,
  tooltip: undefined,
};
