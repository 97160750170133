/* eslint-disable sonarjs/cognitive-complexity */
import PropTypes from 'prop-types';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { Divider, TableRow, Typography, IconButton } from '@mui/material';
import { useTranslation } from '@hooks';
import { HelpTooltip } from '../../HelpTooltip';
import { Cell } from '../../Styled';
import { RowType } from '../utils';

export const PaymentRow = ({
  alignAmount = 'right',
  currency,
  displayLabelBold = false,
  displayValueBold = false,
  header,
  hidden = false,
  isExpanded,
  isLabelOnly = false,
  isSmallFont = false,
  label,
  rowType,
  toggleExpanded,
  tooltip,
  value, // legacy prop
}) => {
  const { t } = useTranslation();
  const component = header ? 'th' : 'td';
  const displayLabelOnly = !value || isLabelOnly;
  const isScheduleToggle = rowType === RowType.scheduleToggle;

  if (hidden) {
    return null;
  }

  if (rowType === RowType.divider) {
    return (
      <TableRow>
        <Cell colSpan={3}>
          <Divider />
        </Cell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <Cell
        colSpan={displayLabelOnly ? 2 : 1}
        component={component}
        {...(isScheduleToggle
          ? {
              onClick: toggleExpanded,
            }
          : null)}
      >
        <Typography
          component="span"
          fontSize={isSmallFont ? '0.875rem' : '1rem'}
          fontWeight={displayLabelBold ? 'bold' : 'inherit'}
        >
          {label}
        </Typography>
        {tooltip ? <HelpTooltip isPlainText={true} tooltip={tooltip} /> : null}
        {isScheduleToggle && toggleExpanded ? (
          <IconButton
            aria-label={t('fields.paymentPlan.expand')}
            onClick={toggleExpanded}
            size="small"
          >
            {
              // onClick event isn't bubbling up to the button element...
              isExpanded ? (
                <KeyboardArrowUp onClick={toggleExpanded} />
              ) : (
                <KeyboardArrowDown onClick={toggleExpanded} />
              )
            }
          </IconButton>
        ) : null}
      </Cell>
      {displayLabelOnly ? null : (
        <>
          <Cell component={component}>
            <Typography
              component="span"
              fontSize={isSmallFont ? '0.875rem' : '1rem'}
              fontWeight={displayValueBold ? 'bold' : 'inherit'}
            >
              {currency}
            </Typography>
          </Cell>
          <Cell align={alignAmount} component={component}>
            <Typography
              component="span"
              fontSize={isSmallFont ? '0.875rem' : '1rem'}
              fontWeight={displayValueBold ? 'bold' : 'inherit'}
              whiteSpace="nowrap"
            >
              {value}
            </Typography>
          </Cell>
        </>
      )}
    </TableRow>
  );
};

PaymentRow.propTypes = {
  alignAmount: PropTypes.string,
  currency: PropTypes.string,
  displayLabelBold: PropTypes.bool,
  displayValueBold: PropTypes.bool,
  header: PropTypes.bool,
  hidden: PropTypes.bool,
  isExpanded: PropTypes.bool,
  label: PropTypes.string.isRequired,
  rowType: PropTypes.string,
  toggleExpanded: PropTypes.func,
  value: PropTypes.string,
};

PaymentRow.defaultProps = {
  alignAmount: 'right',
  currency: null,
  displayLabelBold: false,
  displayValueBold: false,
  header: false,
  hidden: false,
  isExpanded: false,
  rowType: RowType.amount,
  toggleExpanded: null,
  value: null,
};
