import { forwardRef } from 'react';
import { useFormatters } from '../../../../utils';
import { NumericFormatInput } from './NumericFormatInput';

export const MonetaryFixedFormatInput = forwardRef((props, ref) => {
  const { getDecimalSeparator, getGroupSeparator } = useFormatters();

  return (
    <NumericFormatInput
      decimalPlaces={0}
      decimalSeparator={getDecimalSeparator()}
      groupSeparator={getGroupSeparator()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
    />
  );
});

MonetaryFixedFormatInput.displayName = 'MonetaryFixedFormatInput';
