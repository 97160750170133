import { useRef } from 'react';
import { useTranslation } from '@hooks';
import { getConsentScrollableContainerTemplateSections } from '../utils/consentScrollableContainerUtil';

export const useContentScrollable = ({
  content,
  error,
  onChange,
  parentContainerRef,
  value,
}) => {
  const { t } = useTranslation();
  const hasErrors = Array.isArray(error) && error.length > 0;
  const htmlContentSections =
    getConsentScrollableContainerTemplateSections(content);
  const consentBlocks = value.map(consent => ({
    ...consent,
    // TODO eslint migration - resolve this error by re-designing logic
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ref: useRef(null),
  }));

  const moveToNextConsentRef = index => {
    if (index < consentBlocks.length - 1) {
      return consentBlocks[index + 1]?.ref?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
    return parentContainerRef?.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleOnItemChange = (key, newValue, index) => {
    const newArray = value.map(item =>
      item.key === key ? { ...item, value: newValue } : item,
    );

    // scroll to the next consent block or to the top if last
    moveToNextConsentRef(index);

    onChange(newArray);
  };

  // Throw an exception if there is a mismatch between the number of legal text paragraphs
  // and the number of consent blocks.
  if (htmlContentSections.length <= consentBlocks.length) {
    throw Error('Invalid number of consents for the number of text sections.');
  }

  return {
    consentBlocks,
    handleOnItemChange,
    hasErrors,
    htmlContentSections,
    moveToNextConsentRef,
    t,
  };
};
