import { Link, Typography } from '@mui/material';
import { FeatureFlag, useExecuteAsync, useFeatureFlags } from '@hooks';
import { getTranslationConstants } from '../../../constants';
import { SoftErrorKeys } from '../../../utils';
import { getPagolightProSecciUri } from '../../services/PagolightProSecciUriService';

interface ISecciLinkLegalNoticeOptions {
  numberOfInstallments: number;
}

interface ISecciLinkLegalNotice {
  options: ISecciLinkLegalNoticeOptions;
}

// Component for PagolightPro and Italian language only
export const SecciLinkLegalNotice = ({ options }: ISecciLinkLegalNotice) => {
  const openSecciDocumentInNewTab = async () => {
    const { numberOfInstallments } = options;
    const { secciPdfUrl } = await getPagolightProSecciUri(numberOfInstallments);

    window.open(secciPdfUrl, '_blank');
  };

  const handleOnClick = useExecuteAsync(openSecciDocumentInNewTab, {
    softErrorCode: SoftErrorKeys.retry,
  });

  const { flagEnabled } = useFeatureFlags();
  const isHeylightBrandingEnabled = flagEnabled(
    FeatureFlag.IsHeyLightBrandingEnabled,
  );
  const { italyInformativaUri } = getTranslationConstants(
    isHeylightBrandingEnabled,
  );

  return (
    <Typography>
      Cliccando sul pulsante PROSEGUI dichiari di aver preso visione del
      documento&nbsp;
      <Link
        onClick={handleOnClick}
        sx={{ cursor: 'pointer' }}
        underline="hover"
      >
        Informazioni Europee di Base sul credito ai consumatori
      </Link>
      &nbsp;e dell’
      <Link
        href={italyInformativaUri}
        rel="noreferrer"
        target="_blank"
        underline="hover"
      >
        informativa sul trattamento dei dati personali
      </Link>
    </Typography>
  );
};
