export const yesterday = () => {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  return new Date(d.toDateString());
};

export const getTomorrowDate = () => {
  const d = new Date();
  d.setDate(d.getDate() + 1);
  return new Date(d.toDateString());
};
