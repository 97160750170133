import { useCallback } from 'react';
import * as yup from 'yup';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { StackedLabel } from '../StackedLabel';
import {
  MonetaryFormatInput,
  MonetaryFixedFormatInput,
} from '../TextInputNumeric/components';
import {
  IBaseInputComponent,
  IBaseValidationOptions,
  IComponentBaseOptions,
} from '../types';
import { FieldType } from './enums';

interface ITextInputMoney {
  amount: string;
  currency: string;
}

interface ITextInputMoneyOptions extends IComponentBaseOptions {
  currency: string;
  hidden?: boolean;
  isFixedAmount?: boolean;
  tooltip?: string;
  type?: FieldType;
  withStackedLabel?: boolean;
}

interface ITextInputMoneyProps
  extends IBaseInputComponent<ITextInputMoneyOptions, ITextInputMoney> {
  controlName: string;
  options: ITextInputMoneyOptions;
}

export const TextInputMoney = ({
  complete,
  controlName,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  value,
}: ITextInputMoneyProps) => {
  const {
    currency,
    disabled = false,
    hidden = false,
    isFixedAmount = false,
    overrideKey,
    tooltip,
    withStackedLabel = false,
  } = options;

  const { t } = useTranslation();

  const label = t(`fields.textInput.${overrideKey || name}.label`);

  const updateState = useCallback(
    val => {
      onChange(val);
      if (controlName && setValue) {
        setValue(controlName ?? name, val);
      }
    },
    [controlName, name, onChange, setValue],
  );

  const handleOnChange = useCallback(
    amount => {
      if (amount) {
        const val = { amount, currency };
        updateState(val);
      } else {
        updateState(null);
      }
    },
    [currency, updateState],
  );

  if (hidden) {
    return null;
  }

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel
          className="MuiFormLabel-external"
          label={label}
          name={name}
          tooltip={tooltip}
        />
      ) : null}
      <CompleteWrapper complete={complete} error={error}>
        <TextField
          disabled={disabled}
          error={!!error}
          hidden={true}
          id={name}
          InputLabelProps={{ shrink: !!value?.amount }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{value?.currency}</InputAdornment>
            ),
            inputComponent: (isFixedAmount
              ? MonetaryFixedFormatInput
              : // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                MonetaryFormatInput) as any,
          }}
          inputProps={{ inputMode: 'decimal' }}
          inputRef={inputRef}
          label={withStackedLabel ? undefined : label}
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          size="small"
          type="text"
          value={value?.amount ?? ''}
          variant="outlined"
        />
      </CompleteWrapper>
      <FormHelperText>{t(error ?? '')}</FormHelperText>
    </FormControl>
  );
};

TextInputMoney.validation = (
  name: string,
  { optional }: IBaseValidationOptions,
) => {
  const schema = yup.mixed();

  if (optional) {
    return schema.nullable();
  }

  return schema.test(
    name,
    `fields.textInput.${name}.required`,
    value => Boolean(value?.amount) && Boolean(value?.currency),
  );
};
