import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { getMonthlyPayments, getPaymentPlan } from '../../../payment/redux';
import { useFormatters } from '../../../utils';
import { PaymentPlanTable } from '../PaymentPlanTable';
import { getPaymentPlanFields } from './utils/getPaymentPlanFields';

export const PaymentPlanMobilezone = () => {
  const { t: translate } = useTranslation();
  const { formatAmountWithCurrency } = useFormatters();
  const {
    schedule,
    summary: { currency, payMonthly, payNow, residualPayment, total } = {},
  } = useSelector(getPaymentPlan);

  const monthlyPayments = useSelector(getMonthlyPayments);

  const displayFields = getPaymentPlanFields({
    currency,
    residualPayment,
    formatAmountWithCurrency,
    payMonthly,
    payNow: payNow.amount,
    schedule,
    term: monthlyPayments,
    totalAmount: total,
    translate,
  });

  if (!displayFields) {
    return null;
  }

  return (
    <Grid container={true}>
      <PaymentPlanTable
        payNow={payNow}
        rows={displayFields}
        schedule={schedule}
      />
    </Grid>
  );
};
