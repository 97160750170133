import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FeatureFlag, useFeatureFlags } from '@hooks';
import { getBrand } from '@redux';
import { Brand } from '../../utils/constants';

const pagolightBrands: string[] = [Brand.pagolight, Brand.pagolightPro];

export const HtmlTitle = () => {
  const { flagEnabled } = useFeatureFlags();
  const isHeylightBrandingEnabled = flagEnabled(
    FeatureFlag.IsHeyLightBrandingEnabled,
  );
  const { logo } = useSelector(getBrand) || {};

  const overwriteFaviconAndTitleForBrand = useCallback(() => {
    const link = document.getElementById('favicon');

    if (isHeylightBrandingEnabled) {
      document.title = 'HeyLight';
      link?.setAttribute('href', '/favicon_heylight.ico');
    } else if (logo && pagolightBrands.includes(logo.toLowerCase())) {
      document.title = 'PagoLight';
      link?.setAttribute('href', '/favicon_pagolight.ico');
    }
  }, [isHeylightBrandingEnabled, logo]);

  useEffect(() => {
    overwriteFaviconAndTitleForBrand();
  }, [logo, overwriteFaviconAndTitleForBrand]);

  return null;
};
