import { useCallback } from 'react';
import * as yup from 'yup';
import { TextInput as TextInputBase } from '@heidi-pay/heidi-component-library/components/TextInput';
import { FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import { StackedLabel } from '../../StackedLabel';
import { FieldType, InputType } from '../../TextInput/utils/enums';
import {
  registerCodiceFiscaleValidation,
  registerIbanValidation,
} from '../../TextInput/validators';
import { IBaseInputComponent, IComponentBaseOptions } from '../../types';

interface ITextInputOptions extends IComponentBaseOptions {
  hidden?: boolean;
  labelOverride?: string;
  type?: typeof InputType;
  withInputAdornment?: boolean;
}

interface ITextInputProps
  extends IBaseInputComponent<ITextInputOptions, string> {
  complete?: boolean;
  controlName?: string;
  name: string;
  options: ITextInputOptions;
  value: string;
}

export const TextInput = ({
  complete,
  controlName,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options = {},
  setValue,
  value,
}: ITextInputProps) => {
  const { t } = useTranslation();

  const {
    disabled = false,
    hidden = false,
    labelOverride = undefined,
    tooltip,
    type: inputType = 'text',
    withStackedLabel = false,
  } = options;

  const label = t(`fields.textInput.${labelOverride || name}.label`);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const eventTargetValue = e.target.value;
      setValue(controlName ?? name, eventTargetValue);
      onChange(eventTargetValue);
    },
    [controlName, name, onChange, setValue],
  );

  if (hidden) {
    return null;
  }

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel
          className="MuiFormLabel-external"
          label={label}
          name={name}
          tooltip={tooltip}
        />
      ) : null}
      <TextInputBase
        complete={complete && !!value}
        disabled={disabled}
        hasError={!!error}
        id={name}
        inputRef={inputRef}
        label={withStackedLabel ? undefined : label}
        name={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        type={inputType as string}
        value={value}
      />
      {error ? <FormHelperText>{t(error)}</FormHelperText> : null}
    </FormControl>
  );
};

TextInput.validation = (
  name: string,
  { optional = false, regex = null, type = null },
) => {
  let schema = yup.string();

  // re-assigning is necessary because yup will clone the schema object when doing method chaining
  if (regex) {
    schema = schema.matches(
      new RegExp(regex),
      `fields.textInput.${name}.invalid`,
    );
  }

  if (name === FieldType.ibanNumber) {
    schema = registerIbanValidation({
      schema,
      errorTranslationKey: 'fields.textInput.ibanNumber.invalid',
    });
  } else if (name === FieldType.codiceFiscale) {
    schema = registerCodiceFiscaleValidation({
      schema,
      errorTranslationKey: 'fields.textInput.codiceFiscale.invalid',
      nameErrorTranslationKey: 'fields.textInput.codiceFiscale.invalidName',
      ageErrorTranslationKey: 'fields.textInput.codiceFiscale.invalidAge',
    });
  }

  if (type === InputType.email) {
    schema = schema.email('fields.textInput.email.invalid');
  }

  if (optional) {
    return schema.nullable();
  }

  return schema.required(`fields.textInput.${name}.required`);
};
