import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  Country,
  getCountries,
} from 'react-phone-number-input';
import * as yup from 'yup';
import { FormControl, FormHelperText, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { parseLocale } from '../../../../utils';
import { CallingCodePicker } from './components/CallingCodePicker';
import { PhoneNumberInput } from './components/PhoneNumberInput';

interface IMobileNumberInputProps {
  complete?: boolean;
  error?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  name: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  options?: {
    callingCodeFieldLabelOverride?: string;
    prefixSelectionDisabled?: boolean;
    withCallingCodeFieldLabel?: boolean;
  };
  value: string;
}

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  '& > *': {
    margin: '0 0.25rem',
    '&:first-of-type': { marginLeft: 0 },
    '&:last-of-type': { marginRight: 0 },
  },
});

export const MobileNumberInput = ({
  complete,
  error = '',
  inputRef,
  name,
  onBlur,
  onChange,
  options = {},
  value,
}: IMobileNumberInputProps) => {
  const { i18n, t } = useTranslation();
  const {
    callingCodeFieldLabelOverride = '',
    prefixSelectionDisabled = false,
    withCallingCodeFieldLabel = false,
  } = options;

  const defaultCountry = useMemo(() => {
    const parsedCountry =
      parseLocale(i18n.language).country?.toUpperCase() ?? '';
    return getCountries().includes(parsedCountry as Country)
      ? parsedCountry
      : '';
  }, [i18n.language]);

  const [country, setCountry] = useState<Country | ''>(defaultCountry);
  const [phoneNumber, setPhoneNumber] = useState(value);

  useEffect(() => {
    if (value) {
      const parsedNumber = parsePhoneNumber(value);
      if (parsedNumber?.country) {
        setCountry(parsedNumber.country);
        setPhoneNumber(parsedNumber.number);
      }
    } else {
      setCountry(defaultCountry as Country);
      setPhoneNumber('');
    }
  }, [value, defaultCountry]);

  const handlePhoneChange = useCallback(
    (newValueOrEvent: string | React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        typeof newValueOrEvent === 'string'
          ? newValueOrEvent
          : newValueOrEvent.target.value;
      setPhoneNumber(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  const handleCountryChange = useCallback(
    (newCountry: Country | '') => {
      setCountry(newCountry);
      if (phoneNumber) {
        const parsedNumber = parsePhoneNumber(phoneNumber, {
          defaultCountry: newCountry as Country,
        });
        if (parsedNumber) {
          const newPhoneNumber = parsedNumber.format('E.164');
          setPhoneNumber(newPhoneNumber);
          onChange(newPhoneNumber);
        }
      }
    },
    [phoneNumber, onChange],
  );

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ marginTop: '10px' }}
      variant="outlined"
    >
      <StyledContainer>
        <CallingCodePicker
          disabled={prefixSelectionDisabled}
          hasLabel={withCallingCodeFieldLabel}
          labelOverride={callingCodeFieldLabelOverride}
          name={`${name}-select`}
          onChange={handleCountryChange}
          value={country}
        />
        <PhoneNumberInput
          complete={complete}
          country={country}
          error={error}
          inputRef={inputRef}
          name={name}
          onBlur={onBlur}
          onChange={handlePhoneChange}
          value={phoneNumber}
        />
      </StyledContainer>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

MobileNumberInput.validation = (name: string) =>
  yup
    .string()
    .required(`fields.mobileNumberInput.${name}.required`)
    .test(
      'isValidPhoneNumber',
      `fields.mobileNumberInput.${name}.invalid`,
      value => isValidPhoneNumber(typeof value === 'string' ? value : ''),
    );
