import { Grid, styled } from '@mui/material';
import { useFormatters, FormatterType } from '@utils';
import { CopyBlock } from '../../../components';
import { HelpTooltip } from '../HelpTooltip';
import { ITextBlockValue } from './types';
import { getColorForBackendValue } from './utils/getColorForBackendValue';

interface ITextField {
  bold?: boolean;
}

const TextField = styled('div')(({ bold }: ITextField) => ({
  fontWeight: bold ? 'bold' : 'normal',
}));

export function TextBlockValue({
  name,
  options: {
    bold,
    colorVariant,
    currency,
    format,
    hideLabel,
    large = false,
    tooltip,
  } = {},
  value = '',
}: ITextBlockValue) {
  const { formatAmount, formatAmountWithCurrency, formatPercentage } =
    useFormatters();

  const formatters = {
    [FormatterType.AMOUNT]: () => formatAmount(value),
    [FormatterType.AMOUNT_WITH_CURRENCY]: () =>
      formatAmountWithCurrency(value, currency),
    [FormatterType.PERCENTAGE]: () => formatPercentage(value),
  };

  const formatter = format && formatters[format];

  const color = getColorForBackendValue(colorVariant);

  return (
    <Grid
      container={true}
      fontSize={large ? '1rem' : '0.875rem'}
      mb={0.75}
      mt={0.75}
    >
      <Grid item={true} xs={value ? 9 : 12}>
        {hideLabel ? null : (
          <CopyBlock
            i18nKey={`fields.textBlock.${name}.content`}
            sizeLarge={large}
            sx={{ display: 'inline' }}
          />
        )}
        {tooltip ? <HelpTooltip tooltip={tooltip} /> : null}
      </Grid>
      {value ? (
        <Grid color={color} item={true} textAlign="right" xs={3}>
          <TextField bold={bold}>{formatter ? formatter() : value}</TextField>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}

TextBlockValue.formatting = () => undefined;
