import { useSelector } from 'react-redux';
import { getCancelUrl, getFeatureFlags, getOriginatorUuid } from '@redux';
import { CopyBlock } from '../CopyBlock';
import { getI18nKey } from './utils';

export const MerchantRedirectLink = ({
  isHeylightBrandingEnabled,
  isIntroScreen = false,
}) => {
  const cancelUrl = useSelector(getCancelUrl);

  const { isPagolightTextUpdateEnabled } =
    useSelector(getFeatureFlags) ?? false;

  const originatorUuid = useSelector(getOriginatorUuid);

  const i18nKey = getI18nKey({
    isHeylightBrandingEnabled,
    isIntroScreen,
    isPagolightTextUpdateEnabled,
    originatorUuid,
  });

  return (
    <CopyBlock
      i18nKey={i18nKey}
      sx={{ textAlign: 'center', marginBottom: '16px', lineHeight: '20px' }}
      value={{ cancelUrl }}
    />
  );
};
