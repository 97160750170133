/* eslint-disable sonarjs/cognitive-complexity */
import { useRef } from 'react';

const useCodeVerification = (digits, onCodeSubmitted, onChange) => {
  const inputRefs = [];

  for (let i = 0; i < digits; i += 1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    inputRefs.push(useRef(null));
  }

  const generateCode = () => {
    const code = inputRefs.reduce(
      (previous, item) => `${previous}${item.current?.value || ''}`,
      '',
    );

    if (onChange) {
      onChange(code);
    }

    if (onCodeSubmitted && code.length === digits) {
      onCodeSubmitted(code);
    }
  };

  const handleSingleDigitInput = nextRef => {
    if (nextRef) {
      nextRef.current.focus();
    } else {
      inputRefs[digits - 1].current.blur();
    }
  };

  const handleMultiDigitInput = value => {
    const size = Math.min(value.length, digits);
    for (let i = 0; i < size; i += 1) {
      inputRefs[i].current.value = value[i];
    }
    if (value.length < digits) {
      inputRefs[value.length].current.focus();
    }
  };

  const onChangeEvent = (e, nextRef) => {
    const {
      target: { value },
    } = e;
    if (value.length === 1) {
      handleSingleDigitInput(nextRef);
    } else if (value.length > 1) {
      handleMultiDigitInput(value);
      e.target.blur();
    }
    generateCode();
  };

  const onKeyDown = e => {
    const ignoredKeyCodes = [37, 38, 39, 40, 69, 110, 188, 190];
    if (ignoredKeyCodes.includes(e.keyCode)) {
      e.preventDefault();
    } else {
      e.target.value = '';
    }
  };

  const onKeyUp = (e, prevRef) => {
    if (e.keyCode === 8) {
      onChangeEvent(e, e.target);
      prevRef.current.focus();
    }
  };

  return {
    inputRefs,
    onChange: onChangeEvent,
    onKeyDown,
    onKeyUp,
  };
};

export default useCodeVerification;
