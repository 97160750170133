import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStringToComponentMap } from '@appTypes';
import {
  FeatureFlag,
  TranslateOverrideProvider,
  useFeatureFlags,
} from '@hooks';
import { Form } from '../components/Form';

import { getMerchantDetails } from '../redux/selectors';
import fieldToComponentMapping from './fieldToComponentMapping';
import { heyLightFieldToComponentMapping } from './fieldToComponentMappingHeyLight';
import { submitStep, getCurrentStep } from './redux';

export default function Transaction() {
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);
  const { translationOverrides } = useSelector(getMerchantDetails);
  const { flagEnabled } = useFeatureFlags();
  const isHeyLightEnabled = flagEnabled(FeatureFlag.IsHeyLightBrandingEnabled);

  const submit = useCallback(
    fields => dispatch(submitStep(fields)),
    [dispatch],
  );
  const fields = useMemo(() => currentStep?.fields ?? [], [currentStep]);

  return (
    <TranslateOverrideProvider overrides={translationOverrides}>
      <Form
        fields={fields}
        fieldToComponentMapping={{
          ...(fieldToComponentMapping as IStringToComponentMap),
          ...(isHeyLightEnabled
            ? (heyLightFieldToComponentMapping as IStringToComponentMap)
            : {}),
        }}
        onSubmit={submit}
      />
    </TranslateOverrideProvider>
  );
}
