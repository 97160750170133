import { useCallback } from 'react';
import * as yup from 'yup';
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { StackedLabel } from '../StackedLabel';
import { IBaseInputComponent, IComponentBaseOptions } from '../types';

export interface ISelectPickerRawOption {
  text: string;
  value: string;
}

export interface ISelectPickerRawOptions extends IComponentBaseOptions {
  items: ISelectPickerRawOption[];
  withStackedLabel: boolean;
}

export const SelectPickerRaw = ({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}: IBaseInputComponent<ISelectPickerRawOptions, string, string>) => {
  const {
    disabled = false,
    items = [],
    withStackedLabel = false,
  } = options ?? {};

  const { t } = useTranslation();
  const displayLabel = t(`fields.selectPickerRaw.${name}.label`);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => onChange(event.target.value),
    [onChange],
  );

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel label={displayLabel} name={name} />
      ) : (
        <InputLabel htmlFor={name}>{displayLabel}</InputLabel>
      )}
      <CompleteWrapper complete={complete} error={error}>
        <Select
          id={name}
          inputRef={inputRef}
          label={withStackedLabel ? undefined : displayLabel}
          name={name}
          native={true}
          onBlur={onBlur}
          onChange={handleChange}
          value={value}
        >
          <option aria-label="None" value="" />
          {items.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </CompleteWrapper>
      <FormHelperText>{error ? t(error) : null}</FormHelperText>
    </FormControl>
  );
};

SelectPickerRaw.validation = () =>
  yup.string().required('fields.selectPickerRaw.required');
