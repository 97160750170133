import { Themes } from '../theme/config/enums';
import { IAppState } from './types';

export const initialState: IAppState = {
  abandonVisible: true,
  brand: {
    logo: null,
  },
  externalContractUuid: null,
  fallbackUrl: '',
  featureFlags: {},
  isLoading: true,
  isUnhandledError: false,
  locales: [],
  locale: '',
  metricsData: {
    domain: null,
    orgId: null,
    sessionId: null,
  },
  merchantDetails: {
    logo: '',
    name: '',
    primaryColour: '',
    translationOverrides: {},
    uuid: '',
  },
  networkDisconnectLog: [],
  originationChannel: '',
  originatorUuid: '',
  redirectUrls: {
    cancel: '',
    failure: '',
    success: '',
  },
  sessionDetails: {
    checkoutType: null,
    sessionUuid: null,
  },
  softErrorCode: '',
  themeType: Themes.HeyLight,
};
