import { useState, useEffect } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import PropTypes from 'prop-types';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile';
import 'react-phone-number-input/style.css';
import * as yup from 'yup';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { parseLocale } from '../../../utils';
import CallingCodePicker from './CallingCodePicker';
import PhoneNumberInput from './PhoneNumberInput';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      marginLeft: '0.25rem',
      marginRight: '0.25rem',
    },
    '& > *:first-child': {
      marginLeft: '0',
    },
    '& > *:last-child': {
      marginRight: '0',
    },
  },
  label: {
    marginLeft: '0.25rem',
    marginBottom: '20px',
  },
});

export default function MobileNumberInput({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}) {
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const { prefixSelectionDisabled, withFieldLabel } = options;
  const transLabel = t(`fields.mobileNumberInput.${name}.label`);
  const displayFormLabel = !withFieldLabel && transLabel;
  // determine country for country code picker
  const defaultCountry =
    parseLocale(i18n.language).country?.toUpperCase() ?? '';
  const [country, setCountry] = useState(defaultCountry);

  useEffect(() => {
    if (value) {
      const { country: newCountry } =
        parsePhoneNumber(value, { defaultCountry }) || {};
      if (newCountry) {
        setCountry(newCountry);
      }
    } else if (defaultCountry) {
      setCountry(defaultCountry);
    }
  }, [value, defaultCountry]);

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      {displayFormLabel ? (
        <FormLabel className={classes.label} htmlFor={name}>
          {transLabel}
        </FormLabel>
      ) : null}
      <CompleteWrapper complete={complete} error={error}>
        <div className={classes.root}>
          <CallingCodePicker
            disabled={prefixSelectionDisabled}
            name={`${name}-select`}
            onChange={setCountry}
            value={country}
          />
          <PhoneNumberInput
            country={country}
            error={error}
            inputRef={inputRef}
            name={name}
            onBlur={onBlur}
            onChange={val => onChange(val === undefined ? '' : val)}
            value={value}
            withLabel={withFieldLabel}
          />
        </div>
      </CompleteWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
}

MobileNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  error: PropTypes.string,
  complete: PropTypes.bool,
  options: PropTypes.shape({
    prefixSelectionDisabled: PropTypes.bool,
    withFieldLabel: PropTypes.bool,
  }),
};

MobileNumberInput.defaultProps = {
  error: '',
  complete: false,
  inputRef: undefined,
  options: {
    prefixSelectionDisabled: true,
    withFieldLabel: false,
  },
};

MobileNumberInput.validation = name =>
  yup
    .string()
    .required(`fields.mobileNumberInput.${name}.required`)
    .test(
      'isValidPhoneNumber',
      `fields.mobileNumberInput.${name}.invalid`,
      value => isValidPhoneNumber(typeof value === 'string' ? value : ''),
    );
