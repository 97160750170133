import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  FormControl,
  InputAdornment,
  TextField,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { StackedLabel } from '../StackedLabel';
import { FieldType, InputType } from './utils/enums';
import {
  registerCodiceFiscaleValidation,
  registerIbanValidation,
} from './validators';

export const TextInput = ({
  complete,
  controlName,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  value,
}) => {
  const { t } = useTranslation();

  const {
    disabled = false,
    hidden = false,
    labelOverride = undefined,
    tooltip,
    type: inputType = InputType.text,
    withInputAdornment = false,
    withStackedLabel = false,
  } = options;

  const label = t(`fields.textInput.${labelOverride || name}.label`);

  const inputAdornmentText = withInputAdornment
    ? t(`fields.textInput.${labelOverride || name}.inputAdornment`)
    : '';

  if (hidden) {
    return null;
  }

  const handleOnChange = e => {
    setValue(controlName ?? name, e.target.value);
    onChange(e);
  };

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel
          className="MuiFormLabel-external"
          label={label}
          name={name}
          tooltip={tooltip}
        />
      ) : null}
      <CompleteWrapper complete={complete} error={error}>
        <TextField
          disabled={disabled}
          error={!!error}
          fullwidth="true"
          hidden={true}
          id={name}
          InputLabelProps={{ shrink: value !== '' || withInputAdornment }}
          InputProps={{
            startAdornment: withInputAdornment ? (
              <InputAdornment position="start" sx={{ marginTop: '0.125rem' }}>
                {inputAdornmentText}
              </InputAdornment>
            ) : null,
          }}
          inputRef={inputRef}
          label={withStackedLabel ? undefined : label}
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          size="small"
          type={inputType}
          value={value}
          variant="outlined"
        />
      </CompleteWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

TextInput.validation = (
  name,
  { optional = false, regex = null, type = null },
) => {
  let schema = yup.string();

  // re-assigning is necessary because yup will clone the schema object when doing method chaining
  if (regex) {
    schema = schema.matches(
      new RegExp(regex),
      `fields.textInput.${name}.invalid`,
    );
  }

  if (name === FieldType.ibanNumber) {
    schema = registerIbanValidation({
      schema,
      errorTranslationKey: 'fields.textInput.ibanNumber.invalid',
    });
  } else if (name === FieldType.codiceFiscale) {
    schema = registerCodiceFiscaleValidation({
      schema,
      errorTranslationKey: 'fields.textInput.codiceFiscale.invalid',
      nameErrorTranslationKey: 'fields.textInput.codiceFiscale.invalidName',
      ageErrorTranslationKey: 'fields.textInput.codiceFiscale.invalidAge',
    });
  }

  if (type === InputType.email) {
    schema = schema.email('fields.textInput.email.invalid');
  }

  if (optional) {
    return schema.nullable();
  }

  return schema.required(`fields.textInput.${name}.required`);
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.shape({
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    labelOverride: PropTypes.string,
    optional: PropTypes.bool,
    regex: PropTypes.string,
    type: PropTypes.string,
    withStackedLabel: PropTypes.bool,
  }),
  inputRef: PropTypes.shape(),
  error: PropTypes.string,
  complete: PropTypes.bool,
};

TextInput.defaultProps = {
  error: '',
  complete: false,
  inputRef: undefined,
  options: {
    disabled: false,
    hidden: false,
    labelOverride: undefined,
    optional: false,
    regex: undefined,
    type: InputType.text,
    withStackedLabel: false,
  },
};
