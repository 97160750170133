import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { registerSubFormValidation } from '../../../utils';
import { setProducts } from '../../redux';
import { getFormSettings } from '../InstoreProductForm/config';
import { MultiProductForm } from './components/MultiProductForm';

export const InstoreMultiProductFormContainer = ({
  onChange,
  options = {},
  submitAttempted,
}) => {
  const dispatch = useDispatch();
  const {
    currency,
    isIdentityFieldEnabled,
    isNameFieldEnabled,
    isQuantityFieldEnabled = false,
    isSingleProductSelectionEnabled = false,
    variant = 'default',
  } = options;

  const variantConfig = getFormSettings(variant, {
    isIdentityFieldEnabled,
    isNameFieldEnabled,
    isQuantityFieldEnabled,
  });

  const {
    defaultValues,
    instoreProductForm,
    validationSchema: validation,
  } = variantConfig;

  const [productsCopy, setProductsCopy] = useState(defaultValues.products);

  const validationSchema = yup.object().shape({
    products: yup.array().of(yup.object().shape(validation)).required(),
  });

  const { control, errors, getValues, setValue, trigger, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues,
  });

  useEffect(() => {
    // Return the value as form functions - for use in the validator
    onChange({
      getValues,
      hasErrors: () => Object.values(errors).length !== 0,
      isSubmitAttempted: () => submitAttempted,
      trigger,
    });
  }, [errors, getValues, onChange, submitAttempted, trigger]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateStoreDebounced = useCallback(
    debounce(() => {
      const values = getValues();
      dispatch(setProducts(values?.products ?? []));
    }, 800),
    [dispatch, getValues, setProducts],
  );

  const productsWatch = watch('products');
  useEffect(() => {
    // the component re-renders when sibling components in the form change value
    // this comparison ensures we're only updating the store when the product arary changes
    const previousProductsJson = JSON.stringify(productsCopy);
    const currentProductsJson = JSON.stringify(productsWatch);
    if (previousProductsJson !== currentProductsJson) {
      updateStoreDebounced();
      setProductsCopy(productsWatch);
    }
  }, [productsCopy, productsWatch, updateStoreDebounced]);

  return (
    <Grid container={true} justifyContent="center">
      <Grid item={true} xs={12}>
        <MultiProductForm
          control={control}
          currency={currency}
          defaultValues={defaultValues}
          errors={errors}
          getValues={getValues}
          InstoreProductForm={instoreProductForm}
          instoreProductFormProps={{
            isIdentityFieldEnabled,
            isNameFieldEnabled,
            isQuantityFieldEnabled,
          }}
          isAddProductButtonDisabled={isSingleProductSelectionEnabled}
          setValue={setValue}
          watch={watch}
        />
      </Grid>
    </Grid>
  );
};

InstoreMultiProductFormContainer.validation = registerSubFormValidation(
  'instoreMultiProductFormContainer',
  true,
);
