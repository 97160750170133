import { Button, Grid } from '@mui/material';
import ContinueButton from '../../ContinueButton';
import { IActionButtonsStackedProps } from './types';

export const ActionButtonsInline = ({
  primaryButtonAction,
  primaryButtonOptions,
  secondaryButtonAction,
  secondaryButtonLabel,
}: IActionButtonsStackedProps) => (
  <Grid container={true} pt={6}>
    <Grid item={true} textAlign="start" xs={6}>
      <Button
        color="primary"
        onClick={secondaryButtonAction}
        sx={{ textTransform: 'none' }}
        variant="outlined"
      >
        {secondaryButtonLabel}
      </Button>
    </Grid>
    <Grid item={true} textAlign="end" xs={6}>
      <ContinueButton
        options={primaryButtonOptions}
        submit={primaryButtonAction}
      />
    </Grid>
  </Grid>
);
