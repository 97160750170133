import { useDispatch } from 'react-redux';
import { makeStyles, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from '@hooks';
import { resetErrorState } from '@redux';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    WebkitTapHighlightColor: 'transparent',
  },
});

export default function ErrorBackdrop() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const close = () => {
    dispatch(resetErrorState());
  };

  return (
    <div aria-hidden={true} className={classes.backdrop}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={close}
            size="small"
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        severity="error"
        variant="filled"
      >
        <AlertTitle>{t('error.title')}</AlertTitle>
        {t('error.description')}
      </Alert>
    </div>
  );
}
