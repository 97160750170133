import { useSelector } from 'react-redux';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../../../components';
import { getMonthlyPayments } from '../../../../payment/redux';
import { Dialog } from '../../Dialog';
import DocumentTile from './DocumentTile';

const TermsDialog = ({
  documents,
  isOpen,
  onClose,
  onComplete,
  secciDocuments,
}) => {
  const { t } = useTranslation();
  const monthlyPayments = useSelector(getMonthlyPayments);
  const secciDocument = secciDocuments[monthlyPayments];

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('fields.mandatoryActionContainer.termsDialog.title')}
    >
      <Box alignContent="center" pb={4} px={{ sm: 4 }} textAlign="center">
        <CopyBlock
          i18nKey="fields.mandatoryActionContainer.termsDialog.body"
          sizeLarge={true}
        >
          {t('fields.mandatoryActionContainer.termsDialog.body')}
        </CopyBlock>

        <Typography mt={0.5} paragraph={true} variant="caption">
          {t('fields.mandatoryActionContainer.termsDialog.caption')}
        </Typography>

        <Grid container={true} justifyContent="center" mt={5}>
          <DocumentTile
            href={secciDocument.uri}
            onClick={onComplete}
            outlined={true}
            textAlign="center"
            title={t(
              `fields.mandatoryActionContainer.termsDialog.${secciDocument.key}`,
            )}
          />
          <Grid item={true} mt={5} xs={12}>
            <Typography variant="body1">
              {t(
                'fields.mandatoryActionContainer.termsDialog.documentListTitle',
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} mt={2}>
          {documents.map(({ key, uri }) => (
            <DocumentTile
              href={uri}
              key={key}
              textAlign="start"
              title={t(`fields.mandatoryActionContainer.termsDialog.${key}`)}
            />
          ))}
        </Grid>
      </Box>
    </Dialog>
  );
};

TermsDialog.propTypes = {
  documents: arrayOf(
    shape({
      uri: string.isRequired,
      title: string.isRequired,
    }),
  ).isRequired,
  isOpen: bool.isRequired,
  secciDocuments: shape().isRequired,
  onClose: func.isRequired,
  onComplete: func.isRequired,
};

export default TermsDialog;
