import { useSelector } from 'react-redux';
import { Box, Divider, styled } from '@mui/material';
import { Children } from '@appTypes';
import { getFeatureFlags } from '@redux';
import Header from './components/Header';
import { ContentWrapper } from './components/heyLight';
import { HtmlTitle } from './components/HtmlTitle';
import Progress from './components/Progress';

const StyledDivider = styled(Divider)({
  marginTop: '1.3rem',
  marginLeft: '0',
  marginRight: '0',
  '@media(max-width: 600px)': {
    marginTop: '0.875rem',
  },
});

const MainContentStyled = styled(Box)({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
});

interface IMainContent {
  children: Children;
}

export const MainContent = ({ children }: IMainContent) => {
  const { isHeylightBrandingEnabled } = useSelector(getFeatureFlags);

  return (
    <MainContentStyled component="section" pt={1}>
      <HtmlTitle />
      {isHeylightBrandingEnabled ? (
        <ContentWrapper>{children}</ContentWrapper>
      ) : (
        <>
          <Header />
          <Progress />
          <StyledDivider variant="middle" />
          {children}
        </>
      )}
    </MainContentStyled>
  );
};
