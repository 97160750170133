import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  IPaymentRowProps,
  RowType,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IUseFormatter } from '@utils/formatters/types';
import { IScheduleData } from './types';

export const getSwissMobilezoneInstorePaymentPlanFields = (
  {
    currency,
    initialBalance,
    initialPayment,
    interest,
    monthlyPayments,
    schedule,
    total,
  }: IScheduleData,
  formatters: IUseFormatter,
  translate: Translate,
): IPaymentRowProps[] | null => {
  if (!total) {
    return null;
  }

  const monthlyPayment = schedule[0].amount;
  const scheduleLength = schedule.length;
  const residualValue = schedule[scheduleLength - 1];
  const amountWithoutResidualPayment =
    parseFloat(total) - parseFloat(residualValue.amount);

  const { apr, interestAmount } = interest;
  const { formatAmountWithCurrency, formatDecimalToPercentage } = formatters;

  const baseKey = 'fields.paymentPlanMobilezoneInstore';

  const payFields: IPaymentRowProps[] = [
    {
      label: translate(`${baseKey}.payNow`),
      labelVariant: 'h6',
      value: formatAmountWithCurrency(initialPayment || '0', currency),
      valueVariant: 'h6',
    },
    {
      label: translate(`${baseKey}.payMonthly`),
      labelVariant: 'h6',
      value: formatAmountWithCurrency(monthlyPayment, currency),
      valueVariant: 'h6',
    },
  ];

  const otherFields: IPaymentRowProps[] =
    apr && parseFloat(apr) !== 0
      ? [
          {
            label: translate(`${baseKey}.creditDuration`),
            labelVariant: 'h6',
            value:
              monthlyPayments > 1
                ? translate(`${baseKey}.creditDurationMonths`, {
                    creditDuration: monthlyPayments,
                  })
                : translate(`${baseKey}.creditDurationMonth`, {
                    creditDuration: monthlyPayments,
                  }),
            valueVariant: 'h6',
          },
          {
            label: translate(`${baseKey}.annualInterestRate`),
            labelVariant: 'h6',
            value: formatDecimalToPercentage(apr),
            valueVariant: 'h6',
          },
          {
            label: translate(`${baseKey}.totalInterest`),
            labelVariant: 'h6',
            value: formatAmountWithCurrency(interestAmount, currency),
            valueVariant: 'h6',
          },
          {
            label: translate(`${baseKey}.totalPricePlusInterest`),
            labelVariant: 'h6',
            value: formatAmountWithCurrency(initialBalance, currency),
            valueVariant: 'h6',
          },
        ]
      : [
          {
            label: 'payment-plan-divider-1',
            rowType: RowType.Divider,
          },
          {
            displayLabelBold: true,
            displayValueBold: true,
            label: translate(`${baseKey}.totalAmountWithoutResidualPayment`),
            labelVariant: 'h6',
            value: formatAmountWithCurrency(
              amountWithoutResidualPayment,
              currency,
            ),
            valueVariant: 'h6',
          },
          {
            label: 'payment-plan-divider-2',
            rowType: RowType.Divider,
          },
          {
            label: translate(`${baseKey}.residualAmount`, {
              term: scheduleLength + 1,
            }),
            labelVariant: 'h6',
            value: formatAmountWithCurrency(residualValue.amount, currency),
            valueVariant: 'h6',
          },
          {
            label: 'payment-plan-divider-3',
            rowType: RowType.Divider,
          },
          {
            displayLabelBold: true,
            displayValueBold: true,
            label: translate(`${baseKey}.totalAmount`),
            labelVariant: 'h6',
            value: formatAmountWithCurrency(total, currency),
            valueVariant: 'h6',
          },
          {
            label: 'payment-plan-divider-4',
            rowType: RowType.Divider,
          },
        ];

  return [...payFields, ...otherFields];
};
