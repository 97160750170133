import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  FormControl,
  Autocomplete,
  TextField,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { StackedLabel } from '../StackedLabel';
import useSelectPickerUtil from './utils/SelectPickerUtil';

export const SelectPickerSearchable = ({
  autoComplete,
  complete,
  displayRawValues,
  error,
  freeSolo,
  isLoading,
  label,
  name,
  onBlur,
  onChange,
  onInputChange,
  options,
  sx,
  value,
}) => {
  const parsedValue = value ?? '';
  const { disabled, tooltip, withStackedLabel } = options;

  const { t } = useTranslation();
  const displayLabel = t(
    label ?? `fields.selectPickerSearchable.${name}.label`,
  );

  const { sortAndTranslateItemsBackwardCompatible } = useSelectPickerUtil();
  const selectItems = displayRawValues
    ? options.items
    : sortAndTranslateItemsBackwardCompatible(options, name, t);

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem', ...sx }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel label={displayLabel} name={name} tooltip={tooltip} />
      ) : null}
      <CompleteWrapper complete={complete} error={error}>
        <Autocomplete
          autoComplete={autoComplete}
          disabled={disabled}
          freeSolo={freeSolo}
          fullWidth={true}
          getOptionLabel={option => option.text}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={(event, newValue, reason) => {
            const syntheticEvent = {
              ...event,
              target: {
                ...event.target,
                name,
                value: newValue ? newValue.value : '',
              },
              reason,
            };
            onChange(syntheticEvent);
          }}
          options={selectItems}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress size="25px" /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label={withStackedLabel ? undefined : displayLabel}
              onChange={event => onInputChange?.(event)}
            />
          )}
          size="small"
          value={
            selectItems.find(item => item.value === parsedValue) ?? {
              value: parsedValue,
              text: parsedValue,
            }
          }
        />
      </CompleteWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

SelectPickerSearchable.propTypes = {
  autoComplete: PropTypes.bool,
  complete: PropTypes.bool,
  displayRawValues: PropTypes.bool,
  error: PropTypes.string,
  freeSolo: PropTypes.bool,
  inputRef: PropTypes.shape(),
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  options: PropTypes.shape().isRequired,
  value: PropTypes.string.isRequired,
};

SelectPickerSearchable.defaultProps = {
  autoComplete: false,
  complete: false,
  displayRawValues: false,
  error: '',
  freeSolo: false,
  isLoading: false,
  inputRef: undefined,
  label: null,
};

SelectPickerSearchable.validation = () =>
  yup.string().required('fields.selectPicker.required');
