import PropTypes from 'prop-types';
import { TextField, makeStyles } from '@material-ui/core';
import { useTranslation } from '@hooks';

import PhoneInputAdapter from './PhoneInputAdapter';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginLeft: '2px',
  },
});

export default function PhoneNumberInput({
  country,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  value,
  withLabel,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const label = withLabel
    ? t(`fields.mobileNumberInput.${name}.placeholder`)
    : '';

  return (
    <TextField
      className={classes.root}
      error={!!error}
      id={name}
      InputLabelProps={{ shrink: value !== '' }}
      InputProps={{
        inputComponent: PhoneInputAdapter,
        inputProps: {
          country,
          label,
        },
      }}
      inputRef={inputRef}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={t(`fields.mobileNumberInput.${name}.placeholder`)}
      size="small"
      value={value}
      variant="outlined"
    />
  );
}

PhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  country: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  inputRef: PropTypes.shape(),
  withLabel: PropTypes.bool,
};

PhoneNumberInput.defaultProps = {
  value: '',
  country: undefined,
  inputRef: undefined,
  withLabel: false,
};
