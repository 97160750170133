import PropTypes from 'prop-types';
import * as yup from 'yup';
import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { StackedLabel } from '../StackedLabel';

export const NumberPicker = ({
  complete,
  error,
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  options,
  value,
}) => {
  const { disabled, max, min, sortDesc, tooltip, withStackedLabel } = options;

  const { t } = useTranslation();
  const displayLabel = t(label ?? `fields.numberPicker.${name}.label`);

  let numbers = Array.from({ length: max - min + 1 }, (_, i) => min + i);
  if (sortDesc) {
    numbers = numbers.reverse();
  }

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel label={displayLabel} name={name} tooltip={tooltip} />
      ) : (
        <InputLabel htmlFor={name}>{displayLabel}</InputLabel>
      )}
      <CompleteWrapper complete={complete} error={error}>
        <Select
          id={name}
          inputRef={inputRef}
          label={withStackedLabel ? undefined : displayLabel}
          name={name}
          native={true}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        >
          <option aria-label="None" value="" />
          {numbers.map(num => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </Select>
      </CompleteWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

NumberPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  complete: PropTypes.bool,
  error: PropTypes.string,
  inputRef: PropTypes.shape(),
  label: PropTypes.string,
};

NumberPicker.defaultProps = {
  complete: false,
  displayRawValues: false,
  error: '',
  inputRef: undefined,
  label: null,
};

NumberPicker.validation = () =>
  yup.string().required('fields.selectPicker.required');
