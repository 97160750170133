import { string } from 'prop-types';
import { PictureAsPdf } from '@material-ui/icons';
import { Grid, Link, styled, Typography } from '@mui/material';

const Icon = styled(PictureAsPdf)`
  width: 2rem;
  height: 2rem;
`;

const DocumentTile = ({ href, title }) => (
  <Grid item={true} md={6} py={0.5} xs={12}>
    <Grid container={true} py={1}>
      <Grid alignItems="center" display="flex" item={true} xs={2}>
        <Icon color="primary" />
      </Grid>
      <Grid item={true} sm={10} xs={10}>
        <Link color="initial" href={href} target="_blank" underline="hover">
          <Typography fontWeight="500" variant="subtitle2">
            {title}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  </Grid>
);

DocumentTile.propTypes = {
  href: string.isRequired,
  title: string.isRequired,
};

export default DocumentTile;
