import * as yup from 'yup';
import { FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { IBaseInputComponent } from '../types';
import { ButtonGroup } from './components/ButtonGroup';
import { FlexContainer, FormLabelStyled } from './components/Styled';
import { IButtonGroupPickerOptions } from './types';

export const ButtonGroupPicker = ({
  complete,
  error,
  name,
  onChange,
  options,
  value = '',
}: IBaseInputComponent<IButtonGroupPickerOptions & string[], string>) => {
  const { t } = useTranslation();

  if (!options) {
    throw Error('The options prop is required');
  }

  // Required for backwards compatibility while we update origination not to provide an array
  const parsedOptions = Array.isArray(options)
    ? ({
        items: options,
        withCompleteWrapper: true,
        translationAttributes: {},
      } as IButtonGroupPickerOptions)
    : options;

  // The component is being used in a multi input field form which requires a complete wrapper
  const isMultiInputFormField = options?.withCompleteWrapper;

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      <FlexContainer
        alignItems={isMultiInputFormField ? undefined : 'center'}
        flexDirection={isMultiInputFormField ? undefined : 'row'}
        justifyContent={isMultiInputFormField ? undefined : 'space-between'}
      >
        <FormLabelStyled
          color={isMultiInputFormField ? undefined : 'inherit'}
          fontSize={isMultiInputFormField ? '1rem' : '0.875rem'}
          htmlFor={name}
        >
          {t(
            `fields.buttonGroupPicker.${name}.label`,
            parsedOptions?.translationAttributes,
          )}
        </FormLabelStyled>
        {isMultiInputFormField ? (
          <CompleteWrapper complete={complete} error={error}>
            <ButtonGroup
              name={name}
              onChange={onChange}
              options={parsedOptions.items}
              value={value}
            />
          </CompleteWrapper>
        ) : (
          <ButtonGroup
            name={name}
            onChange={onChange}
            options={parsedOptions.items}
            value={value}
          />
        )}
      </FlexContainer>
      {error ? <FormHelperText>{t(error)}</FormHelperText> : null}
    </FormControl>
  );
};

ButtonGroupPicker.validation = () =>
  yup.string().required('fields.buttonGroupPicker.required');
