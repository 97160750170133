import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import {
  getOrderProfile,
  getOrderProfileContractType,
} from '@instore/redux/selectors';
import { useFormatters } from '@utils';
import { getPaymentPlan, getMonthlyPayments } from '../../../payment/redux';
import { getOriginatorUuid } from '../../../redux/selectors';
import { PaymentPlanTable } from '../PaymentPlanTable';
import { IBaseComponent } from '../types';
import { ContractType } from './enums';
import { IPaymentPlanSwissCreditOptions } from './types';
import { getPaymentPlanFields } from './utils/getPaymentPlanFields';

export const PaymentPlanSwissCredit = ({
  options = {},
}: IBaseComponent<IPaymentPlanSwissCreditOptions, unknown>) => {
  const { t: translate } = useTranslation();
  const formatters = useFormatters();
  const {
    schedule,
    summary: {
      creditAmount,
      currency,
      initialBalance,
      interest,
      payMonthly,
      payNow,
      refreshPrice,
      residualPayment,
      total,
      totalPricePlusInterest,
    },
  } = useSelector(getPaymentPlan);

  const orderProfile = useSelector(getOrderProfile);
  const monthlyPayments = useSelector(getMonthlyPayments);
  const contractType = useSelector(getOrderProfileContractType);
  const originatorUuid = useSelector(getOriginatorUuid);
  const showCreditFields =
    !options.instore || contractType !== ContractType.Factoring;
  const orderProfileRequired = Boolean(options.instore && !orderProfile);

  const displayFields = getPaymentPlanFields({
    filters: {
      isRegulatedProduct: false,
      originatorUuid,
    },
    formatters,
    scheduleData: {
      creditAmount,
      currency,
      initialBalance,
      interest,
      monthlyPayments,
      nominalInterestRate: 0,
      numberOfInstallments: schedule.length,
      orderProfileRequired,
      payMonthly,
      payNow,
      refreshPrice,
      residualPayment,
      showCreditFields,
      total,
      totalPricePlusInterest,
    },
    translate,
  });

  if (!displayFields) {
    return null;
  }

  return (
    <Grid container={true}>
      <PaymentPlanTable
        payNow={payNow}
        rows={displayFields}
        schedule={schedule}
      />
    </Grid>
  );
};
