import PropTypes from 'prop-types';
import { ArrowDropDown, Lock } from '@material-ui/icons';
import { CircularProgress } from '@mui/material';

const ChainedAutocompleteIcon = ({ isDisabled, isLoading }) => {
  if (isLoading) {
    return <CircularProgress size="25px" />;
  }

  if (isDisabled) {
    return <Lock aria-label="lock icon" role="img" />;
  }

  return <ArrowDropDown aria-label="arrow down icon" role="img" />;
};

ChainedAutocompleteIcon.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ChainedAutocompleteIcon;
