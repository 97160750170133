/* eslint-disable react/jsx-no-bind */
import { useCallback, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../../components/CompleteWrapper';
import { IOption, IOrderProfileTermsPicker } from '../types';
import { getTermsAutocompleteOptions } from '../utils/mappingFunctions';

export const OrderProfileTermsPicker = ({
  availableTerms,
  error = '',
  isSingleInstallmentTermSelection = false,
  onChange,
  selectedTerms,
}: IOrderProfileTermsPicker) => {
  const { t } = useTranslation();
  const [singleSelectedTerm, setSingleSelectedTerm] = useState('');

  const isComplete = !!(
    !error &&
    ((!isSingleInstallmentTermSelection && selectedTerms.length > 0) ||
      (isSingleInstallmentTermSelection && singleSelectedTerm))
  );

  const availableTermOptions = getTermsAutocompleteOptions({
    terms: availableTerms,
    translate: t,
  });

  const selectedTermOptions = getTermsAutocompleteOptions({
    terms: selectedTerms,
    translate: t,
  });

  const handleOnMultiChange = useCallback(
    (_, options: IOption[]) => onChange(options.map(({ value }) => value)),
    [onChange],
  );

  const handleOnSingleChange = useCallback(
    e => {
      setSingleSelectedTerm(e.target.value);
      onChange([e.target.value]);
    },
    [onChange, setSingleSelectedTerm],
  );

  const singleTermPickerId = 'order-single-term-picker-select';

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ margin: '0.5rem; 0' }}
      variant="outlined"
    >
      <CompleteWrapper complete={isComplete} error={error}>
        {isSingleInstallmentTermSelection ? (
          <>
            <InputLabel id={singleTermPickerId}>
              {t('fields.orderProfilePicker.orderProfileTerms.label')}
            </InputLabel>
            <Select
              label={t('fields.orderProfilePicker.orderProfileTerms.label')}
              labelId={singleTermPickerId}
              name={singleTermPickerId}
              onChange={handleOnSingleChange}
              value={singleSelectedTerm}
            >
              {availableTermOptions.map(({ label, value }: IOption) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        ) : (
          <Autocomplete
            disableCloseOnSelect={true}
            filterSelectedOptions={true}
            getOptionLabel={option => option.label}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            multiple={true}
            noOptionsText={t(
              'fields.orderProfilePicker.orderProfileTerms.noOptions',
            )}
            onChange={handleOnMultiChange}
            options={availableTermOptions}
            renderInput={params => (
              <TextField
                error={!!error}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={t('fields.orderProfilePicker.orderProfileTerms.label')}
                rows={2}
              />
            )}
            value={selectedTermOptions}
          />
        )}
      </CompleteWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};
