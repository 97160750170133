import {
  IdCard,
  Selfie,
} from '@heidi-pay/heidi-component-library/components/Icons';
import { CameraAlt } from '@material-ui/icons';
import { Box } from '@mui/material';
import { IOnfidoWelcomeScreenContainerProps } from '@transaction/components/OnfidoWelcomeScreenContainer/types';
import { CopyBlock } from '../../../../components/CopyBlock';
import { StepsList } from './components';

export const BaseTranslationKey =
  'fields.onfidoWelcomeScreenContainer.heyLightTextRebrand';

export const OnfidoWelcomeScreenContainer = ({
  options,
}: IOnfidoWelcomeScreenContainerProps) => {
  const { isThreeStepUpload } = options ?? {};
  const outros = ['outro1', 'outro2', 'outro3', 'outro4'];

  const steps = [
    {
      labelKey: `${BaseTranslationKey}.listItems.listItem1.label`,
      subLabelKey: `${BaseTranslationKey}.listItems.listItem1.subLabel`,
      icon: <IdCard />,
    },
    {
      labelKey: `${BaseTranslationKey}.listItems.listItem2.label`,
      subLabelKey: `${BaseTranslationKey}.listItems.listItem2.subLabel`,
      icon: <Selfie />,
    },
    ...(isThreeStepUpload
      ? [
          {
            labelKey: `${BaseTranslationKey}.listItems.listItem3.label`,
            icon: <CameraAlt color="primary" />,
          },
        ]
      : []),
  ];

  return (
    <Box>
      <CopyBlock i18nKey={`${BaseTranslationKey}.title`} />
      <StepsList
        steps={steps}
        titleTranslationKey={`${BaseTranslationKey}.listTitle`}
      />
      {outros.map(key => (
        <CopyBlock
          i18nKey={`${BaseTranslationKey}.outros.${key}`}
          key={key}
          sx={{ margin: '10px 0' }}
        />
      ))}
    </Box>
  );
};
