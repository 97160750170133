import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    height: '1.6em',
    width: '2.6em',
    marginTop: '0em',
    border: '1px solid #CCC',
    padding: '0.1rem 0em',
    borderRadius: '4px',
  },
  img: {
    display: 'block',
    height: '100%',
    margin: 'auto',
  },
});

export default function PaymentMethodIcon({ paymentMethod, size }) {
  const classes = useStyles();
  const cardType = paymentMethod && paymentMethod.split('_')[0].toLowerCase();

  return (
    <Box className={classes.container} fontSize={`${size}rem`}>
      <img
        alt=""
        className={classes.img}
        src={`/images/cards/${cardType}.svg`}
      />
    </Box>
  );
}

PaymentMethodIcon.propTypes = {
  paymentMethod: PropTypes.string.isRequired,
  size: PropTypes.number,
};

PaymentMethodIcon.defaultProps = {
  size: 1,
};
