import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { ArrowForward } from '@material-ui/icons';
import {
  Button,
  Container,
  Grid,
  styled,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { getMonthlyPayments } from '../../../payment/redux';
import TermsDialog from './components/TermsDialog';

const ContainerWrapper = styled(Container)`
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
`;

// This component is deprecated and will be removed
const MandatoryActionContainer = ({ error, onChange, options, value }) => {
  const { t } = useTranslation();
  const numberOfMonthlyPayments = useSelector(getMonthlyPayments);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { documents, secciDocuments } = options;

  const handleOnComplete = () => onChange(true);

  const handleOnClick = () => setIsDialogOpen(true);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <ContainerWrapper>
        <CompleteWrapper complete={value} error={error}>
          <Grid textAlign="center">
            <Button
              color="black"
              disabled={!numberOfMonthlyPayments}
              endIcon={<ArrowForward color="primary" />}
              onClick={handleOnClick}
              variant="text"
            >
              {t('fields.mandatoryActionContainer.buttonLabel')}
            </Button>
          </Grid>
        </CompleteWrapper>
      </ContainerWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
      {isDialogOpen ? (
        <TermsDialog
          documents={documents}
          isOpen={true}
          onClose={closeDialog}
          onComplete={handleOnComplete}
          secciDocuments={secciDocuments}
        />
      ) : null}
    </FormControl>
  );
};

MandatoryActionContainer.validation = name =>
  yup.bool().oneOf([true], `fields.${name}.required`);

MandatoryActionContainer.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape().isRequired,
  error: PropTypes.string,
};

MandatoryActionContainer.defaultProps = {
  error: '',
};

export default MandatoryActionContainer;
