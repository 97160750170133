import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { SelectInput } from '@heidi-pay/heidi-component-library/components/SelectInput';
import { FormControl, FormHelperText } from '@material-ui/core';
import { useTranslation } from '@hooks';
import {
  resetOrderProfile,
  setOrderProfile,
  setSelectedNumberOfInstallments,
} from '@instore/redux';
import { getOrderProfiles, getOrderProfile } from '@instore/redux/selectors';
import { Divider } from '@transaction/components/Divider';
import {
  IBaseInputComponent,
  IComponentBaseOptions,
} from '@transaction/components/types';
import { setMonthlyPayments } from '../../../../payment/redux';
import { OrderProfileTermsPicker } from './components/OrderProfileTermsPicker';
import { IOrderProfilePickerError, IValue } from './types';
import { getOrderProfilePickerLabel } from './utils/mappingFunctions';

interface IOrderProfilePickerOptions extends IComponentBaseOptions {
  isSingleInstallmentTermSelection?: boolean;
}

export const OrderProfilePicker = ({
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value = {},
}: IBaseInputComponent<
  IOrderProfilePickerOptions,
  IValue,
  IOrderProfilePickerError
>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const orderProfiles = useSelector(getOrderProfiles);
  const orderProfile = useSelector(getOrderProfile);

  const { terms: valueTerms = [], uuid: valueUuid = '' } = value;
  const { isSingleInstallmentTermSelection = false } = options ?? {};
  const {
    availableTerms,
    countries,
    uuid: orderProfileUuid,
  } = orderProfile ?? {};

  const shouldDisplayTermsSelector =
    availableTerms && availableTerms.length > 1;

  const handleOnTermsChange = useCallback(
    terms => onChange({ terms, uuid: valueUuid }),
    [onChange, valueUuid],
  );

  const handleOnOrderProfileChange = useCallback(
    uuid => onChange({ terms: [], uuid }),
    [onChange],
  );

  const handleOnChange = useCallback(
    event => handleOnOrderProfileChange(event.target.value),
    [handleOnOrderProfileChange],
  );

  useEffect(() => {
    if (!orderProfiles?.length) {
      dispatch(resetOrderProfile());
      if (valueUuid !== '') {
        handleOnOrderProfileChange('');
      }
    } else if (valueUuid !== orderProfile?.uuid) {
      const selectedOrderProfile = orderProfiles.find(
        x => x.uuid === valueUuid,
      );
      dispatch(setOrderProfile(selectedOrderProfile));

      if (
        selectedOrderProfile?.availableTerms &&
        selectedOrderProfile.availableTerms.length > 1
      ) {
        dispatch(setSelectedNumberOfInstallments([]));
        handleOnTermsChange([]);
      }
    }
  }, [
    countries,
    dispatch,
    handleOnOrderProfileChange,
    handleOnTermsChange,
    orderProfile?.uuid,
    orderProfiles,
    valueUuid,
  ]);

  useEffect(() => {
    // if the valid list has changed, and the current value is not in the list, or the available terms have changed
    // refresh the current order profile, or change to the first order profile available,
    if (
      (!orderProfile ||
        !orderProfiles?.find(
          op =>
            op.uuid === orderProfile.uuid &&
            op.availableTerms.length === availableTerms?.length,
        )) &&
      orderProfiles?.length
    ) {
      const selectOrderProfile =
        orderProfiles.find(op => op.uuid === orderProfile?.uuid) ??
        orderProfiles[0];

      handleOnOrderProfileChange(selectOrderProfile.uuid);
      dispatch(setOrderProfile(selectOrderProfile));
    }
  }, [
    dispatch,
    handleOnOrderProfileChange,
    valueUuid,
    orderProfiles,
    orderProfile,
    availableTerms,
  ]);

  useEffect(() => {
    dispatch(setSelectedNumberOfInstallments(valueTerms));

    if (valueTerms?.length) {
      dispatch(setMonthlyPayments(Math.max(...valueTerms)));
    }
  }, [dispatch, valueTerms]);

  useEffect(() => {
    if (availableTerms?.length === 1) {
      dispatch(setSelectedNumberOfInstallments(availableTerms));
      dispatch(setMonthlyPayments(availableTerms[0]));
      onChange({ terms: availableTerms, uuid: orderProfileUuid });
    }
  }, [availableTerms, dispatch, onChange, orderProfileUuid]);

  return (
    <>
      <Divider options={{ spaced: true }} />
      <FormControl
        component="fieldset"
        error={!!error?.uuid}
        fullWidth={true}
        size="small"
        variant="outlined"
      >
        <SelectInput
          complete={!!valueUuid}
          id={name}
          inputRef={inputRef}
          label={t(`fields.orderProfilePicker.${name}.label`)}
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          value={valueUuid}
        >
          {orderProfiles?.map(
            ({ availableTerms: availableTermsOp, labelPartsData, uuid }) => (
              <option key={uuid} value={uuid}>
                {getOrderProfilePickerLabel({
                  availableTerms: availableTermsOp,
                  labelPartsData,
                  name,
                  translate: t,
                })}
              </option>
            ),
          )}
        </SelectInput>
        <FormHelperText>{t(error?.uuid?.message ?? '')}</FormHelperText>
      </FormControl>
      {shouldDisplayTermsSelector ? (
        <OrderProfileTermsPicker
          availableTerms={availableTerms}
          error={error?.terms?.message ?? ''}
          isSingleInstallmentTermSelection={isSingleInstallmentTermSelection}
          onBlur={onBlur}
          onChange={handleOnTermsChange}
          selectedTerms={valueTerms}
        />
      ) : null}
    </>
  );
};

OrderProfilePicker.validation = () =>
  yup.object({
    terms: yup
      .array()
      .min(1, 'fields.orderProfilePicker.orderProfileTerms.required'),
    uuid: yup
      .string()
      .required('fields.orderProfilePicker.orderProfileDetails.required'),
  });
