import { HeidipayLogo } from './HeidipayLogo';
import { PagolightProLogo } from './PagolightProLogo';

const brandLogoMap = {
  heidipay: HeidipayLogo,
  mediobanca: HeidipayLogo,
  pagolight_pro: PagolightProLogo,
};

const getLogoByKey = key => brandLogoMap[key] || HeidipayLogo;

export default getLogoByKey;
