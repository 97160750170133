import { useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from '@hooks';
import { QRCode } from '../../../components';
import {
  IBaseComponent,
  IComponentBaseOptions,
} from '../../../transaction/components/types';

export interface IInstoreMerchantRedirectQROptions
  extends IComponentBaseOptions {
  observationUrl: string;
}

export const InstoreMerchantRedirectQR = ({
  options,
  value: redirectUrl,
}: IBaseComponent<IInstoreMerchantRedirectQROptions, string>) => {
  if (!redirectUrl) {
    throw new Error('The checkout redirect url is required.');
  }

  if (!options) {
    throw new Error('The options prop is required.');
  }

  const { observationUrl } = options;
  const { t } = useTranslation();
  const altText = t('common.checkoutRedirectUrl');

  const handleGoToObservation = useCallback(
    () => window.location.assign(observationUrl),
    [observationUrl],
  );

  return (
    <>
      <QRCode altText={altText} text={redirectUrl} />
      <Box mt={3}>
        <Button
          onClick={handleGoToObservation}
          size="medium"
          sx={{ textTransform: 'None' }}
          variant="contained"
        >
          {t('common.trackProgress')}
        </Button>
      </Box>
    </>
  );
};
