import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput, {
  getCountryCallingCode,
} from 'react-phone-number-input/input';

export default function PhoneInputAdapter({
  country,
  inputRef,
  label,
  onAnimationStart,
  onChange,
  ...rest
}) {
  const changeHandler = number => {
    const val = inputRef.current?.value;
    const coercedVal = Number(val);
    const result =
      number ||
      `+${getCountryCallingCode(country)}${
        Number.isNaN(coercedVal) ? '' : coercedVal
      }`;
    onChange(result);
  };

  return (
    <PhoneInput
      aria-label={label}
      country={country}
      international={true}
      onAnimationStart={event => {
        // Handle Material UI thinking the input is empty with autofill
        if (event.animationName !== 'mui-auto-fill-cancel') {
          onAnimationStart(event);
        }
      }}
      onChange={changeHandler}
      ref={inputRef}
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

PhoneInputAdapter.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]).isRequired,
  onChange: PropTypes.func.isRequired,
  onAnimationStart: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
};
