import { useMemo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/mobile';
import { Select, makeStyles } from '@material-ui/core';
import { useTranslation } from '@hooks';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  select: {
    width: '2.3em',
  },
  value: {
    position: 'absolute',
    top: 4,
    left: 3,
    right: '2em',
    bottom: 3,
    textAlign: 'right',
    verticalAlign: 'middle',
    backgroundColor: 'white',
    fontSize: '1rem',
    lineHeight: '2rem',
    pointerEvents: 'none',
  },
  valueDisabled: {
    color: '#AAA',
  },
  label: {
    display: 'none',
  },
});

function generateCallingCodes(locale, countryTranslation) {
  const countries = getCountries().map(code => ({
    code,
    name: countryTranslation(code),
    callingCode: getCountryCallingCode(code),
  }));
  return countries.sort((a, b) =>
    a.name.localeCompare(b.name, locale.replace('_', '-')),
  );
}

export default function CallingCodePicker({ disabled, name, onChange, value }) {
  const { i18n, t } = useTranslation();
  const { t: countryTranslation } = useTranslation('country');
  const locale = i18n.language;
  const countries = useMemo(
    () => generateCallingCodes(locale, countryTranslation),
    [countryTranslation, locale],
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <label className={classes.label} htmlFor={name}>
        {t(
          `fields.mobileNumberInput.${name.replace(
            '-select',
            '',
          )}.countryCodeLabel`,
        )}
      </label>
      <Select
        disabled={disabled}
        id={name}
        inputProps={{
          className: clsx(
            'MuiInputBase-inputMarginDense',
            'MuiOutlinedInput-inputMarginDense',
            classes.select,
          ),
        }}
        name={name}
        native={true}
        onChange={event => onChange(event.target.value || undefined)}
        size="small"
        value={value}
        variant="outlined"
      >
        {countries.map(({ callingCode, code, name: countryName }) => (
          <option key={code} value={code}>
            {countryName} (+
            {callingCode})
          </option>
        ))}
      </Select>
      <span
        aria-hidden={true}
        className={clsx(classes.value, disabled && classes.valueDisabled)}
      >
        {value && `+${getCountryCallingCode(value)}`}
      </span>
    </div>
  );
}

CallingCodePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CallingCodePicker.defaultProps = {
  value: '',
};
