import * as Sentry from '@sentry/react';

const defaultTagValue = 'unknown';

export const axiosNetworkErrorCode = 'ERR_NETWORK'; // Network-related issue.

export const OriginatedOnBackendTagKey = 'originated_on_backend';

export const SentrySeverity = Object.freeze({
  debug: 'debug',
  error: 'error',
  fatal: 'fatal',
  info: 'info',
  log: 'log',
  warning: 'warning',
});

export const SentryEnvironments = Object.freeze({
  prod: 'prod',
  qa: 'qa',
  sandbox: 'sandbox',
});

export const formatSentryEventTags = ({
  checkoutType,
  merchantName,
  originationChannel,
  sessionUuid,
}) => ({
  checkout_type: checkoutType || defaultTagValue,
  merchant_name: merchantName || defaultTagValue,
  origination_channel: originationChannel || defaultTagValue,
  origination_session_uuid: sessionUuid || defaultTagValue,
  [OriginatedOnBackendTagKey]: false,
});

export const setSentryGlobalScope = tags => {
  Sentry.configureScope(scope => scope.setTags(tags));
};

export const getSentryIntegrations = sentryEnv => {
  const shouldEnableReplays = ![SentryEnvironments.qa].includes(sentryEnv);

  return [
    new Sentry.BrowserTracing(),
    ...(shouldEnableReplays
      ? [
          new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ]
      : []),
  ];
};

export const toggleReplay = enableReplay => {
  const client = Sentry.getCurrentHub().getClient();
  if (!client) {
    return;
  }

  if (enableReplay) {
    const existingReplay = client.getIntegration(Sentry.Replay);
    if (existingReplay) {
      return;
    }
    const replay = new Sentry.Replay({
      maskAllText: true,
      maskAttributes: true,
      unmask: [
        '.MuiFormLabel-root',
        '.MuiFormHelperText-root',
        '.MuiAlert-standardError',
      ],
    });
    client.addIntegration(replay);
  } else {
    const replay = client.getIntegration(Sentry.Replay);
    replay?.stop();
  }
};

// When debugging we might want to limit replays to a reduced number of orders
export const disableSentryReplay = () => toggleReplay(false);
