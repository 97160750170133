import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { captureMessage } from '@sentry/react';
import { SentrySeverity } from '../../../utils';
import {
  getProvinces,
  getCityById,
  searchCityByName,
} from '../../services/ItalianPostAddressService';
import { SelectPicker } from './SelectPicker';

export const SelectPickerItalianProvince = ({
  complete,
  error,
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  options,
  value,
}) => {
  const { cityName, disabled } = options;

  const [provinces, setProvinces] = useState([{ text: value, value }]);

  const loadProvinces = useCallback(async () => {
    const result = await getProvinces();
    setProvinces(result.map(item => ({ text: item.value, value: item.value })));
  }, [setProvinces]);

  const loadProvinceForCity = useCallback(async () => {
    const { key } = (await searchCityByName(cityName)) || {};

    // If we get a city key back we'll fetch city details using the key
    // For V1 we won't bother handling cases in which the city is not found.
    if (key) {
      const { siglaProvincia = '' } = (await getCityById(key)) || {};
      setProvinces([{ text: siglaProvincia, value: siglaProvincia }]);
      onChange(siglaProvincia);
    } else {
      captureMessage(
        `Postel IT search city request returned no city for search string: ${cityName}.`,
        SentrySeverity.warning,
      );
    }
  }, [setProvinces, onChange, cityName]);

  useEffect(() => {
    if (cityName) {
      loadProvinceForCity();
    } else {
      loadProvinces();
    }
  }, [loadProvinceForCity, loadProvinces, cityName]);

  return (
    <SelectPicker
      complete={complete}
      displayRawValues={true}
      error={error}
      inputRef={inputRef}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={{ items: provinces, disabled }}
      value={value}
    />
  );
};

SelectPickerItalianProvince.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.shape({
    cityName: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  inputRef: PropTypes.shape(),
  error: PropTypes.string,
  complete: PropTypes.bool,
  label: PropTypes.string,
};

SelectPickerItalianProvince.defaultProps = {
  error: '',
  complete: false,
  inputRef: undefined,
  label: null,
  options: {},
};
