import { useRef } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import HtmlContainer from '../HtmlContainer';
import { ZoomScrollableContainer } from '../ZoomScrollableContainer';
import ConsentBlock from './components/ConsentBlock';
import { useContentScrollable } from './hooks/useContentScrollable';
import {
  contentScrollableDefaultProps,
  contentScrollablePropTypes,
  contentScrollableValidation,
} from './utils/contentScrollableConfig';

const ConsentScrollableZoomContainer = ({
  content,
  error,
  name,
  onChange,
  options,
  value,
}) => {
  const parentContainerRef = useRef(null);
  const {
    consentBlocks,
    handleOnItemChange,
    hasErrors,
    htmlContentSections,
    t,
  } = useContentScrollable({
    content,
    error,
    onChange,
    parentContainerRef,
    value,
  });

  return (
    <FormControl error={hasErrors} ref={parentContainerRef}>
      {consentBlocks.map(({ key, ref, value: consentValue }, index) => (
        <div key={key} style={{ marginBottom: 20 }}>
          <ConsentBlock
            error={error[index]}
            id={key}
            innerRef={ref}
            name={key}
            onChange={newValue => handleOnItemChange(key, newValue, index)}
            options={options}
            value={consentValue?.toString() ?? ''}
          />
          <ZoomScrollableContainer name={name}>
            <HtmlContainer
              content={htmlContentSections[index]}
              options={options}
            />
          </ZoomScrollableContainer>
        </div>
      ))}
      <HtmlContainer
        content={htmlContentSections[htmlContentSections.length - 1]}
        options={options}
      />
      {hasErrors ? (
        <FormHelperText>
          {t('fields.consentScrollableContainer.required')}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

ConsentScrollableZoomContainer.propTypes = contentScrollablePropTypes;
ConsentScrollableZoomContainer.defaultProps = contentScrollableDefaultProps;
ConsentScrollableZoomContainer.validation = contentScrollableValidation;

export default ConsentScrollableZoomContainer;
