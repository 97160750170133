import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useTranslation } from '@hooks';
import CompleteWrapper from '../../../components/CompleteWrapper';
import { StackedLabel } from '../StackedLabel';
import {
  MonetaryFormatInput,
  MonetaryFixedFormatInput,
  NumericFormatInput,
} from './components';

export const Type = Object.freeze({
  monetary: 'monetary',
  monetaryFixed: 'monetaryFixed',
  numeric: 'numeric',
});

const inputComponentMap = Object.freeze({
  [Type.monetary]: MonetaryFormatInput,
  [Type.monetaryFixed]: MonetaryFixedFormatInput,
  [Type.number]: NumericFormatInput,
});

export const TextInputNumeric = ({
  complete,
  controlName,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  value,
}) => {
  const {
    currency = undefined,
    disabled = false,
    hidden = false,
    labelOverride = null,
    tooltip,
    type = Type.numeric,
    withInputAdornment = false,
    withStackedLabel = false,
  } = options;

  const { t } = useTranslation();

  const label = t(`fields.textInput.${labelOverride || name}.label`);
  const inputAdornmentText = withInputAdornment
    ? t(`fields.textInput.${labelOverride || name}.inputAdornment`)
    : '';

  if (hidden) {
    return null;
  }

  // Handle monetary object values
  const isMoneyField =
    value?.hasOwnProperty('amount') || value?.hasOwnProperty('currency');
  const parsedValue = isMoneyField ? value.amount : value;
  const parsedCurrency = isMoneyField ? value.currency : currency;

  const handleOnChange = newValue => {
    const val = isMoneyField
      ? { amount: newValue, currency: parsedCurrency }
      : newValue;
    onChange(val);
    if (controlName && setValue) {
      setValue(controlName ?? name, val);
    }
  };

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel
          className="MuiFormLabel-external"
          label={label}
          name={name}
          tooltip={tooltip}
        />
      ) : null}
      <CompleteWrapper complete={complete} error={error}>
        <TextField
          disabled={disabled}
          error={!!error}
          hidden={true}
          id={name}
          InputLabelProps={{ shrink: parsedValue !== '' || withInputAdornment }}
          InputProps={{
            startAdornment: withInputAdornment ? (
              <InputAdornment position="start" sx={{ marginTop: '0.125rem' }}>
                {inputAdornmentText}
              </InputAdornment>
            ) : null,
            ...(parsedCurrency
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      {parsedCurrency}
                    </InputAdornment>
                  ),
                }
              : {}),
            inputComponent: inputComponentMap[type] ?? NumericFormatInput,
          }}
          inputProps={{ inputMode: 'decimal' }}
          inputRef={inputRef}
          label={withStackedLabel ? undefined : label}
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          size="small"
          type="text"
          value={parsedValue}
          variant="outlined"
        />
      </CompleteWrapper>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

TextInputNumeric.validation = (
  name,
  {
    isMonetaryObject = false,
    labelOverride = null,
    optional = false,
    regex = null,
  },
) => {
  let schema;

  if (isMonetaryObject) {
    schema = yup
      .object({
        amount: yup.string().required(),
        currency: yup.string().required(),
      })
      .transform(form => (!form ? { amount: '', currency: '' } : form));
  } else {
    schema = yup.string();
    if (regex) {
      schema = schema.matches(
        new RegExp(regex),
        `fields.textInput.${labelOverride || name}.invalid`,
      );
    }
  }

  if (optional) {
    return schema.nullable();
  }

  return schema.test(
    name,
    `fields.textInput.${labelOverride || name}.required`,
    value => {
      if (isMonetaryObject) {
        return Boolean(value?.amount) && Boolean(value?.currency);
      }
      return Boolean(value);
    },
  );
};

TextInputNumeric.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.shape({
      amount: PropTypes.string,
      currency: PropTypes.string,
    }),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.shape({
    currency: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    isFixedAmount: PropTypes.bool,
    labelOverride: PropTypes.string,
    optional: PropTypes.bool,
    type: PropTypes.oneOf([Type.monetary, Type.monetaryFixed, Type.numeric]),
    withStackedLabel: PropTypes.bool,
  }),
  error: PropTypes.string,
  complete: PropTypes.bool,
};

TextInputNumeric.defaultProps = {
  error: '',
  complete: false,
  options: {
    currency: undefined,
    disabled: false,
    labelOverride: undefined,
    isFixedAmount: false,
    optional: false,
    type: Type.numeric,
    withStackedLabel: false,
  },
};
