import { bool, func, string } from 'prop-types';
import { PictureAsPdf } from '@material-ui/icons';
import { Grid, Link, Typography } from '@mui/material';

const DocumentTile = ({ href, onClick, outlined, textAlign, title }) => (
  <Grid
    border={outlined ? '1px solid' : ''}
    borderColor="lightgrey"
    borderRadius={1}
    item={true}
    md={6}
    px={outlined ? 4 : 0}
    py={1}
    xs={12}
  >
    <Grid container={true} py={1}>
      <Grid
        item={true}
        sm={1}
        sx={{ display: 'flex', alignItems: 'center' }}
        xs={2}
      >
        <PictureAsPdf color="primary" />
      </Grid>
      <Grid item={true} sm={11} textAlign={textAlign} xs={10}>
        <Link
          color="initial"
          href={href}
          onClick={onClick}
          target="blank"
          underline="hover"
        >
          <Typography fontWeight="500" variant="subtitle2">
            {title}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  </Grid>
);

DocumentTile.propTypes = {
  href: string.isRequired,
  title: string.isRequired,
  outlined: bool,
  textAlign: string,
  onClick: func,
};

DocumentTile.defaultProps = {
  outlined: false,
  textAlign: 'start',
  onClick: () => {},
};

export default DocumentTile;
