import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material';
import {
  IUnknownObject,
  IExtendedComponentLazyWrapper,
  IStringToComponentMap,
} from '@appTypes';
import { InstoreTasksMenu } from '@instore/components/InstoreTasksMenu';

import Field from '../Field';
import SoftErrorAlertController from '../SoftErrorAlertController';
import { IField } from '../types';
import { FormToReduxLink } from './components/FormToReduxLink';
import { getShouldLinkToRedux } from './utils/getShouldLinkToRedux';

const StyledForm = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > button': {
    marginTop: '1.2rem',
    width: '80%',
    justifySelf: 'center',
  },
  '& > *': {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  '& > MuiFromControl': {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  '& > :first-child': {
    marginTop: '0',
  },
  '& > :last-child': {
    marginBottom: '0',
  },
});

interface IStringToFuncMap {
  [key: string]: (value: string) => unknown | void;
}

interface IFormProps {
  fieldToComponentMapping: IStringToComponentMap;
  fields: IField[];
  onSubmit: (fields: IField[]) => void;
}

function buildSchema(
  fields: IField[],
  fieldToComponentMapping: IStringToComponentMap,
): Record<string, yup.AnySchema> {
  return Object.fromEntries(
    fields
      .map(({ name, options, type }) => {
        const schemaGenerator = fieldToComponentMapping[type]?.validation;
        let schema;
        if (schemaGenerator) {
          schema = schemaGenerator(name, options ?? {});
        }
        return [name, schema];
      })
      .filter(field => !!field[1]),
  ) as unknown as Record<string, yup.AnySchema>;
}

function buildFormatters(
  fields: IField[],
  fieldToComponentMapping: IStringToComponentMap,
): IStringToFuncMap {
  return Object.fromEntries(
    fields
      .map(({ name, type }) => [
        name,
        fieldToComponentMapping[type]?.formatting,
      ])
      .filter(field => !!field[1]),
  );
}

const Unknown = () => null;

function formattingResolver(
  schema: yup.AnyObjectSchema,
  formatters: IStringToFuncMap = {},
  // TODO try to remove the any in the next PR
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const resolver = yupResolver(schema);
  return async (
    values: IUnknownObject,
    context: object | undefined,
    validateAllFieldCriteria: boolean | undefined,
  ) => {
    const result = await resolver(values, context, validateAllFieldCriteria);
    return {
      values: Object.fromEntries(
        Object.entries(result.values).map(([key, value]) => {
          const formatter = formatters[key];
          if (formatter) {
            return [key, formatter(value)];
          }
          return [key, value];
        }),
      ),
      errors: result.errors,
    };
  };
}

export const Form = ({
  fields,
  fieldToComponentMapping,
  onSubmit,
}: IFormProps) => {
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const shouldLinkToRedux = useMemo(
    () => getShouldLinkToRedux(fields),
    [fields],
  );

  const schema = yup
    .object()
    .shape(buildSchema(fields, fieldToComponentMapping));
  const formatters = buildFormatters(fields, fieldToComponentMapping);

  const getComponentByType = (type: string) => {
    const component = fieldToComponentMapping[type] ?? Unknown;

    return (component as IExtendedComponentLazyWrapper)?.lazy ?? component;
  };

  const {
    clearErrors,
    control,
    errors,
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm({
    resolver: formattingResolver(schema, formatters),
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const submit = useCallback(
    (event: React.SyntheticEvent) => {
      setSubmitAttempted(true);
      handleSubmit(onSubmit)(event);
    },
    [handleSubmit, onSubmit],
  );

  return (
    <StyledForm role="form">
      {shouldLinkToRedux ? (
        <FormToReduxLink fields={fields} watch={watch} />
      ) : null}

      <SoftErrorAlertController />

      <InstoreTasksMenu setFormValue={setValue} />

      {fields.map(
        ({ content, name: fieldName, options, type, value: defaultValue }) => (
          <Field
            clearErrors={clearErrors}
            content={content}
            control={control}
            errors={errors}
            key={`${fieldName}-${JSON.stringify(options)}-${JSON.stringify(
              defaultValue,
            )}`}
            name={fieldName}
            options={options}
            setValue={setValue}
            submit={submit}
            submitAttempted={submitAttempted}
            trigger={trigger}
            type={getComponentByType(type)}
            value={defaultValue}
            watch={watch}
          />
        ),
      )}
    </StyledForm>
  );
};
