import { Fragment, useRef } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import HtmlContainer from '../HtmlContainer';
import { ScrollableContainer } from '../ScrollableContainer';
import ConsentBlock from './components/ConsentBlock';
import { useContentScrollable } from './hooks/useContentScrollable';
import {
  contentScrollableDefaultProps,
  contentScrollablePropTypes,
  contentScrollableValidation,
} from './utils/contentScrollableConfig';

const ConsentScrollableContainer = ({
  content,
  error,
  name,
  onChange,
  onScrollToBottom,
  options,
  value,
}) => {
  const parentContainerRef = useRef(null);
  const {
    consentBlocks,
    handleOnItemChange,
    hasErrors,
    htmlContentSections,
    t,
  } = useContentScrollable({
    content,
    error,
    onChange,
    parentContainerRef,
    value,
  });

  return (
    <FormControl error={hasErrors}>
      <ScrollableContainer
        id={name}
        innerRef={parentContainerRef}
        onScroll={onScrollToBottom}
        options={options}
      >
        {consentBlocks.map(({ key, ref, value: consentValue }, index) => (
          <Fragment key={key}>
            <HtmlContainer
              content={htmlContentSections[index]}
              options={options}
            />
            <ConsentBlock
              error={error[index]}
              id={key}
              innerRef={ref}
              name={key}
              onChange={newValue => handleOnItemChange(key, newValue, index)}
              value={consentValue?.toString() || ''}
            />
          </Fragment>
        ))}
        <HtmlContainer
          content={htmlContentSections[htmlContentSections.length - 1]}
          options={options}
        />
      </ScrollableContainer>
      {hasErrors ? (
        <FormHelperText>
          {t('fields.consentScrollableContainer.required')}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

ConsentScrollableContainer.propTypes = contentScrollablePropTypes;
ConsentScrollableContainer.defaultProps = contentScrollableDefaultProps;
ConsentScrollableContainer.validation = contentScrollableValidation;

export default ConsentScrollableContainer;
