import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { useTranslation } from '@hooks';
import { getPaymentPlan, loadPaymentPlan } from '../../payment/redux';
import { useFormatters } from '../../utils';

const useStyles = makeStyles({
  control: {
    marginTop: '1rem',
    marginBottom: '0',
  },
  container: {
    marginRight: '0.5rem',
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
  table: {
    '& td, & th': {
      fontSize: '16px',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      paddingTop: '0.2rem',
      paddingBottom: '0.2rem',
      border: 'none',
      '&:first-child': {
        width: '100%',
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    '& th': {
      fontWeight: 'bold',
    },
  },
  tableCellAmount: {
    whiteSpace: 'nowrap',
  },
});

const useStylesPaymentRow = makeStyles({
  noWrap: {
    whiteSpace: 'nowrap',
  },
});

// TODO eslint migration - move to separate file and import
function PaymentRow({
  alignAmount = 'right',
  amount,
  currency,
  expandClick,
  expanded,
  header,
  title,
  value,
  visible = true,
}) {
  const { t } = useTranslation();
  const component = header ? 'th' : 'td';
  const { noWrap } = useStylesPaymentRow();

  if (visible) {
    return (
      <TableRow>
        <TableCell
          colSpan={value === null ? 2 : 1}
          component={component}
          onClick={expandClick}
        >
          {title}
          {expandClick && (
            <IconButton
              aria-label={t('fields.paymentPlan.expand')}
              onClick={expandClick}
              size="small"
            >
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        {value !== null && (
          <TableCell align="right" component={component}>
            {value}
          </TableCell>
        )}
        <TableCell component={component}>{currency}</TableCell>
        <TableCell align={alignAmount} className={noWrap} component={component}>
          {amount}
        </TableCell>
      </TableRow>
    );
  }
  return null;
}

export default function PaymentPlan({ options }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const { hiddenFields = [], shouldLoadPaymentPlan = false } = options;
  const { formatAmountWithCurrency, formatDate } = useFormatters();

  const {
    schedule,
    summary: { currency, heldOnCard, payMonthly, payNow, total },
  } = useSelector(getPaymentPlan);

  const shouldDisplayPayNow = Number(payNow?.amount) > 0;

  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldLoadPaymentPlan) {
      dispatch(loadPaymentPlan());
    }
  }, [dispatch, shouldLoadPaymentPlan]);

  const shouldShowField = fieldName => !hiddenFields.includes(fieldName);

  if (!currency || schedule?.length === 0) {
    return null;
  }

  return (
    <FormControl
      className={classes.control}
      component="fieldset"
      fullWidth={true}
      size="small"
    >
      <TableContainer className={classes.container}>
        <Table className={classes.table} size="small">
          <TableBody>
            {shouldDisplayPayNow ? (
              <PaymentRow
                amount={formatAmountWithCurrency(payNow.amount, currency)}
                title={t('fields.paymentPlan.rows.payNow')}
                visible={shouldShowField('payNow')}
              />
            ) : null}
            <PaymentRow
              amount={formatAmountWithCurrency(payMonthly, currency)}
              title={t('fields.paymentPlan.rows.payMonthly')}
              value={`${schedule.length}x`}
              visible={schedule.length !== 1 && shouldShowField('payMonthly')}
            />
            <PaymentRow
              amount={formatAmountWithCurrency('0.00', currency)}
              title={t('fields.paymentPlan.rows.interest')}
              value="0%"
              visible={schedule.length !== 1 && shouldShowField('interest')}
            />

            <PaymentRow
              amount={formatAmountWithCurrency(total, currency)}
              expandClick={() => setExpanded(!expanded)}
              expanded={expanded}
              header={true}
              title={t('fields.paymentPlan.rows.total')}
            />

            {expanded ? (
              <>
                <TableRow>
                  <TableCell colSpan={4} component="th">
                    {t('fields.paymentPlan.schedule')}
                  </TableCell>
                </TableRow>
                {shouldDisplayPayNow ? (
                  <PaymentRow
                    amount={formatAmountWithCurrency(payNow.amount, currency)}
                    key={payNow.amount}
                    title={t('fields.paymentPlan.rows.today')}
                  />
                ) : null}
                {schedule.map(({ amount, date }) => (
                  <PaymentRow
                    amount={formatAmountWithCurrency(amount, currency)}
                    key={date}
                    title={formatDate({ date })}
                  />
                ))}
              </>
            ) : null}
            {Number(heldOnCard.percentage) ? (
              <PaymentRow
                amount={formatAmountWithCurrency(heldOnCard.amount, currency)}
                title={t('fields.paymentPlan.rows.heldOnCard')}
                value={`${heldOnCard.percentage}%`}
              />
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <FormHelperText>
        {!!Number(heldOnCard.percentage) &&
          t('fields.paymentPlan.heldOnCardDescription')}
      </FormHelperText>
    </FormControl>
  );
}

PaymentPlan.propTypes = {
  options: PropTypes.shape(),
};

PaymentPlan.defaultProps = {
  options: { hiddenFields: [] },
};

PaymentRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.string.isRequired,
  expandClick: PropTypes.func,
  expanded: PropTypes.bool,
  header: PropTypes.bool,
  alignAmount: PropTypes.string,
  visible: PropTypes.bool,
};

PaymentRow.defaultProps = {
  alignAmount: 'right',
  expandClick: null,
  expanded: false,
  currency: null,
  header: false,
  value: null,
  visible: true,
};
